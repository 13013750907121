import defaultTheme from '@oberoninternal/travelbase-ds/themes/default';
import { FontStyle, Theme } from '@oberoninternal/travelbase-ds/constants/theme';
import { BodyVariant } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';

/**
 * The Voorkant (website) theme.
 * A general purpose whitelabeled theme for the booking site.
 */

const titleStyles: Record<TitleVariant, FontStyle> = {
    large: {
        size: { min: 19, max: 31 },
        line: { min: 24, max: 32 },
        letterSpacing: 0,
        weight: 700,
    },
    regular: {
        size: { min: 19, max: 23 },
        line: { min: 24, max: 28 },
        letterSpacing: 0,
        weight: 700,
    },
    small: {
        size: { min: 16, max: 19 },
        line: { min: 24, max: 24 },
    },
    tiny: {
        size: { min: 16, max: 16 },
        line: { min: 20, max: 20 },
    },
    micro: {
        size: { min: 15, max: 15 },
        line: { min: 16, max: 16 },
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 17, max: 20 },
        line: { min: 28, max: 34 },
        weight: 300,
    },
    large: {
        size: { min: 14, max: 18 },
        line: { min: 20, max: 32 },
        weight: 300,
    },
    regular: {
        size: { min: 14, max: 17 },
        line: { min: 20, max: 28 },
        letterSpacing: 0,
        weight: 300,
    },
    small: {
        size: { min: 13, max: 14 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
    },
    tiny: {
        size: { min: 12, max: 13 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
    micro: {
        size: { min: 12, max: 12 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
    },
};

const mediaQueriesValues = {
    xs: 480,
    s: 688,
    m: 1024,
    l: 1200,
    xl: 1520,
    xxl: 1660,
    xxxl: 1920,
};

const primary = {
    0: '#ecf8ff',
    5: '#d5eeff',
    10: '#bee3ff',
    20: '#8fcafc',
    30: '#65aff5',
    40: '#4392eb',
    50: '#2875de',
    60: '#1558cb',
    70: '#083cb3',
    80: '#002395',
    90: '#00196a',
    100: '#000f3d',
};

const website: Theme = {
    ...defaultTheme,
    name: 'Voorkant',

    fontFamily: {
        title: 'Manrope, sans-serif',
        body: 'Manrope, sans-serif',
    },

    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    breakpoints: Object.values(mediaQueriesValues).map(value => `${value}px`),
    mediaQueries: Object.assign(
        {},
        ...Object.entries(mediaQueriesValues).map(([key, value]) => ({
            [key]: `${value}px`,
        }))
    ),
    mediaQueriesValues,
    colors: {
        primary,
        tertiary: primary,
        secondary: {
            0: '#ffe6dd',
            5: '#fdd3c2',
            10: '#fcbfa7',
            20: '#f69972',
            30: '#ee7845',
            40: '#e25e20',
            50: '#d24a06',
            60: '#bd4400',
            70: '#a33f00',
            80: '#833700',
            90: '#5f2b00',
            100: '#3b1c00',
        },
        neutral: {
            0: '#ffffff',
            5: '#F4F4F4',
            10: '#E0E0E0',
            20: '#C6C6C6',
            30: '#A8A8A8',
            40: '#8D8D8D',
            50: '#6F6F6F',
            60: '#525252',
            70: '#393939',
            80: '#262626',
            90: '#161616',
            100: '#000000',
        },
        positive: {
            0: '#ebffeb',
            5: '#E2FDE3',
            10: '#d9fbdb',
            20: '#b3f0b5',
            30: '#7ade7e',
            40: '#36c43c',
            50: '#00a308',
            60: '#008207',
            70: '#006606',
            80: '#005206',
            90: '#004705',
            100: '#004206',
        },
        warning: {
            0: '#ffe8be',
            5: '#fed790',
            10: '#fec662',
            20: '#f9ae2c',
            30: '#f1990b',
            40: '#e48800',
            50: '#d37800',
            60: '#be6500',
            70: '#a65000',
            80: '#8c3d00',
            90: '#722c00',
            100: '#591e00',
        },
        negative: {
            0: '#fff1f1',
            5: '#FEEBEB',
            10: '#fde6e6',
            20: '#f8cecd',
            30: '#f2a8a7',
            40: '#e87975',
            50: '#dc463e',
            60: '#cc1a0d',
            70: '#b71100',
            80: '#9f1400',
            90: '#831400',
            100: '#661300',
        },
    },
    colorAssignments: {
        main: ['primary', 80],
        buttonPrimary: ['primary', 80],
        buttonLight: ['secondary', 40],
        textAction: ['secondary', 40],
        input: ['secondary', 30],
        form: ['secondary', 30],
        rating: ['secondary', 30],
        booking: ['primary', 80],
        calendar: defaultTheme.colorAssignments.calendar,
    },
    maxWidths: {
        xs: '464px',
        s: '636px',
        m: '960px',
        l: '1152px',
        xl: '1344px',
        xxl: '1440px',
    },
    radius: {
        button: '0.8rem', // we use this for buttons
        textInput: '0.8rem', // we use this for Input, TextInput, SelectDateInputs, Dropdown
        textArea: '0.6rem', // we use this for SelectInput, Select Option, Select open containers, Dropdown open containers
        dropdown: '0.5rem',
    },
};

export default website;
