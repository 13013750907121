import websiteTheme from './website';
import { FontStyle, Theme, Colors } from '@oberoninternal/travelbase-ds/constants/theme';
import { BodyVariant } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';

/**
 * The Schiermonnikoog Voorkant theme.
 * The Schiermonnikoog theme for the booking site.
 */

const primary = {
    0: '#fff0db',
    5: '#ffe7c2',
    10: '#ffe0a8',
    20: '#ffdb8e',
    30: '#ffd775',
    40: '#ffcf4a',
    50: '#fdc928',
    60: '#f2b215',
    70: '#de9609',
    80: '#af6d02',
    90: '#563200',
    100: '#080400',
};

const colors: Colors = {
    primary,
    tertiary: primary,
    secondary: {
        0: '#4db5d7',
        5: '#4db5d7',
        10: '#4db5d7',
        20: '#4db5d7',
        30: '#4db5d7',
        40: '#2d91ca',
        50: '#1f7aca',
        60: '#1862c5',
        70: '#1145b4',
        80: '#082387',
        90: '#020a4a',
        100: '#000017',
    },
    neutral: {
        0: '#ffffff',
        5: '#fbfcfc',
        10: '#f7f8f9',
        20: '#e6e9eb',
        30: '#cbd0d4',
        40: '#a4acb2',
        50: '#767f87',
        60: '#48515b',
        70: '#212a36',
        80: '#060e1c',
        90: '#00040d',
        100: '#000208',
    },
    positive: {
        0: '#faffeb',
        5: '#e8f6bb',
        10: '#d7ee8e',
        20: '#c7e563',
        30: '#b8dc3c',
        40: '#96ca1e',
        50: '#75b804',
        60: '#5ba600',
        70: '#469400',
        80: '#348200',
        90: '#247000',
        100: '#185e00',
    },
    warning: {
        0: '#fff5eb',
        5: '#f9d9b8',
        10: '#f2bd88',
        20: '#eca15a',
        30: '#e5862e',
        40: '#c96b11',
        50: '#ad5500',
        60: '#914800',
        70: '#753b00',
        80: '#592d00',
        90: '#3d1f00',
        100: '#211100',
    },
    negative: {
        0: '#ffffff',
        5: '#fdd5f0',
        10: '#fbabdd',
        20: '#f982c4',
        30: '#f65aa7',
        40: '#f43385',
        50: '#f20c5f',
        60: '#cf004c',
        70: '#ac0040',
        80: '#890033',
        90: '#650026',
        100: '#420019',
    },
};

const baseTitle: Partial<FontStyle> = {
    color: colors.primary[100],
};

const titleStyles: Record<TitleVariant, FontStyle> = {
    large: {
        size: { min: 19, max: 31 },
        line: { min: 24, max: 32 },
        letterSpacing: 0,
        ...baseTitle,
    },
    regular: {
        size: { min: 19, max: 23 },
        line: { min: 24, max: 28 },
        letterSpacing: 0,
        ...baseTitle,
    },
    small: {
        size: { min: 16, max: 19 },
        line: { min: 24, max: 24 },
        ...baseTitle,
    },
    tiny: {
        size: { min: 16, max: 16 },
        line: { min: 20, max: 20 },
        ...baseTitle,
    },
    micro: {
        size: { min: 15, max: 15 },
        line: { min: 16, max: 16 },
        ...baseTitle,
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 17, max: 20 },
        line: { min: 28, max: 34 },
        weight: 400,
    },
    large: {
        size: { min: 15, max: 19 },
        line: { min: 20, max: 32 },
        letterSpacing: 0,
        weight: 400,
    },
    regular: {
        size: { min: 15, max: 19 },
        line: { min: 20, max: 32 },
        letterSpacing: 0,
        weight: 400,
    },
    small: {
        size: { min: 13, max: 14 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 500,
    },
    tiny: {
        size: { min: 12, max: 13 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
    micro: {
        size: { min: 12, max: 12 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
};

const schier: Theme = {
    ...websiteTheme,
    name: 'Schier',
    fontFamily: {
        title: 'Roboto Condensed, sans-serif',
        body: 'Roboto Condensed, sans-serif',
    },
    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    colors,
    colorAssignments: {
        main: ['primary', 60],
        buttonPrimary: ['primary', 60],
        buttonLight: ['primary', 30],
        textAction: ['primary', 40],
        input: ['secondary', 50],
        form: ['secondary', 50],
        rating: ['secondary', 40],
        booking: ['secondary', 50],
        special: ['primary', 40],
        calendar: websiteTheme.colorAssignments.calendar,
    },
    radius: {
        button: '0',
        textArea: '0.5rem',
        textInput: '0.5rem',
        dropdown: '0.5rem',
    },
};

export default schier;
