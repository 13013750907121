import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderSummaryItem_UpsellPurchase_Fragment } from '../generated/graphql';
import Stack from './Stack';
import SummaryBarItem, { SummaryBarItemField } from './SummaryBarItem';
import SummaryBarItemPriceLine from './SummaryBarItemPriceLine';

export const fragment = gql`
    fragment OrderSummaryItemUpsellPurchase on UpsellPurchase {
        priceLines {
            ...PriceLine
        }
        totalPrice
        paymentPrice
        offer {
            id
        }
    }

    fragment PriceLine on PriceLine {
        label
        quantity
        totalPrice
        unitPrice
        category
    }
`;

interface Props {
    item: OrderSummaryItem_UpsellPurchase_Fragment;
    updating?: boolean;
}

const SummaryBarItemUpsell: FC<React.PropsWithChildren<Props>> = ({ item, updating }) => (
    <SummaryBarItem updating={updating} item={item}>
        <SummaryBarItemField title={<FormattedMessage defaultMessage="Speciale aanbieding" />}>
            <Stack spacing={3}>
                {item.priceLines.map((line, key) => (
                    <SummaryBarItemPriceLine priceLine={line} key={key} />
                ))}
            </Stack>
        </SummaryBarItemField>
    </SummaryBarItem>
);

export default SummaryBarItemUpsell;
