import { Colors } from '@oberoninternal/travelbase-ds/themes/default';
import websiteTheme from './website';
import { FontStyle, Theme } from '@oberoninternal/travelbase-ds/constants/theme';
import { BodyVariant } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';

const colors: Colors = {
    primary: {
        ...websiteTheme.colors.primary,
        0: '#f5f6f8',
        5: '#ccd0dd',
        10: '#a4aac1',
        20: '#7b85a6',
        30: '#535f8b',
        40: '#2a3a6f',
        50: '#021454',
        60: '#021147',
        70: '#010e39',
        80: '#010a2c',
        90: '#01071e',
        100: '#000411',
    },
    secondary: {
        ...websiteTheme.colors.secondary,
        60: '#ffeeff',
        70: '#e77681',
        80: '#e25563',
        90: '#dc3545',
        100: '#b92d3a',
    },
    tertiary: {
        ...websiteTheme.colors.tertiary,
        0: '#ffffff',
        5: '#f4f4f4',
        10: '#e0e0e0',
        20: '#c6c6c6',
        30: '#a8a8a8',
        40: '#8d8d8d',
        50: '#6f6f6f',
        60: '#525252',
        70: '#393939',
        80: '#262626',
        90: '#161616',
        100: '#000000',
    },
    neutral: websiteTheme.colors.neutral,
    positive: websiteTheme.colors.positive,
    warning: websiteTheme.colors.warning,
    negative: websiteTheme.colors.negative,
};

const titleStyles: Record<TitleVariant, FontStyle> = {
    large: {
        size: { min: 31, max: 31 },
        line: { min: 40, max: 40 },
        weight: 500,
        color: colors.neutral[100],
    },
    regular: {
        size: { min: 23, max: 23 },
        line: { min: 28, max: 28 },
        weight: 500,
        color: colors.tertiary[100],
    },
    small: {
        size: { min: 19, max: 19 },
        line: { min: 24, max: 24 },
        weight: 500,
        color: colors.tertiary[100],
    },
    tiny: {
        size: { min: 16, max: 16 },
        line: { min: 20, max: 20 },
        weight: 500,
        color: colors.tertiary[100],
    },
    micro: {
        size: { min: 15, max: 15 },
        line: { min: 20, max: 20 },
        weight: 500,
        color: colors.tertiary[100],
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 20, max: 20 },
        line: { min: 24, max: 24 },
        weight: 400,
        color: colors.tertiary[100],
    },
    large: {
        size: { min: 18, max: 18 },
        line: { min: 24, max: 24 },
        weight: 400,
        color: colors.tertiary[100],
    },
    regular: {
        size: { min: 16, max: 16 },
        line: { min: 24, max: 24 },
        letterSpacing: 0,
        weight: 400,
        color: colors.tertiary[100],
    },
    small: {
        size: { min: 14, max: 14 },
        line: { min: 24, max: 24 },
        letterSpacing: 0,
    },
    tiny: {
        size: { min: 12, max: 12 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
    micro: {
        size: { min: 10, max: 10 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
    },
};

const zandvoortTheme: Theme = {
    ...websiteTheme,
    name: 'Zandvoort',

    fontFamily: {
        title: 'Nunito Sans, sans-serif',
        body: 'Nunito Sans, sans-serif',
    },

    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    colors,
    colorAssignments: {
        main: ['primary', 80],
        buttonPrimary: ['secondary', 80],
        buttonLight: ['primary', 80],
        textAction: ['primary', 80],
        input: ['primary', 80],
        form: ['primary', 80],
        rating: ['primary', 40],
        booking: ['primary', 80],
        calendar: {
            ...websiteTheme.colorAssignments.calendar,

            bookableWithin: {
                selected: ['neutral', 0],
                notSelected: ['neutral', 0],
            },
            bookableOutside: ['neutral', 0],
            bookable: {
                backgroundColor: ['secondary', 60],
                color: ['neutral', 100],
                focusColor: ['secondary', 80],
            },
            withinCss: {
                selected: ['neutral', 0],
                notSelected: ['secondary', 60],
            },
            selected: {
                backgroundColor: ['secondary', 80],
                color: ['neutral', 0],
                focusColor: ['secondary', 60],
            },
            periodBackground: {
                selected: ['secondary', 80],
                notSelected: ['secondary', 80],
            },
            price: ['secondary', 80],
        },
    },
    maxWidths: {
        xs: '464px',
        s: '636px',
        m: '960px',
        l: '1152px',
        xl: '1344px',
        xxl: '1440px',
    },
    radius: {
        button: '8px', // we use this for buttons and SearchBar when inline on Search
        textInput: '0.8rem', // we use this for Input, TextInput, SelectDateInputs, Dropdown
        textArea: '0.6rem', // we use this for SelectInput, Select Option, Select open containers, Dropdown open containers
        dropdown: '0.5rem',
    },
};

export default zandvoortTheme;
