import { Box, BoxProps } from '@rebass/grid';
import styled from 'styled-components';
import { gridColumn, GridRowProps, GridColumnProps, compose, gridRow } from 'styled-system';

export type CellProps = GridColumnProps & GridRowProps & BoxProps;

const cell = compose(gridColumn, gridRow);

const Cell = styled(Box)<CellProps>(cell);

export default Cell;
