import { ToastContainer, ToastContainerProps } from 'react-toastify';
import styled from 'styled-components';
import React from 'react';

const Toasts = ({ className, ...rest }: ToastContainerProps & { className?: string }) => (
    <div className={className}>
        <ToastContainer
            position="bottom-right"
            draggable={false}
            autoClose={false}
            closeButton={false}
            hideProgressBar
            closeOnClick={false}
            {...rest}
        />
    </div>
);

export default styled(Toasts)`
    .Toastify__toast-container {
        color: inherit;
        width: unset;
        border-radius: 2px;
    }
    .Toastify__toast {
        font-family: inherit;
        padding: 0;
        cursor: inherit;
        color: inherit !important;
        box-shadow: 0 20px 40px 0 rgba(16, 36, 48, 0.06);
    }
    .Toastify__close-button {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
`;
