import React from 'react';
import { useIntl } from 'react-intl';
import { Localizable, Locale } from '../../entities/Locale';
import { Translation } from '../../entities/Translation';
import Cell from '../designsystem/Cell';

const translations: Record<string, Translation> = {
    text: {
        nl: 'Niet al het aanbod in onze regio is direct via ons online boekbaar.',
        de: 'Nicht alles, was wir anbieten, kann über VVV gebucht werden',
        en: 'Not all accommodations can be booked through our site',
    },
    button: {
        nl: 'Bekijk hier al het aanbod',
        de: 'Sehen Sie sich hier das gesamte Angebot an',
        en: 'View all the accommodations here',
    },
};

const urls: Localizable<string> = {
    nl: 'https://www.waterlandvanfriesland.nl/nl/plan-je-bezoek/overnachten',
    en: 'https://www.waterlandvanfriesland.nl/en/visit/staying-overnight',
    de: 'https://www.waterlandvanfriesland.nl/de/besuchen/ubernachten',
};

const LowSearchListing = () => {
    const intl = useIntl();
    return (
        <Cell gridColumn={['1 / -1']} style={{ textAlign: 'center' }}>
            <p>{translations.text[intl.locale as Locale]}</p>
            <a href={urls[intl.locale as Locale]} className="pagepart__footer-btn btn">
                {translations.button[intl.locale as Locale]}
            </a>
        </Cell>
    );
};

export default LowSearchListing;
