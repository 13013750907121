import websiteTheme from './website';
import { FontStyle, Theme, Colors } from '@oberoninternal/travelbase-ds/constants/theme';
import { BodyVariant } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';
import defaultTheme from '@oberoninternal/travelbase-ds/themes/default';

/**
 * The Visit Wadden Voorkant theme.
 * The Visit Wadden theme for the booking site.
 */

const primary = {
    0: '#fffcf2',
    5: '#fffae5',
    10: '#fff7d9',
    20: '#fff1bf',
    30: '#ffe999',
    40: '#ffde66',
    50: '#ffd333',
    60: '#ffc800',
    70: '#bf9600',
    80: '#806400',
    90: '#403200',
    100: '#000',
};

const colors: Colors = {
    primary,
    tertiary: primary,
    secondary: primary,
    neutral: defaultTheme.colors.neutral,
    positive: defaultTheme.colors.positive,
    warning: defaultTheme.colors.warning,
    negative: defaultTheme.colors.negative,
};

const baseTitle: Partial<FontStyle> = {
    color: colors.primary[100],
    weight: 500,
};

const titleStyles: Record<TitleVariant, FontStyle> = {
    large: {
        size: { min: 30, max: 50 },
        line: { min: 29, max: 48 },
        letterSpacing: 0,
        ...baseTitle,
    },
    regular: {
        size: { min: 19, max: 32 },
        line: { min: 28, max: 28 },
        ...baseTitle,
    },
    small: {
        size: { min: 17, max: 20 },
        line: { min: 28, max: 28 },
        ...baseTitle,
    },
    tiny: {
        size: { min: 17, max: 22 },
        line: { min: 28, max: 28 },
        ...baseTitle,
    },
    micro: {
        size: { min: 17, max: 17 },
        line: { min: 28, max: 28 },
        ...baseTitle,
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 18, max: 20 },
        line: { min: 28, max: 34 },
        weight: 400,
    },
    large: {
        size: { min: 17, max: 19 },
        line: { min: 28, max: 32 },
        letterSpacing: 0,
        weight: 400,
    },
    regular: {
        size: { min: 17, max: 19 },
        line: { min: 28, max: 32 },
        letterSpacing: 0,
        weight: 400,
    },
    small: {
        size: { min: 13, max: 14 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 500,
    },
    tiny: {
        size: { min: 12, max: 13 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
    micro: {
        size: { min: 12, max: 12 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
};

const wadden: Theme = {
    ...websiteTheme,
    name: 'Visit Wadden',
    fontFamily: {
        title: 'Rubik, Arial Narrow, Arial, sans-serif',
        body: 'Rubik, Arial Narrow Arial, sans-serif',
    },
    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    colors,
    colorAssignments: {
        main: ['primary', 60],
        buttonPrimary: ['primary', 100],
        buttonLight: ['primary', 100],
        textAction: ['primary', 60],
        input: ['primary', 60],
        form: ['primary', 60],
        rating: ['primary', 60],
        booking: ['primary', 60],
        special: ['primary', 60],
        calendar: websiteTheme.colorAssignments.calendar,
    },
    radius: {
        button: '0',
        textArea: '0.5rem',
        textInput: '0.5rem',
        dropdown: '0.5rem',
    },
};

export default wadden;
