import { Main } from 'next/document';
import React from 'react';
import { Transform } from 'react-html-parser';
import { FormattedMessage } from 'react-intl';
import { PaymentMethodEnum, SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';
import uitinderegioTheme from '../themes/uitinderegio';
import { BrandConfig } from './brandConfig';
import uitinderegioBg from '../../public/static/img/uitinderegioBg.jpg';
import { rentalUnitTypes } from './filterTypes';
import Phone from '../components/svg/socials/Phone.svg';
import TranslatedPhoneNumber from '../components/TranslatedPhoneNumber';

const uitinderegioConfig: BrandConfig = {
    name: 'Uitinderegio',
    locales: ['nl', 'de', 'en'],
    theme: uitinderegioTheme,
    indexImage: uitinderegioBg,
    gtmID: 'GTM-5BQKMKDQ',
    globalStyling: `
    input[readonly]{
        background-color: inherit !important;
        cursor: inherit !important;
    }

    footer {
        z-index: 1 !important;
    }

    main {
        padding-top: 0 !important;
    }
    .searchbar {
        margin-top: 12rem !important;
    }

    .TrengoWidgetLauncher__iframe {
        bottom: 10rem !important;
    }

    .scrolltop {
        bottom: 18rem !important;
    }

    @media screen and (max-width: ${uitinderegioTheme.mediaQueriesValues.s - 1}px) {
        .searchbar {
            margin-top: 8rem !important;
        }

    }

    .hero-searchbar-container {
        top: 0 !important;
    }
    @media screen and (max-width: ${uitinderegioTheme.mediaQueriesValues.m - 1}px) {
        .checkout-content-wrapper {
            padding-top: 3.2rem !important;
        }
    }

    .menu-item.menu-item-has-children a:after {
        right: -4px !important;
    }

    #search-page {
        padding-top: 2rem;
    }
    .menu.large-horizontal {
        gap: 2rem;
    }
    .widgets {
        width: 100% !important;
        max-width: 120rem !important;
    }
    .footer-socials {
        display: flex !important;
    }
    .footer-socials .social-link {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 0 !important;
    }
    footer {
        font-size: 0.875em !important;
    }
    footer h6 {
        font-size: 1em !important;
    }
    footer p {
        margin: 0 0 1rem !important;
    }
    .copyright > .row {
        width: 100% !important;
        max-width: 120rem !important;
        margin: 0 auto !important;
    }
`,

    template: {
        containerId: 'main',
        urls: {
            nl: 'https://www.uitinderegio.nl/zoek-en-boek/',
            de: 'https://www.uitinderegio.nl/zoek-en-boek/',
            en: 'https://www.uitinderegio.nl/zoek-en-boek/',
        },
        hrefParser: href => href.replace('lege-pagina', ''),
        getCustomMainRenderer: async () => {
            const { convertNodeToElement } = await import('react-html-parser');
            return parserArgs => {
                const [node, , transform] = parserArgs;
                // @ts-ignore
                const children = node.children?.map((child, i) =>
                    convertNodeToElement(child, i, transform as Transform)
                );
                return (
                    <main role="main">
                        <Main />
                        {children}
                    </main>
                );
            };
        },
    },

    contact: {
        page: {
            nl: 'https://www.uitinderegio.nl/contact',
            de: 'https://www.uitinderegio.nl/contact',
            en: 'https://www.uitinderegio.nl/contact',
        },
        info: [
            {
                name: (
                    <>
                        <FormattedMessage defaultMessage="Bel" />{' '}
                        <TranslatedPhoneNumber phoneNumber="+31 886 363 888" />
                    </>
                ),
                content: <FormattedMessage defaultMessage="ma. t/m za. tussen 8.30 en 17.00 uur." />,
                icon: <Phone width={24} height={24} />,
                link: 'tel:+31886363888',
            },
        ],
    },
    termsAndConditions: {
        nl: 'https://www.uitinderegio.nl/disclaimer',
        de: 'https://www.uitinderegio.nl/disclaimer',
        en: 'https://www.uitinderegio.nl/disclaimer',
    },

    accommodationTypes: [
        {
            // not a valid filter, so make sure to escape the value when using it
            message: 'all',
            value: 'all',
        },
        {
            message: 'vacationHomes',
            value: rentalUnitTypes.HOME,
        },
        {
            message: 'caravan',
            value: `${rentalUnitTypes.HOME} type-of-accommodation.mobile-home type-of-accommodation.touring-caravan type-of-accommodation.chalet`,
        },
        {
            message: 'hotelOrBnB',
            value: rentalUnitTypes.ROOM,
        },
        {
            message: 'groupAccommodations',
            value: `${rentalUnitTypes.HOME} type-of-accommodation.group-accommodation`,
        },
        {
            message: 'camping',
            value: `${rentalUnitTypes.CAMPING_PITCH}`,
        },
    ],
    lastMinute: {
        defaultAccommodationType: 'all',
    },
    groupTypes: ['adults', 'youth', 'children', 'babies', 'pets'],
    unitSearchOrder: {
        popularity: SearchRentalUnitsOrderEnum.RandomBoosted,
        ratingDesc: SearchRentalUnitsOrderEnum.ReviewRatingDesc,
    },
    tripsSearchOrder: {
        popularity: UnitSearchOrderEnum.RandomBoosted,
        ratingDesc: UnitSearchOrderEnum.ReviewRatingDesc,
        priceAsc: UnitSearchOrderEnum.PriceAsc,
        priceDesc: UnitSearchOrderEnum.PriceDesc,
    },

    // Map zoom level
    mapZoom: 14,

    // Map min zoom level
    mapMinZoom: {
        desktop: 10.5,
        mobile: 8.5,
    },

    coordinates: {
        latitude: 51.914727943280376,
        longitude: 5.646377242329304,
    },

    seo: {
        twitterUsername: '@rivierenlandnl',
        siteName: 'Uitinderegio.nl',
    },

    minBookingAge: 21,
    cancellationInsuranceDefault: true,

    checkout: {
        mobileHeaderPlacement: 'bottom',
        initialPaymentMethod: {
            nl: PaymentMethodEnum.Ideal,
            en: PaymentMethodEnum.CreditCard,
            de: PaymentMethodEnum.Paypal,
        },
    },

    complaintFormUrl: {
        nl: 'https://www.uitinderegio.nl/contact',
        de: 'https://www.uitinderegio.nl/contact',
        en: 'https://www.uitinderegio.nl/contact',
    },
    homepageUrl: {
        nl: 'https://www.uitinderegio.nl',
        de: 'https://www.uitinderegio.nl',
        en: 'https://www.uitinderegio.nl',
    },
};

export default uitinderegioConfig;
