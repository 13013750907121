import { BrandConfig } from '../constants/brandConfig';
import { Locale } from '../entities/Locale';

const getActivityPageSlugByLocale = (locale: Locale, brandConfig?: BrandConfig) => {
    if (brandConfig?.customSlugs?.activity?.[locale]) {
        return brandConfig?.customSlugs?.activity[locale];
    }

    switch (locale) {
        case 'nl':
            return 'activiteit';
        case 'de':
            return 'aktivitat';
        case 'en':
        default:
            return 'activity';
    }
};

const getCompanyPageSlugByLocale = (locale: Locale, brandConfig?: BrandConfig) => {
    if (brandConfig?.customSlugs?.company?.[locale]) {
        return brandConfig?.customSlugs?.company[locale];
    }

    switch (locale) {
        case 'nl':
            return 'onderneming';
        case 'de':
            return 'firma';
        case 'en':
        default:
            return 'company';
    }
};

const getAccommodationPageSlugByLocale = (locale: Locale, brandConfig?: BrandConfig) => {
    if (brandConfig?.customSlugs?.accommodation?.[locale]) {
        return brandConfig?.customSlugs?.accommodation[locale];
    }

    switch (locale) {
        case 'nl':
            return 'accommodatie';
        case 'de':
            return 'unterkunft';
        case 'en':
        default:
            return 'accommodation';
    }
};

export { getActivityPageSlugByLocale, getCompanyPageSlugByLocale, getAccommodationPageSlugByLocale };
