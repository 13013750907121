import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/storage';
import getStorageWithExpiry from './getStorageWithExpiry';

export interface SuccessData {
    token_type: string;
    expires_in: number;
    access_token: string;
    refresh_token: string;
}

const login = async (authCode: string): Promise<boolean> => {
    const formData = new FormData();
    formData.append('grant_type', 'authorization_code');
    formData.append('client_id', 'frontend');
    formData.append('scope', 'internal');
    formData.append('code', authCode);
    const storageUtils = getStorageWithExpiry('local');

    try {
        const result = await fetch(`${process.env.NEXT_PUBLIC_CORE_API_URL}/api/public/oauth/token/`, {
            method: 'POST',
            // https://github.com/microsoft/TypeScript/issues/30584
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            body: formData as any,
        });

        if (result.ok) {
            const data: SuccessData = await result.json();
            storageUtils?.setItem(ACCESS_TOKEN, data.access_token);
            storageUtils?.setItem(REFRESH_TOKEN, data.refresh_token);
            return true;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
    return false;
};

const logout = () => {
    const storageUtils = getStorageWithExpiry('local');
    storageUtils?.removeItem(ACCESS_TOKEN);
    storageUtils?.removeItem(REFRESH_TOKEN);
};

const refresh = async (): Promise<boolean> => {
    const storageUtils = getStorageWithExpiry('local');
    const token = storageUtils?.getItem(REFRESH_TOKEN);
    if (!token) {
        return false;
    }

    const formData = new FormData();
    formData.append('grant_type', REFRESH_TOKEN);
    formData.append('client_id', 'frontend');
    formData.append(REFRESH_TOKEN, token);

    try {
        const result = await fetch(`${process.env.NEXT_PUBLIC_CORE_API_URL}/api/public/oauth/token/`, {
            method: 'POST',
            // https://github.com/microsoft/TypeScript/issues/30584
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            body: formData as any,
        });

        if (result.ok) {
            const data: SuccessData = await result.json();

            storageUtils?.setItem(ACCESS_TOKEN, data.access_token);
            storageUtils?.setItem(REFRESH_TOKEN, data.refresh_token);
            return true;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    // remove current tokens
    logout();
    return false;
};

export { login, refresh, logout, ACCESS_TOKEN, REFRESH_TOKEN };
