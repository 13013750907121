import React from 'react';
import styled from 'styled-components';

const Navigation = () => (
    <NavWrapper>
        <NavList>
            <NavItem>
                <NavLink href="https://spektakel.musicaldetocht.frl/spektakelmusical-de-tocht/">Home</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="https://spektakel.musicaldetocht.frl/spektakelmusical-de-tocht/tickets">Tickets</NavLink>
            </NavItem>
        </NavList>
    </NavWrapper>
);

const NavLink = styled.a`
    color: ${({ theme }) => theme.colors.primary[90]};
    font-weight: 900;
    text-decoration: none;
`;

const NavItem = styled.li`
    position: relative;
    padding: 1.5rem 2.5rem;
    min-width: 8rem;
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 50%;
        bottom: 0px;
        width: 0%;
        height: 3px;
        transition: all 300ms ease 0s;
    }

    &:hover::after {
        background-color: ${({ theme }) => theme.colors.primary[80]};
        left: 0%;
        width: 100%;
    }
`;

const NavWrapper = styled.nav`
    display: flex;
    align-items: center;
    gap: 6.4rem;
`;

const NavList = styled.ul`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing['00_None']};
    padding: 0;
    margin: 0;
`;

export default Navigation;
