import { Main } from 'next/document';
import React from 'react';
import { Transform } from 'react-html-parser';
import { FormattedMessage } from 'react-intl';
import waterlandBg from '../../public/static/img/waterlandBg.jpg';
import Mail from '../components/svg/socials/Mail.svg';
import Phone from '../components/svg/socials/Phone.svg';
import TranslatedPhoneNumber from '../components/TranslatedPhoneNumber';
import LowSearchListing from '../components/waterland/LowSearchListing';
import { PaymentMethodEnum, SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';
import waterlandTheme from '../themes/waterland';
import { BrandConfig } from './brandConfig';
import { rentalUnitTypes } from './filterTypes';
import { uspIcons } from './UspIcons';

const usps = [
    {
        title: <FormattedMessage values={uspIcons} defaultMessage="{tag} Geen reserveringskosten" />,
        description: (
            <FormattedMessage defaultMessage="Wij rekenen geen reserverings-, of andere verborgen kosten voor het boeken van je accommodatie. Zo boek je lokaal tegen de beste prijs!" />
        ),
    },
    {
        title: <FormattedMessage values={uspIcons} defaultMessage="{local} Lokaal boeken" />,
        description: (
            <FormattedMessage defaultMessage="Je boekt lokaal, direct bij de VVV van Zuidwest Friesland. Met je lokale boeking help je ons en onze ondernemers." />
        ),
    },
    {
        title: <FormattedMessage values={uspIcons} defaultMessage="{euro} Beste prijs" />,
        description: (
            <FormattedMessage defaultMessage="Geen verborgen kosten. Direct de beste prijs voor jouw vakantie in onze regio!" />
        ),
    },
    {
        title: <FormattedMessage values={uspIcons} defaultMessage="{passport} Fries paspoort met kortingen" />,
        description: (
            <FormattedMessage defaultMessage="Ontvang een ‘Frysk Paspoart’ bij je boeking en burger optimaal in. Daarnaast krijg je leuke kortingen op lokale producten in onze VVV winkels" />
        ),
    },
    {
        title: <FormattedMessage values={uspIcons} defaultMessage="{shield} VVV zekerheid" />,
        description: (
            <FormattedMessage defaultMessage="Boeken via de VVV betekent geen onaangename verrassingen, maar veilig en zeker je vakantie regelen." />
        ),
    },
];
const waterlandConfig: BrandConfig = {
    name: 'Waterland',
    locales: ['nl', 'de', 'en'],
    theme: waterlandTheme,
    indexImage: waterlandBg,
    gtmID: 'GTM-PP74XLP',
    // language=CSS
    globalStyling: `
        main {
            padding-top: 0 !important;
            --page-margin: 12rem;
        }


        @media screen and (max-width: ${waterlandTheme.mediaQueriesValues.s - 1}px) {
            main {
                --page-margin: 8rem;
            }
        }

        .search-usps {
            margin-top: var(--page-margin);
        }

        .hero-searchbar-container {
            top: var(--page-margin) !important;
        }

        .checkout-content-wrapper {
            padding-top: var(--page-margin) !important;
        }

        @media screen and (min-width: ${waterlandTheme.mediaQueries.m}) {
            .tips-image-container {
                aspect-ratio: unset !important;
                height: 100%;
            }
        }

        .newsletter-popup__wrapper--visible {
            display: none !important;
        }

    `,
    template: {
        containerId: 'main',
        urls: {
            nl: 'https://www.waterlandvanfriesland.nl/nl/lege-pagina',
            de: 'https://www.waterlandvanfriesland.nl/de/lege-pagina',
            en: 'https://www.waterlandvanfriesland.nl/en/lege-pagina',
        },
        hrefParser: href => href.replace('lege-pagina', ''),
        getCustomMainRenderer: async () => {
            const { convertNodeToElement } = await import('react-html-parser');
            return parserArgs => {
                const [node, , transform] = parserArgs;
                // @ts-ignore
                const children = node.children?.map((child, i) =>
                    convertNodeToElement(child, i, transform as Transform)
                );
                return (
                    <main role="main">
                        <Main />
                        {children}
                    </main>
                );
            };
        },
    },

    contact: {
        info: [
            {
                name: (
                    <>
                        <FormattedMessage defaultMessage="Bel" />{' '}
                        <TranslatedPhoneNumber phoneNumber="+31 513 416 030" />
                    </>
                ),
                content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                icon: <Phone width={24} height={24} />,
                link: 'tel:+31513416030',
            },
            {
                name: <FormattedMessage defaultMessage="E-mail" />,
                content: <FormattedMessage defaultMessage="Je krijgt binnen twee werkdagen een reactie." />,
                icon: <Mail width={24} height={24} />,
                link: 'mailto:info@waterlandvanfriesland.nl',
                queryParams: {
                    nl: {
                        subject: 'Beste VVV Waterland van Friesland, ik heb een vraag over {rentalUnitName}',
                    },
                    de: {
                        subject:
                            'Sehr geehrter Mitarbeiter des VVV Waterland van Friesland, ich habe eine Frage zu {rentalUnitName}',
                    },
                    en: {
                        subject: 'Dear VVV Waterland van Friesland, I have a question about {rentalUnitName}',
                    },
                },
            },
        ],

        page: {
            nl: 'https://www.waterlandvanfriesland.nl/nl/organisatie/contact-ons',
            de: 'https://www.waterlandvanfriesland.nl/de/organisation/ihr-kontakt-zu-uns',
            en: 'https://www.waterlandvanfriesland.nl/en/organisation/contact-us',
        },
    },
    placeFilter: {
        show: false,
    },
    accommodationTypes: [
        {
            // not a valid filter, so make sure to escape the value when using it
            message: 'all',
            value: 'all',
        },
        {
            message: 'vacationHomes',
            value: rentalUnitTypes.HOME,
        },
        {
            message: 'hotelOrBnB',
            value: rentalUnitTypes.ROOM,
        },
        {
            message: 'groupAccommodations',
            value: `${rentalUnitTypes.HOME} type-of-accommodation.group-accommodation`,
        },
        {
            message: 'tinyHouse',
            value: `${rentalUnitTypes.HOME} type-of-accommodation.tiny-house`,
        },
        {
            message: 'boat',
            value: `${rentalUnitTypes.BOAT}`,
        },
        {
            message: 'camping',
            value: `${rentalUnitTypes.TENT}`,
        },
    ],

    groupTypes: ['adults', 'youth', 'children', 'babies', 'pets'],
    unitSearchOrder: {
        popularity: SearchRentalUnitsOrderEnum.RecentBookingsBoosted,
        ratingDesc: SearchRentalUnitsOrderEnum.ReviewRatingDesc,
    },
    tripsSearchOrder: {
        popularity: UnitSearchOrderEnum.RecentBookingsBoosted,
        ratingDesc: UnitSearchOrderEnum.ReviewRatingDesc,
        priceAsc: UnitSearchOrderEnum.PriceAsc,
        priceDesc: UnitSearchOrderEnum.PriceDesc,
    },

    // Map zoom level
    mapZoom: 10.5,

    // Map min zoom level
    mapMinZoom: {
        desktop: 10.5,
        mobile: 8.5,
    },

    coordinates: {
        latitude: 52.3892,
        longitude: 4.8906,
    },

    seo: {
        twitterUsername: '@waterlandvf',
        siteName: 'VVV Waterland van Friesland',
        homeTitle: {
            nl: 'Zoek en boek je overnachting | VVV Zuidwest Friesland ',
            de: 'Buchen Sie ein Ferien in Friesland | VVV Südwestfriesland',
            en: 'Book your stay in Friesland | Southwest Friesland Tourist Board',
        },
        siteDescription: {
            nl: 'Boek hier bij de VVV je overnachting voor een vakantie in Friesland. Vind hier je vakantiehuisje, B&B, hotel of glamping tent.',
            de: 'Ferienhaüser, Zelt, Hotelzimmer oder Bed and Breakfast mieten in Friesland? Wählen Sie Ihr Lieblingsort und geniessen Sie Friesland.',
            en: 'Always wanted to rent a nice cottage, holiday house of tent in Friesland? Find your favorite place and enjoy Friesland.',
        },
    },

    minBookingAge: 18,
    cancellationInsuranceDefault: false,
    customContent: {
        LowSearchListing,
    },

    bakedInFilterProperty: '_channel.waterland',

    searchBox: {
        hideScrollbar: true,
    },
    termsAndConditions: {
        nl: 'https://www.waterlandvanfriesland.nl/nl/algemene-boekingsvoorwaarden',
        de: ' https://www.waterlandvanfriesland.nl/de/allgemeine-buchungsbedingungen',
        en: ' https://www.waterlandvanfriesland.nl/en/general-booking-conditions',
    },
    checkout: {
        mobileHeaderPlacement: 'bottom',
        initialPaymentMethod: {
            nl: PaymentMethodEnum.Ideal,
            en: PaymentMethodEnum.CreditCard,
            de: PaymentMethodEnum.Paypal,
        },
        insuranceTerms: {
            nl: 'https://assets.citynavigator.nl/kuma-waterlandvanfriesland/uploads/media/626a452d71606/samengestelde-voorwaarden-kortlopend-annuleringsverzekering-2021.pdf?token=/uploads/media/626a452d71606/samengestelde-voorwaarden-kortlopend-annuleringsverzekering-2021.pdf',
            de: 'https://assets.citynavigator.nl/kuma-waterlandvanfriesland/uploads/media/626a52c94039e/allianz-polisvoorwaarden-annuleringsverzekering-duits-27-07-21.pdf?token=/uploads/media/626a52c94039e/allianz-polisvoorwaarden-annuleringsverzekering-duits-27-07-21.pdf',
            en: 'https://assets.citynavigator.nl/kuma-waterlandvanfriesland/uploads/media/626a549314c34/allianz-polisvoorwaarden-annuleringsverzekering-engels-27-07-21.pdf?token=/uploads/media/626a549314c34/allianz-polisvoorwaarden-annuleringsverzekering-engels-27-07-21.pdf',
        },
    },
    complaintFormUrl: {
        nl: 'https://www.waterlandvanfriesland.nl/nl/organisatie/contact-ons/contactformulier',
        de: 'https://www.waterlandvanfriesland.nl/de/organisation/ihr-kontakt-zu-uns/kontaktformular',
        en: 'https://www.waterlandvanfriesland.nl/en/organisation/contact-us/contact-form',
    },
    locationDescription: {
        imgSrc: '/static/img/waterlandTips.jpg',
        imgAlt: {
            nl: 'Dit is Waterland van Friesland',
            en: 'This is Waterland van Friesland',
            de: 'Das ist Waterland van Friesland',
        },
        description: {
            nl: 'Als VVV kennen we de bijzondere plekjes. Hieronder hebben we enkele tips opgeschreven die je bezoek nog leuker maken. Op zoek naar meer? Laat je dan verrassen door de veelzijdigheid van onze regio via speciale <a href="https://www.waterlandvanfriesland.nl/nl/inspiratie/verhalenroutes"target="_blank">inspiratie verhalenroutes.</a>',
            en: 'At the tourist information centre we know all the special places. We’ve put together a few tips for you to make your visit even more enjoyable. Would you like more? Let the versatility of our region surprise you and set off on one of our very special <a href="https://www.waterlandvanfriesland.nl/en/inspiration/story-trails" target="_blank">inspirational story trails</a>.',
            de: 'Der VVV kennt sich aus in der Region und verrät Ihnen gerne ein paar Geheimtipps, die Ihren Aufenthalt bei uns unvergesslich machen. Lust auf mehr? Lassen Sie sich von der Vielseitigkeit unserer Region überraschen und begeben Sie sich auf eine unserer speziellen <a href="https://www.waterlandvanfriesland.nl/de/inspiration/geschichtenrouten" target="_blank">Inspirations- und Geschichtenrouten.</a>',
        },
        tips: {
            nl: [
                'Zeven van de Elfsteden liggen in onze regio. Maak een <a href="https://www.waterlandvanfriesland.nl/nl/stedentrip" target="_blank">stedentrip</a> vol kunst, cultuur en gezelligheid!',
                'Bezoek het <a href="https://www.waterlandvanfriesland.nl/nl/inspiratie/verhalenroutes/stromen-in-waterland/woudagemaal" target="_blank">UNESCO Werelderfgoed Ir. D.F. Woudagemaal</a> in Lemmer, of één van de andere <a href="https://www.waterlandvanfriesland.nl/nl/plan-je-bezoek/zien-en-doen/kunst-en-cultuur/alle-musea" target="_blank">20 musea</a>.',
                'Ontdek <a href="https://www.waterlandvanfriesland.nl/nl/gaasterland" target="_blank">Gaasterland</a> met glooiende heuvels, bossen en kliffen langs het IJsselmeer. Wist je dat onze regio een <a href="https://www.waterlandvanfriesland.nl/nl/nationaal-landschap" target="_blank">Nationaal Landschap</a> is?',
                'De IJsselmeerkust en onze Friese meren hebben bij elkaar meer dan <a href="https://www.waterlandvanfriesland.nl/nl/plan-je-bezoek/watersport/strand-friesland" target="_blank">25 bijzondere stranden</a>. Ontdek jouw favoriete plekje!',
                'Fietsen en wandelen kan bij ons over water. Ontdek bijzondere <a href="https://www.waterlandvanfriesland.nl/nl/plan-je-bezoek/routes/pontjes-routes" target="_blank">pontjes routes</a> in Zuidwest Friesland.',
            ],
            en: [
                'Seven of the eleven famous Frisian cities can be found in our region. Take a <a href="https://www.waterlandvanfriesland.nl/en/inspiration/story-trails/citytrip-in-waterland" target="_blank">city trip</a> full of art, culture and fun!',
                'Visit the <a href="https://www.waterlandvanfriesland.nl/en/inspiration/story-trails/flows-in-waterland/woudagemaal" target="_blank">UNESCO World Heritage site of the Ir. D.F. Wouda pumping station</a> in Lemmer, or one of the other <a href="https://www.waterlandvanfriesland.nl/en/visit/things-to-see-and-do/culture-and-museums/museums" target="_blank">20 museums</a>.',
                'Discover <a href="https://www.waterlandvanfriesland.nl/en/gaasterland" target="_blank">Gaasterland</a> with its rolling hills, woodlands and cliffs along the IJsselmeer lake. Did you know that our region is a designated <a href="https://www.waterlandvanfriesland.nl/en/inspiration/landscape-of-national-importance" target="_blank">landscape of national importance</a>?',
                'The IJsselmeer coastline and our Frisian lakes offer more than <a href="https://www.waterlandvanfriesland.nl/en/visit/water-sports/beaches" target="_blank">25 unique beaches</a>. Discover your favourite spot!',
                'Water is no obstacle during hikes and cycling rides. Discover very special <a href="https://www.waterlandvanfriesland.nl/en/visit/routes" target="_blank">ferry routes</a> in Southwest Friesland.',
            ],
            de: [
                'Elf Städte haben Friesland berühmt gemacht. Sieben davon liegen in unserer Region. Wie wäre es mit einem <a href="https://www.waterlandvanfriesland.nl/de/inspiration/geschichtenrouten/stadtetrip-in-waterland" target="_blank">Städtetrip</a> mit Kunst, Kultur und Altstadtflair?',
                'Besuchen Sie das <a href="https://www.waterlandvanfriesland.nl/de/inspiration/geschichtenrouten/stroeme-im-waterland/woudapumpwerk" target="_blank"></a>UNESCO-Welterbe Ir. D.F. Wouda-Pumpwerk in Lemmer oder eines der anderen <a href="https://www.waterlandvanfriesland.nl/de/besuchen/sehen-staunen-mitmachen/kultur-und-museen/alle-museen" target="_blank">20 Museen</a>.',
                'Entdecken Sie das <a href="https://www.waterlandvanfriesland.nl/de/gaasterland" target="_blank">Gaasterland</a> mit seinen Hügeln, Wäldern und den berühmten IJsselmeer-Klippen. Wussten Sie schon, dass unsere Region ein <a href="https://www.waterlandvanfriesland.nl/de/landschaftspark" target="_blank">nationaler Landschaftspark</a> ist?',
                'Das IJsselmeer und die friesische Seenplatte bieten mehr als <a href="https://www.waterlandvanfriesland.nl/de/besuchen/wassersport/strande-friesland" target="_blank">25 besondere Strände</a>. Auch Sie finden hier garantiert Ihren Lieblingsplatz!',
                'Wasser ist bei (Rad-)Wanderungen kein Hindernis. Auf vielen Routen in Südwestfriesland stoßen Sie immer wieder auf idyllische <a href="https://www.waterlandvanfriesland.nl/de/besuchen/wassersport/fahren" target="_blank">Fähren</a>.',
            ],
        },
    },
    homepageUrl: {
        nl: 'https://www.waterlandvanfriesland.nl',
        de: 'https://www.waterlandvanfriesland.nl/de',
        en: 'https://www.waterlandvanfriesland.nl/en',
    },
    passport: {
        imgSrc: '/static/img/waterlandPassport.jpg',
    },
    showUsps: true,
    usps,
};

export default waterlandConfig;
