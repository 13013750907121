import styled, { css } from 'styled-components';
import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';

export type GradientBackgroundVariant = 'neutral' | 'secondary';

const getGradientCss = (variant: GradientBackgroundVariant) => {
    switch (variant) {
        case 'neutral':
            return css`
                --linearOne: rgba(255, 255, 255, 0);
                --linearTwo: rgb(244, 244, 244, 0.4);
            `;
        case 'secondary':
            return css`
                --linearOne: rgba(255, 255, 255, 0);
                --linearTwo: rgba(255, 230, 221, 0.3);
            `;
        default:
            throw new UnreachableCaseError(variant);
    }
};

const GradientBackground = styled.div<{ variant?: GradientBackgroundVariant }>`
    margin: 0;
    padding: 0;
    height: 100%;
    ${({ variant = 'neutral' }) => getGradientCss(variant)};

    :before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        height: 100%;
        background: linear-gradient(0deg, var(--linearOne) 0%, var(--linearTwo) 100%);
        width: 100%;
        z-index: -1;
    }
`;

export default GradientBackground;
