import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Left from '@oberoninternal/travelbase-ds/components/figure/Left';
import { Box } from '@rebass/grid';
import React, { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { CheckoutPageRoutePayment } from '../hooks/useCheckoutPages';
import CheckoutSteps from './CheckoutSteps';
import ContentWrapper from './ContentWrapper';
import Cell, { CellProps } from './designsystem/Cell';
import GradientBackground from './designsystem/GradientBackground';
import { getPercentageForColumns } from './designsystem/Grid';
import Page from './Page';
import SummaryBar, { SummaryBarProps } from './SummaryBar';
import Head from 'next/head';
import { useTenantContext } from '../context/TenantContext';

const CheckoutPage: FC<PropsWithChildren<{ title?: string }>> = ({ children, title }) => {
    const { brandConfig } = useTenantContext();

    return (
        <>
            <Head>
                {title && <title>{`${title} - ${brandConfig.seo.siteName}`}</title>}
                <meta name="robots" content="noindex" />
            </Head>
            <Page>
                <CheckoutPageGradient />
                <ContentWrapper className="checkout-content-wrapper" variant="checkout">
                    <CheckoutGrid>{children}</CheckoutGrid>
                </ContentWrapper>
            </Page>
        </>
    );
};

export default CheckoutPage;

const CheckoutPageContent = ({
    children,
    paymentType,
    stepsLoading,
    onlyTicketsInCart,
    ...props
}: CellProps & { paymentType?: CheckoutPageRoutePayment; stepsLoading?: boolean; onlyTicketsInCart?: boolean }) => (
    <CheckoutPageContentCell {...props}>
        <CheckoutSteps paymentType={paymentType} loading={stepsLoading} onlyTicketsInCart={onlyTicketsInCart} />
        {children}
    </CheckoutPageContentCell>
);

const CheckoutPageContentCell = (props: CellProps) => (
    <Cell flex={1} order={[2, null, null, 1]} gridColumn={['1 / -1', null, null, '1 / 7', null, '1 / 8']} {...props} />
);

const CheckoutPageSummaryBar = (props: SummaryBarProps) => (
    <Cell order={[1, null, null, 2]} gridColumn={['1 / -1', null, null, '8 / -1', null, '10 / -1']}>
        <SummaryBar {...props} />
    </Cell>
);

const CheckoutPageBackButton: FC<React.PropsWithChildren<ComponentPropsWithoutRef<'button'>>> = ({
    children,
    ...props
}) => (
    <Box pb={6}>
        <TextButton {...props}>
            <Left />
            <span>{children}</span>
        </TextButton>
    </Box>
);

const CheckoutPageGradient = styled(GradientBackground).attrs({ variant: 'secondary' })`
    visibility: hidden;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        visibility: visible;
        :before {
            left: unset;
            right: 0;
            width: calc(100vw / 8 * 3);
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        :before {
            width: calc((100vw - 152rem) / 2 + 50.5rem);
        }
    }
`;

export {
    CheckoutPageContent,
    CheckoutPageSummaryBar,
    CheckoutPageGradient,
    CheckoutPageContentCell,
    CheckoutPageBackButton,
};

const CheckoutGrid = styled(Box)`
    /* 4 cols */
    grid-template-columns: repeat(4, 1fr);
    --gutter: calc((100vw - var(--wrapperPadding) * 2) * ${getPercentageForColumns(4, 64, 20).gutter});
    display: grid;
    column-gap: var(--gutter);
    width: 100%;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        /* 6 cols */
        grid-template-columns: repeat(6, 1fr);
        --gutter: calc((100vw - var(--wrapperPadding) * 2) * ${getPercentageForColumns(6, 72, 24).gutter});
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        /* 10 cols */
        grid-template-columns: repeat(10, 1fr);
        --gutter: calc((100vw - var(--wrapperPadding) * 2) * ${getPercentageForColumns(10, 80, 28).gutter});
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        grid-template-columns: repeat(12, 1fr);
        --gutter: 3.2rem;
    }
`;
