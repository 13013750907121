import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { CART_KEY, IS_GUEST, VISITOR_PARTY } from '../constants/storage';
import getStorageWithExpiry from '../utils/getStorageWithExpiry';

const handleRouteChange = () => {
    window.dataLayer = window.dataLayer ?? [];
    const storage = getStorageWithExpiry('local');
    const isLoggedin = !!storage?.getItem(CART_KEY);
    const isGuest = !!storage?.getItem(IS_GUEST);
    // eslint-disable-next-line no-nested-ternary
    const visitorType = isLoggedin ? (isGuest ? 'guest' : 'known') : 'unknown';

    window.dataLayer.push({
        event: 'pageview',
        visitor_type: visitorType,
        visitor_party: storage?.getItem(VISITOR_PARTY) ?? 'unknown',
    });
};

const usePageView = () => {
    const { asPath } = useRouter();
    const previous = useRef<string | null>(null);

    useEffect(() => {
        if (asPath !== previous.current) {
            handleRouteChange();
        }
        previous.current = asPath;
    }, [asPath]);
};

export default usePageView;
