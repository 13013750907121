import { useRouter } from 'next/router';
import React, { ComponentProps, memo, useMemo } from 'react';
import { QueryParamProvider as ContextProvider } from 'use-query-params';

type QueryParamProviderProps = ComponentProps<typeof ContextProvider>;

export const QueryParamProviderComponent = (props: { children?: React.ReactNode }) => {
    const { children, ...rest } = props;
    const router = useRouter();
    const match = /[^?|#]+/.exec(router.asPath);
    const pathname = match ? match[0] : router.asPath;

    const location = useMemo(
        () =>
            process.browser
                ? window.location
                : ({
                      search: router.asPath.replace(/[^?]+/u, ''),
                  } as QueryParamProviderProps['location']),
        [router.asPath]
    );

    const history = useMemo(() => {
        const createStateHandler = (type: 'push' | 'replace') => (newLocation: QueryParamProviderProps['location']) =>
            router[type](
                {
                    pathname: router.pathname,
                    query: router.query,
                    search: newLocation?.search,
                    hash: newLocation?.hash,
                },
                { pathname, search: newLocation?.search, hash: newLocation?.hash },
                {
                    shallow: true,
                    scroll: false,
                }
            );
        return {
            push: createStateHandler('push'),
            replace: createStateHandler('replace'),
            location,
        };
    }, [location, pathname, router]);

    return (
        <ContextProvider {...rest} history={history} location={location}>
            {children}
        </ContextProvider>
    );
};

export const QueryParamProvider = memo(QueryParamProviderComponent);
