import { Flex } from '@rebass/grid';
import Link from 'next/link';
import React, { FC, memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { useCheckout } from '../context/checkout';
import { CheckoutPageRoute, getSuffixForRoute, useCheckoutPageMeta, useCheckoutPages } from '../hooks/useCheckoutPages';
import WizardStep, { WizardStepProps, WizardStepVariant } from './designsystem/WizardStep';

interface Props {
    paymentType?: CheckoutPageRoute;
    loading?: boolean;
    onlyTicketsInCart?: boolean;
}

const CheckoutSteps: FC<React.PropsWithChildren<Props>> = ({ paymentType, loading, onlyTicketsInCart }) => {
    const pages = useCheckoutPages(paymentType, onlyTicketsInCart);
    return (
        <Container width={1}>
            {pages.map(({ route, title }, i) => (
                <CheckoutStep key={route} step={i + 1} route={route}>
                    {loading && <Skeleton height="2rem" width="6rem" />}
                    {!loading && title}
                </CheckoutStep>
            ))}
        </Container>
    );
};

export default memo(CheckoutSteps);

const useVariant = (
    pageRoute: CheckoutPageRoute
): { variant: WizardStepVariant; navigationAllowed?: boolean; valid?: boolean } => {
    const [state] = useCheckout();
    const pageState = state.pages[pageRoute];
    const pages = useCheckoutPages();
    const { activeStep } = useCheckoutPageMeta();
    const pageStep = pages.findIndex(({ route }) => route === pageRoute);
    const prevPageState = state.pages[pages[pageStep - 1]?.route];

    if (activeStep === pageStep) {
        return { variant: 'active', valid: prevPageState?.valid && pageStep !== pages.length - 1 };
    }

    if (pageState && !pageState.valid) {
        return { variant: 'todo', navigationAllowed: pageState.visited };
    }

    if (activeStep === pages.length - 1) {
        return { variant: 'disabled' };
    }

    if (pageStep < activeStep || pageState?.valid) {
        return { variant: 'done', navigationAllowed: true, valid: prevPageState?.valid };
    }
    return { variant: 'todo' };
};

interface CheckoutStepProps extends WizardStepProps {
    route: CheckoutPageRoute;
}

const CheckoutStep = ({ route, ...rest }: CheckoutStepProps) => {
    const { variant, navigationAllowed, valid } = useVariant(route);
    const step = <WizardStep {...rest} variant={variant} valid={valid} />;

    const suffix = getSuffixForRoute(route);

    return navigationAllowed ? (
        <Link href={`/${suffix}`} as={`/${suffix}`} legacyBehavior>
            {step}
        </Link>
    ) : (
        step
    );
};

const Container = styled(Flex)`
    margin-bottom: 7.2rem;
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: calc(100% + (var(--wrapperPadding) * 2));
        margin-left: calc(var(--wrapperPadding) * -1);
        margin-bottom: ${({ theme }) => theme.spacing['70_XLarge']};
    }

    > * {
        width: 25%;
    }
`;
