import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import sentry from '../utils/sentry';
import ErrorFallback from './ErrorFallback';

const { captureException } = sentry();

const ErrorBoundary: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const router = useRouter();
    return (
        <ReactErrorBoundary resetKeys={[router.pathname]} onError={captureException} FallbackComponent={ErrorFallback}>
            {children}
        </ReactErrorBoundary>
    );
};

export default ErrorBoundary;
