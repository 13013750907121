import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import React, { createContext, FC, useCallback, useContext, useReducer } from 'react';
import { CheckoutPageRoute } from '../hooks/useCheckoutPages';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

interface PageState {
    valid: boolean;
    visited: boolean;
}

export const standardCheckout: CheckoutPageRoute[] = [
    'CheckoutExtras',
    'CheckoutDetails',
    'CheckoutPayment',
    'CheckoutConfirm',
];

export const twoStepCheckout: CheckoutPageRoute[] = ['CheckoutPayment', 'CheckoutConfirm'];
export const threeStepCheckout: CheckoutPageRoute[] = ['CheckoutDetails', 'CheckoutPayment', 'CheckoutConfirm'];

export type CheckoutVariant = 'standard' | 'order' | 'email' | 'ticket';

export const variantMap: Record<CheckoutVariant, CheckoutPageRoute[]> = {
    standard: standardCheckout,
    order: twoStepCheckout,
    email: twoStepCheckout,
    ticket: threeStepCheckout,
};

export interface CheckoutPageState {
    pages: {
        [route: string]: PageState | undefined;
    };
    variant: keyof typeof variantMap;
}

interface SetAction {
    type: 'set';
    state: Partial<CheckoutPageState>;
}

interface InitAction {
    type: 'init';
    variant: keyof typeof variantMap;
}
interface ResetAction {
    type: 'reset';
}

interface SetPageVisitedAction {
    type: 'setPageVisited';
    route: string;
}

interface SetPageAction {
    type: 'setPage';
    route: string;
    value: boolean;
}

type CheckoutAction = ResetAction | SetPageAction | SetAction | SetPageVisitedAction | InitAction;

const CheckoutStateContext = createContext<CheckoutPageState | undefined>(undefined);
const CheckoutDispatchContext = createContext<((action: CheckoutAction) => void) | undefined>(undefined);

const initialState: CheckoutPageState = {
    variant: 'standard',
    pages: {},
};

function reducer(state: CheckoutPageState, action: CheckoutAction): CheckoutPageState {
    switch (action.type) {
        case 'set':
            return { ...state, ...action.state };
        case 'reset':
            return initialState;
        case 'setPage': {
            const { route, value } = action;
            return { ...state, pages: { [route]: { valid: value, visited: true } } };
        }
        case 'setPageVisited': {
            const { route } = action;
            return { ...state, pages: { [route]: { valid: state.pages[route]?.valid ?? false, visited: true } } };
        }
        case 'init':
            return { ...initialState, variant: action.variant };
        default:
            throw new UnreachableCaseError(action);
    }
}

const getVariant = (query: ParsedUrlQuery): keyof typeof variantMap => {
    if (query.id) {
        return 'order';
    }
    if (query.cartId) {
        return 'email';
    }

    return 'standard';
};

const CheckoutProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const router = useRouter();
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        variant: getVariant(router.query),
    });

    return (
        <CheckoutStateContext.Provider value={state}>
            <CheckoutDispatchContext.Provider value={dispatch}>{children}</CheckoutDispatchContext.Provider>
        </CheckoutStateContext.Provider>
    );
};

const useCheckoutDispatch = () => {
    const dispatch = useContext(CheckoutDispatchContext);
    if (!dispatch) {
        throw new Error('useCheckoutDispatch must be used within a CheckoutProvider');
    }

    const setPageValidity = useCallback(
        (route: string, value: boolean) => dispatch({ value, route, type: 'setPage' }),
        [dispatch]
    );
    const reset = useCallback(() => dispatch({ type: 'reset' }), [dispatch]);
    const set = useCallback((state: Partial<CheckoutPageState>) => dispatch({ type: 'set', state }), [dispatch]);
    const setPageVisited = useCallback((route: string) => dispatch({ route, type: 'setPageVisited' }), [dispatch]);
    const init = useCallback((variant: keyof typeof variantMap) => dispatch({ type: 'init', variant }), [dispatch]);

    return { setPageValidity, reset, set, setPageVisited, init };
};

const useCheckoutState = () => {
    const context = useContext(CheckoutStateContext);
    if (!context) {
        throw new Error('useCheckoutState must be used within a CheckoutProvider');
    }
    return context;
};

const useCheckout = () =>
    // maybe seperate these in two exports, but may be a little verbose
    [useCheckoutState(), useCheckoutDispatch()] as const;
export { useCheckout, CheckoutProvider, useCheckoutState, useCheckoutDispatch };
