import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { useThemeContext } from '@oberoninternal/travelbase-ds/hooks/useThemeContext';
import { Box, Flex } from '@rebass/grid';
import Image from 'next/image';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useTenantContext } from '../context/TenantContext';

const defaultPassportTitle = 'Frysk Paspoart';
const defaultPassportMessage =
    'Een vakantie in Friesland laat je hart sneller kloppen. Na een bezoek aan Friesland krijgt onze provincie voor altijd een bijzonder plekje in je hart. Om jezelf ook een heel klein beetje Fries te voelen, krijg je bij je boeking een écht Frysk Paspoart (Fries Paspoort). Dit paspoort staat vol met weetjes en informatie over onze cultuur en taal. {br}{br} Het Frysk Paspoart haal je tijdens je verblijf in Friesland op in één van onze geselecteerde VVV winkels (zie boekingsbevestiging). Met dit paspoort heb je recht op 10% korting op onze VVV informatie- en inspiratie producten.';
const deTochtTitle = 'De Tocht overnachtingsarrangement';
const deTochtMessage = `Onze accommodaties doen voor jou graag extra hun best om je gastvrij in Friesland te ontvangen. Daarom hebben zij speciaal voor De Tocht bijzondere arrangementen samengesteld. De inhoud van dit arrangement vind je op deze pagina onder het kopje ‘bijzonder aan deze accommodatie’.{br}{br}{bewareText}`;
const showCustomPassportContent = process.env.NEXT_PUBLIC_BRAND === 'detocht';

const Passport: FC<React.PropsWithChildren<unknown>> = () => {
    const {
        brandConfig: { passport },
    } = useTenantContext();
    const intl = useIntl();
    const { mediaQueries } = useThemeContext();

    const formattedMessage = {
        title: showCustomPassportContent ? (
            <FormattedMessage defaultMessage={deTochtTitle} />
        ) : (
            <FormattedMessage defaultMessage={defaultPassportTitle} />
        ),
        message: showCustomPassportContent ? (
            <FormattedMessage
                values={{
                    br: <br />,
                    bewareText: (
                        <b>
                            LET OP! Iedereen die boekt via onze site en de VVV krijgt gratis een unieke backstage
                            rondleiding aangeboden t.w.v. € 12,50 p.p. (de rondleiding is voor alle gasten die onder de
                            boeking vallen). Na je reservering ontvang je van VVV Waterland van Friesland een email
                            waarmee je de backstage rondleiding kan reserveren.
                        </b>
                    ),
                }}
                defaultMessage={deTochtMessage}
            />
        ) : (
            <FormattedMessage values={{ br: <br /> }} defaultMessage={defaultPassportMessage} />
        ),
    };

    if (!passport) {
        return null;
    }

    const { imgSrc } = passport;

    return (
        <Container
            my={[6, 8]}
            pt={[6, 8]}
            flexDirection={['column', null, 'row']}
            justifyContent="space-around"
            alignItems="center"
            className="passport-container"
        >
            <StyledBoxImage width={[1, 1, 1, 1 / 2]} mb={[0, 0, 6, 0]} className="passport-image-container">
                {showCustomPassportContent ? (
                    <CustomImage
                        src={imgSrc}
                        alt={deTochtTitle}
                        layout="fill"
                        sizes={`(min-width: ${mediaQueries.m}) 50vw, 100vw`}
                        style={{ objectFit: 'contain' }}
                    />
                ) : (
                    <Image
                        src={imgSrc}
                        alt={intl.formatMessage({ defaultMessage: 'Frysk Paspoart' })}
                        layout="fill"
                        sizes={`(min-width: ${mediaQueries.m}) 50vw, 100vw`}
                    />
                )}
            </StyledBoxImage>
            <Box mt={[5, null, 0]} width={[1 / 1, 1 / 1, 1 / 1, 1 / 2]} ml={[0, 0, 0, 7]}>
                <Title variant="large">{formattedMessage.title}</Title>
                <StyledBox mt={[2, null, 5]}>
                    <Body>{formattedMessage.message}</Body>
                </StyledBox>
            </Box>
        </Container>
    );
};

export default Passport;

const Container = styled(Flex)`
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        flex-direction: row;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        align-items: center;
    }
`;
const StyledBox = styled(Box)`
    p {
        margin-bottom: 0.8rem;
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
            margin-bottom: 1.6rem;
        }
    }
`;

const CustomImage = styled(Image)`
    object-fit: contain;
    background: ${({ theme }) => theme.colors.neutral['0']};
`;

const StyledBoxImage = styled(Box)`
    position: relative;
    overflow: hidden;
    aspect-ratio: 4 / 3;
    background-color: ${({ theme }) => theme.colors.primary['20']};
`;

export const StyledContentWrapper = styled.div`
    padding: 0 var(--wrapperPadding);
    max-width: calc(140.8rem + var(--wrapperPadding) * 2);
    margin: 0 auto ${({ theme }) => theme.spacing['60_Large']};

    border-top: 1px solid ${({ theme }) => theme.colors.neutral['20']};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['20']};
    padding-top: ${({ theme }) => theme.spacing['60_Large']};
    padding-bottom: ${({ theme }) => theme.spacing['60_Large']};
`;
