import { useRandom } from '@oberoninternal/travelbase-ds/hooks/useRandom';
import React, { FC, memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import Heading from './designsystem/Heading';
import Stack, { StackProps } from './Stack';

export const SkeletonField: FC<React.PropsWithChildren<{ skeletonHeight?: Range }>> = ({ skeletonHeight }) => {
    const elementWidth = useRandom(50, 100);
    const renderHeading = useRandom(1, 4) === 1;
    const elementHeight = useRandom(skeletonHeight?.min ?? 0, skeletonHeight?.max ?? 0);
    return (
        <>
            {renderHeading && (
                <Heading>
                    <Skeleton height="2rem" />
                </Heading>
            )}
            <Skeleton width={`${Math.round(elementWidth)}%`} height={elementHeight !== 0 ? elementHeight : undefined} />
        </>
    );
};

type Range = { min: number; max: number };
interface Props extends StackProps {
    range?: Range;
    spacing?: number;
    skeletonHeight?: Range;
}

const rangeConfig: Range = {
    min: 5,
    max: 8,
};
const Loading: FC<React.PropsWithChildren<Props>> = ({
    range = rangeConfig,
    spacing = 5,
    skeletonHeight,
    ...props
}) => {
    const random = useRandom(range.min, range.max);
    return (
        <Stack spacing={spacing} width={1} {...props}>
            {new Array(random).fill(null).map((_, i) => (
                <SkeletonField key={i} skeletonHeight={skeletonHeight} />
            ))}
        </Stack>
    );
};

export default memo(Loading);
