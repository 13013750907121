import React from 'react';
import { SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';
import { BrandConfig } from './brandConfig';
import { rentalUnitTypes } from './filterTypes';
import detochtTheme from '../themes/detocht';
import Heading from '../components/detocht/Heading';
import Passport, { StyledContentWrapper } from '../components/Passport';
import { FormattedMessage } from 'react-intl';
import { uspIcons } from './UspIcons';

const usps = [
    {
        title: <FormattedMessage values={uspIcons} defaultMessage="{tag} Geen reserveringskosten" />,
        description: (
            <FormattedMessage defaultMessage="Wij rekenen geen reserverings-, of andere verborgen kosten voor het boeken van je accommodatie. Zo boek je lokaal tegen de beste prijs!" />
        ),
    },
    {
        title: <FormattedMessage values={uspIcons} defaultMessage="{euro} Beste prijs" />,
        description: (
            <FormattedMessage defaultMessage="Geen verborgen kosten. Direct de beste prijs voor jouw vakantie in onze regio!" />
        ),
    },
    {
        title: <FormattedMessage values={uspIcons} defaultMessage="{arrangement} Speciaal De Tocht arrangement" />,
        description: (
            <FormattedMessage defaultMessage="Boek via ons je unieke De Tocht overnachtingsarrangement bij een door ons geselecteerde accommodatie. Zo beleef je optimaal de magie van musical De Tocht, voor, tijdens én na de voorstelling. Klik op de accommodaties om de arrangementen te bekijken en boeken." />
        ),
    },
    {
        title: <FormattedMessage values={uspIcons} defaultMessage="{microphone} Gratis backstage rondleiding" />,
        description: (
            <FormattedMessage defaultMessage="Boek je via ons dan krijg na afloop van de voorstelling een uniek kijkje achter de schermen van het indrukwekkende decor van Musical De Tocht. Boek je via ons je accommodatie dan krijg je deze rondleiding t.w.v. € 12,50 p.p. gratis!" />
        ),
    },
    {
        title: <FormattedMessage values={uspIcons} defaultMessage="{shield} VVV zekerheid" />,
        description: (
            <FormattedMessage defaultMessage="Boeken via de VVV betekent geen onaangename verrassingen, maar veilig en zeker je vakantie regelen." />
        ),
    },
];

const deTochtConfig: BrandConfig = {
    name: 'detocht',
    locales: ['nl', 'de', 'en'],
    forceAffiliate: 'detocht',
    theme: detochtTheme,
    gtmID: '',
    mapZoom: 10,
    // Map min zoom level
    mapMinZoom: {
        desktop: 10,
        mobile: 8.5,
    },

    font: {
        hrefs: ['/static/font/Proximanova-Regular.woff', '/static/font/Proximanova-Black.woff'],
        css: `
        @font-face {
            font-family: 'Proximanova';
            src: url('/static/font/Proximanova-Regular.woff') format('woff'),
                url('/static/font/Proximanova-Regular.woff2') format('woff2');
            font-weight: 400;
            font-display: swap;
        }

        @font-face {
            font-family: 'Proximanova';
            src: url('/static/font/Proximanova-Black.woff') format('woff'),
             url('/static/font/Proximanova-Black.woff2') format('woff2');
            font-weight: 900;
            font-display: swap;
        }
    `,
    },
    globalStyling: `
        @media screen and (min-width: ${detochtTheme.mediaQueries.s}) {
            .tips-image-container {
                aspect-ratio: unset !important;
                height: 100%;
            }
        }
        .accordion-item-innerdiv {
            margin-top: 0 !important;
        }
        .search-usps button {
            align-items: center;
        }
        .ups-content-container .passport-container {
            display: none !important;
        }
        .passport-image-container {
            max-width: 400px;
            max-height: 370px;
            margin: auto;
        }
    `,
    contact: {},
    customContent: {
        Navigation: () => <Heading />,
        Footer: () => <div style={{ height: '5vh' }} />,
        UnitInfo: () => (
            <StyledContentWrapper>
                <Passport />
            </StyledContentWrapper>
        ),
    },
    placeFilter: {
        hasPriority: true,
        type: 'AND',
    },
    accommodationTypes: [
        {
            message: 'all',
            value: 'all',
        },
        {
            message: 'vacationHomes',
            value: rentalUnitTypes.HOME,
        },
        {
            message: 'caravan',
            value: rentalUnitTypes.CAMPER_SPOT,
        },
        {
            message: 'hotelOrBnB',
            value: rentalUnitTypes.ROOM,
        },
        {
            message: 'groupAccommodations',
            value: rentalUnitTypes.BED,
        },
        {
            message: 'camping',
            value: `${rentalUnitTypes.CAMPING_PITCH} ${rentalUnitTypes.CAMPER_SPOT} ${rentalUnitTypes.TENT}`,
        },
        {
            message: 'boat',
            value: `${rentalUnitTypes.BOAT}`,
        },
    ],
    groupTypes: ['adults', 'youth', 'children', 'babies', 'pets'],
    unitSearchOrder: {
        popularity: SearchRentalUnitsOrderEnum.RandomBoosted,
        ratingDesc: SearchRentalUnitsOrderEnum.ReviewRatingDesc,
    },
    tripsSearchOrder: {
        popularity: UnitSearchOrderEnum.RandomBoosted,
        ratingDesc: UnitSearchOrderEnum.ReviewRatingDesc,
        priceAsc: UnitSearchOrderEnum.PriceAsc,
        priceDesc: UnitSearchOrderEnum.PriceDesc,
    },

    coordinates: {
        latitude: 53.228483528828384,
        longitude: 5.52337487502224,
    },
    showUsps: true,
    usps,
    passport: {
        imgSrc: '/static/img/detocht_passport.jpg',
    },
    bakedInFilterProperty: '_channel.de-tocht',
    checkout: {
        checkoutShouldRedirect: true,
    },
    locationDescription: {
        imgSrc: '/static/img/detocht_tips.jpg',
        imgAlt: {
            nl: 'Dit is Waterland van Friesland',
            en: 'This is Waterland van Friesland',
            de: 'Das ist Waterland van Friesland',
        },
        description: {
            nl: 'Wij houden van Friesland en kennen de bijzondere plekjes van Friesland. Hieronder hebben we er enkele beschreven die je bezoek nog leuker maken.',
            en: 'We love Friesland and know its special places. Below, we have described some that will make your visit even more enjoyable.',
            de: 'Wir lieben Friesland und kennen seine besonderen Orte. Im Folgenden haben wir einige beschrieben, die Ihren Besuch noch angenehmer machen werden.',
        },
        tips: {
            nl: [
                'Bezoek onze beroemde <a href="https://www.friesland.nl/nl/ontdek/de-elf-steden?utm_source=detocht&utm_medium=brandedcontent&utm_campaign=verhaallijn&utm_term=elfsteden"target="_blank">elf steden</a> steden. Het liefst allemaal! Stuk voor stuk pareltjes en stuk voor stuk schatkamers, die verlangen naar jouw blikken.',
                'Boek een fluisterboot of rondvaart in een nationaalpark, lunch aan een unieke oever, wandel langs een van de tientallen meren. In Friesland kun je niet om <a href="https://www.friesland.nl/nl/ontdek/waterland?utm_source=detocht&utm_medium=brandedcontent&utm_campaign=verhaallijn&utm_term=water"target="_blank">water</a> heen.',
                'Wat lopen? Ga wadlopen! Hop van het ene naar het volgende <a href="https://www.friesland.nl/nl/ontdek/de-wadden?utm_source=detocht&utm_medium=brandedcontent&utm_campaign=verhaallijn&utm_term=wadden"target="_blank">Wadden</a>eiland. Daar waar het ’s nachts nog echt dónker is. Snuif je hoofd leeg aan de zilte zeelucht.',
                'Dé Friese <a href="https://www.friesland.nl/nl/ontdek/cultuur?utm_source=detocht&utm_medium=brandedcontent&utm_campaign=verhaallijn&utm_term=cultuur"target="_blank">cultuur</a>. Zij bestaat écht. Diepgeworteld in mens en natuur, door tradities, de verhouding met water, het Frysk! Beleef haar in de talrijke musea, tijdens festivals en op podia.',
                'Oer-Hollandse tegenwind? Niet in de Friese <a href="https://www.friesland.nl/nl/ontdek/de-friese-wouden?utm_source=detocht&utm_medium=brandedcontent&utm_campaign=verhaallijn&utm_term=wouden"target="_blank">Wouden</a> met zijn bossen en zijn met bosschages omgeven landerijen. Rol je kleedje uit, verorber je brood en de natuur ontvouwt zich voor je neus.',
            ],
            en: [
                'Visit our famous eleven cities. Preferably all of them! Each and every one of them gems and treasure troves, longing for your glances.',
                "Book a whisper boat or round trip in a national park, have lunch on a unique shore, walk along one of the dozens of lakes. You can't beat water in Friesland.",
                'Walk what? Go mudflat walking! Hop from one Wadden Island to the next. Where it is still really dark at night. Clear your head in the salty sea air.',
                'The Frisian culture. It really exists. Deeply rooted in people and nature, traditions, the relationship with water, the Frysian language! Experience it in the numerous museums, during festivals and on stages.',
                'Primitive Dutch headwinds? Not in the Frisian Woods, with its forests and its wooded lands. Roll out your rug, consume your bread and nature unfolds before your eyes.',
            ],
            de: [
                'Besuchen Sie unsere berühmten elf Städte. Am besten alle! Jede einzelne ist eine Perle und jede einzelne eine Schatzkammer, die sich nach Ihren Blicken sehnt.',
                'Buchen Sie ein Flüsterboot oder eine Rundfahrt in einem Nationalpark, essen Sie an einer einzigartigen Küste zu Mittag, wandern Sie an einem der Dutzenden von Seen entlang. Dem Wasser kann man in Friesland nicht ausweichen.',
                'Was wandern? Eine Wattwanderung! Hüpfen Sie von einer Watteninsel zur nächsten. Wo es nachts noch richtig dunkel ist. Den Kopf frei bekommen von der salzigen Seeluft.',
                'Die friesische Kultur. Es gibt sie wirklich. Tief verwurzelt in Mensch und Natur, in Traditionen, in der Beziehung zum Wasser, in der friesischen Sprache! Erleben Sie sie in den zahlreichen Museen, auf Festivals und auf Bühnen.',
                'Primitive niederländische Gegenwinde? Nicht im Friesischen Wald, mit seinen Wäldern und seinen bewaldeten Flächen. Rollen Sie Ihren Teppich aus, verzehren Sie Ihr Brot und die Natur entfaltet sich vor Ihnen.',
            ],
        },
    },
    complaintFormUrl: {
        nl: 'mailto:info@musicaldetocht.frl',
        en: 'mailto:info@musicaldetocht.frl',
        de: 'mailto:info@musicaldetocht.frl',
    },

    seo: {
        siteName: 'De Tocht',
    },
    minBookingAge: 18,
    cancellationInsuranceDefault: true,
};

export default deTochtConfig;
