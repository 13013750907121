import styled from 'styled-components';
import { space, SpaceProps, LayoutProps, layout } from 'styled-system';
import React, { HTMLAttributes } from 'react';

export type DividerProps = SpaceProps & LayoutProps & HTMLAttributes<HTMLHRElement>;

const Divider = styled.hr<DividerProps>`
    width: 100%;
    height: 1px;
    border: none;
    background-color: ${({ theme }) => theme.colors.neutral['10']};
    ${space}
    ${layout}
`;

const SectionDivider = styled(Divider)`
    max-width: 140.8rem;
    width: calc(100% - var(--wrapperPadding) * 2);
    margin: 0 auto;
`;

const CheckoutDivider = (props: SpaceProps) => <Divider my={[6, null, 8]} {...props} />;

export default Divider;

export { SectionDivider, CheckoutDivider };
