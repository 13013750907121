import { Box } from '@rebass/grid';
import styled from 'styled-components';

export const getPercentageForColumns = (cols: number, colWidth: number, gutterWidth: number) => {
    const w = colWidth * cols + gutterWidth * (cols - 1);
    return {
        column: colWidth / w,
        gutter: gutterWidth / w,
    };
};

const Grid = styled(Box)`
    /* 4 cols */
    --column: calc((100vw - var(--wrapperPadding) * 2) * ${getPercentageForColumns(4, 64, 20).column});
    --gutter: calc((100vw - var(--wrapperPadding) * 2) * ${getPercentageForColumns(4, 64, 20).gutter});
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--column));
    column-gap: var(--gutter);
    width: 100%;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        /* 6 cols */
        --column: calc((100vw - var(--wrapperPadding) * 2) * ${getPercentageForColumns(6, 72, 24).column});
        --gutter: calc((100vw - var(--wrapperPadding) * 2) * ${getPercentageForColumns(6, 72, 24).gutter});
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        /* 10 cols */
        --column: calc((100vw - var(--wrapperPadding) * 2) * ${getPercentageForColumns(10, 80, 28).column});
        --gutter: calc((100vw - var(--wrapperPadding) * 2) * ${getPercentageForColumns(10, 80, 28).gutter});
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        --column: 8.8rem;
        --gutter: 3.2rem;
    }
`;

export default Grid;
