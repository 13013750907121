import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Locale } from '../entities/Locale';

interface Props {
    phoneNumber: string;
}

export const formatPhoneNumber = (phoneNumber: string, locale: Locale, includeZero = true) => {
    const countryCodeRegexp = /([+]31)0?/;
    const dutchPhoneNumber = phoneNumber.startsWith('+31')
        ? phoneNumber.replace(countryCodeRegexp, '+31 (0)')
        : `+31 (0) ${phoneNumber.startsWith('0') ? phoneNumber.substring(1, phoneNumber.length) : phoneNumber}`;

    if (locale !== 'nl') {
        const formattedPhoneNumber = dutchPhoneNumber.replace('+31 (0)', '0031');
        return formattedPhoneNumber;
    }
    if (!includeZero) {
        // hrefs (tel:) shoudn't include the 0.
        return dutchPhoneNumber.replace('+31 (0)', '+31');
    }
    return dutchPhoneNumber;
};

const TranslatedPhoneNumber: FC<React.PropsWithChildren<Props>> = ({ phoneNumber }) => {
    const { locale } = useIntl();

    return <>{formatPhoneNumber(phoneNumber, locale as Locale)}</>;
};

export default TranslatedPhoneNumber;
