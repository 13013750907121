import defaultTheme from '@oberoninternal/travelbase-ds/themes/default';
import websiteTheme from './website';
import { FontStyle, Theme, Colors } from '@oberoninternal/travelbase-ds/constants/theme';
import { BodyVariant } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';

/**
 * The Waterland Voorkant theme.
 * The Waterland theme for the booking site.
 */

const primary = {
    0: '#ffeff7',
    5: '#ffeff7',
    10: '#ffeff7',
    20: '#ff8eaf',
    30: '#e82329',
    40: '#e82329',
    50: '#e82329',
    60: '#e82329',
    70: '#e82329',
    80: '#A30D22',
    90: '#A30D22',
    100: '#A30D22',
};

const colors: Colors = {
    primary,
    tertiary: primary,
    secondary: {
        0: '#eff6ff',
        5: '#d0e4fb',
        10: '#b2d3f8',
        20: '#95c3f3',
        30: '#78b4ee',
        40: '#5ba5e7',
        50: '#4097de',
        60: '#2587d1',
        70: '#0d72b9',
        80: '#114c93',
        90: '#0f285e',
        100: '#0a1233',
    },
    neutral: defaultTheme.colors.neutral,
    positive: defaultTheme.colors.positive,
    warning: defaultTheme.colors.warning,
    negative: defaultTheme.colors.negative,
};

const fontFamily: Theme['fontFamily'] = {
    body: 'Brown,Arial,sans-serif',
    title: 'Brown,Arial,sans-serif',
};

const baseTitle: Partial<FontStyle> = {
    color: colors.secondary[100],
};

const titleStyles: Record<TitleVariant, FontStyle> = {
    large: {
        size: { min: 24, max: 32 },
        line: { min: 24, max: 32 },
        fontFamily: 'Brown',
        weight: 700,
        letterSpacing: 0,
        ...baseTitle,
    },
    regular: {
        size: { min: 16, max: 18 },
        line: { min: 20, max: 24 },
        fontFamily: 'Brown',
        weight: 700,
        letterSpacing: 0,
        ...baseTitle,
    },
    small: {
        size: { min: 16, max: 18 },
        line: { min: 20, max: 24 },
        fontFamily: 'Brown',
        weight: 400,
        ...baseTitle,
    },
    tiny: {
        size: { min: 15, max: 16 },
        line: { min: 20, max: 20 },
        fontFamily: 'Brown',
        weight: 700,
        ...baseTitle,
    },
    micro: {
        size: { min: 14, max: 15 },
        line: { min: 15, max: 20 },
        fontFamily: 'Brown',
        weight: 700,
        ...baseTitle,
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 14, max: 18 },
        line: { min: 24, max: 28 },
        weight: 400,
    },
    large: {
        size: { min: 16, max: 20 },
        line: { min: 24, max: 28 },
        weight: 400,
    },
    regular: {
        size: { min: 14, max: 18 },
        line: { min: 24, max: 28 },
        weight: 400,
    },
    small: {
        size: { min: 13, max: 14 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 400,
    },
    tiny: {
        size: { min: 12, max: 13 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 400,
    },
    micro: {
        size: { min: 12, max: 12 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 400,
    },
};

const waterland: Theme = {
    ...websiteTheme,
    name: 'Waterland',
    fontFamily,
    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    colors,
    colorAssignments: {
        main: ['primary', 60],
        buttonPrimary: ['primary', 60],
        buttonLight: ['secondary', 70],
        textAction: ['primary', 60],
        input: ['primary', 60],
        form: ['primary', 60],
        rating: ['secondary', 70],
        booking: ['primary', 60],
        calendar: {
            defaultBackgroundColor: ['primary', 0],
            defaultColor: ['neutral', 100],
            bookable: {
                color: ['neutral', 100],
                backgroundColor: '#EBF9F4',
                focusColor: '#1F7457',
            },
            selected: {
                backgroundColor: '#33C191',
                color: ['neutral', 0],
                focusColor: '#33C191',
            },
            bookableOutside: ['neutral', 100],
            bookableWithin: {
                selected: ['neutral', 100],
                notSelected: ['neutral', 0],
            },
            periodBackground: {
                selected: '#EBF9F4',
                notSelected: '#33C191',
            },
            withinCss: {
                selected: '#33C191',
                notSelected: ['neutral', 0],
            },
            price: '#1F7457',
        },
    },
    radius: {
        button: '0',
        textArea: '0',
        textInput: '0',
        dropdown: '0.5rem',
    },
};

export default waterland;
