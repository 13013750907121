import { defineMessages } from 'react-intl';

// Put here messages that are used all over the place
const messages = defineMessages({
    formServerError: {
        defaultMessage: 'Er is iets misgegaan',
    },
    formError: {
        defaultMessage: 'Nog niet alle velden zijn correct ingevuld.',
    },
    fieldError: {
        defaultMessage: 'Dit veld is verplicht',
    },
    minimumAgeError: {
        defaultMessage: 'Je moet minimaal {age} jaar oud zijn om te kunnen boeken',
    },
    emailError: {
        defaultMessage: 'Vul een geldige e-mail in',
    },
    countryCodeError: {
        defaultMessage: 'Vul een land in',
    },
    phoneNumberError: {
        defaultMessage: 'Vul een geldig telefoonnummer in',
    },
    agreedToTermsError: {
        defaultMessage: 'Om door te gaan moet u akkoord gaan met de Algemene voorwaarden',
    },
    ibanError: {
        defaultMessage: 'Vul een geldig rekeningnummer (IBAN) in',
    },
    guests: {
        defaultMessage: `{amountGuests, plural,
                =0 {}
                one {# gast}
                other {# gasten}
                }`,
    },
    adults: {
        defaultMessage: `{amountAdults, plural,
                =0 {}
                one {# volwassene}
                other {# volwassenen}
                }`,
    },
    pets: {
        defaultMessage: `{amountPets, plural,
            =0 {0 huisdieren}
            one {# huisdier}
            other {# huisdieren}
            }`,
    },
    children: {
        defaultMessage: `{amountChildren, plural,
            =0 {}
            one {# kind}
            other {# kinderen}
            }`,
    },
    babies: {
        defaultMessage: `{amountBabies, plural,
            =0 {}
            one {# baby}
            other {# baby's}
            }`,
    },
    bedrooms: {
        defaultMessage: `{amountBedrooms, plural,
            =0 {}
            one {# slaapkamer}
            other {# slaapkamers}
            }`,
    },
    persons: {
        defaultMessage: `{amountPersons, plural,
            =0 {}
            one {# persoon}
            other {# personen}
            }`,
    },
    inOneBedroom: {
        defaultMessage: `in 1 slaapkamer`,
    },
    youth: {
        defaultMessage: `{amountYouths, plural,
            =0 {}
            one {# jongere}
            other {# jongeren}
            }`,
    },
    anonymous: {
        defaultMessage: 'Anoniem',
    },
    moreInfo: {
        defaultMessage: 'Meer informatie',
    },
    flexCancellation: {
        defaultMessage: 'Gratis annuleren',
    },
    flexCancellationUntil: {
        defaultMessage: 'Tot {flexCancellationDays} dagen voor aankomst',
    },
    flexCancellationDays: {
        defaultMessage:
            'Gratis annuleren kan bij deze accommodatie tot {latestCancellationDate}. Eventuele reserveringskosten en verzekeringspremie worden bij annulering altijd in rekening gebracht.',
    },

    buyTickets: {
        defaultMessage: 'Koop tickets',
    },
});

export default messages;
