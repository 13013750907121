/* eslint-disable jsx-a11y/alt-text */
import React, { ImgHTMLAttributes, SourceHTMLAttributes } from 'react';
import Image from 'next/image';
import styled, { css } from 'styled-components';
import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import { Box } from '@rebass/grid';

type SourceProps = SourceHTMLAttributes<HTMLSourceElement>;
export interface BaseImgProps {
    /**  Preferably some kind of low quality, low size image of the same kind as the src image */
    placeholder?: string;
    fallback: ImgHTMLAttributes<HTMLImageElement>;
    sources?: SourceProps[];
    loading?: 'eager' | 'lazy';
    className?: string;
    alt?: string;
    width?: number | `${number}`;
    height?: number | `${number}`;
    onLoad?: () => void;
}

export interface FillImgProps extends BaseImgProps {
    layout: 'fill';
}

export interface RatioImgProps extends BaseImgProps {
    ratio: number;
    layout: 'ratio';
}

export interface DefaultImgProps extends BaseImgProps {
    layout?: undefined;
    width: number | `${number}`;
    height: number | `${number}`;
}

export type ImgProps = FillImgProps | RatioImgProps | DefaultImgProps;

/**
 * There are basically three ways to use the `Img` component.
 *
 * 1. Default, where width and height are required and an image with static dimensions will be displayed
 * 2. `layout: fill` will fill its parent container
 * 3. `layout: ratio` will size the image according to the ratio that's given as a prop
 */
function Img(props: ImgProps) {
    const { placeholder, fallback, sources, loading, width, height, alt, className, ...rest } = props;
    const srcSet = sources?.[0].srcSet?.split(', ').map(src => src.split(' '));

    return (
        <Container
            key={fallback.src}
            width={width as number}
            height={height as number}
            className={className ?? fallback.className ?? ''}
            ratio={rest.layout === 'ratio' ? rest.ratio : 0}
            layout={rest.layout}
        >
            <Image
                src={sources?.[0].src ?? fallback.src ?? ''}
                sizes={sources?.[0].sizes ?? ''}
                alt={alt ?? ''}
                width={width as number}
                height={height as number}
                fill
                loader={({ src, width: srcWidth }: { src: string; width: number | undefined }) => {
                    const foundSrc = srcSet?.reduce<{ src: string; width: number } | null>((prev, [s, w]) => {
                        const innerWidth = parseInt(w, 10);
                        if (!prev || Math.abs((srcWidth ?? 0) - innerWidth) < Math.abs((srcWidth ?? 0) - prev.width)) {
                            return { src: s, width: innerWidth };
                        }
                        return prev;
                    }, null)?.src;
                    return foundSrc ?? src;
                }}
                placeholder={placeholder ? 'blur' : 'empty'}
                blurDataURL={placeholder}
                loading={loading}
            />
        </Container>
    );
}

const parseDimension = (amount: number | string) => (typeof amount === 'number' ? `${amount}px` : amount);

interface ContainerProps {
    width: number | string;
    height: number | string;
    layout?: 'ratio' | 'fill';
    ratio?: number;
}

const Container = styled(Box)<ContainerProps>`
    width: ${({ width }) => parseDimension(width)};
    height: ${({ height }) => parseDimension(height)};
    overflow: hidden;

    ${({ layout, ratio = 0 }) => {
        if (!layout) {
            return undefined;
        }
        switch (layout) {
            case 'fill':
                return css`
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                `;
            case 'ratio':
                return css`
                    position: relative;
                    &:before {
                        display: block;
                        content: '';
                        width: 100%;
                        padding-top: ${Math.round(ratio * 100)}%;
                    }
                `;
            default:
                throw new UnreachableCaseError(layout);
        }
    }}

    img {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
`;

export default Img;
