import React, { FC } from 'react';
import { PriceLineFragment } from '../generated/graphql';
import { FormattedNumber } from 'react-intl';
import { Flex } from '@rebass/grid';
import styled from 'styled-components';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';

interface Props {
    priceLine: PriceLineFragment;
}

const SummaryBarItemPriceLine: FC<React.PropsWithChildren<Props>> = ({ priceLine }) => (
    <Flex key={priceLine.label} justifyContent="space-between">
        <StyledBody>{priceLine.label}</StyledBody>
        {Number(priceLine.quantity) > 1 && (
            <StyledBody>
                {priceLine.quantity}
                {' x'}
            </StyledBody>
        )}
        <StyledBody>
            <FormattedNumber value={priceLine.totalPrice} format="EUR" />
        </StyledBody>
    </Flex>
);

export default SummaryBarItemPriceLine;

const StyledBody = styled(Body).attrs({ variant: 'small' })`
    flex: 1;
    text-align: center;
    :first-of-type {
        text-align: start;
    }
    :last-of-type {
        text-align: end;
    }
`;
