import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    PossibleTypesMap,
} from '@apollo/client';
import merge from 'deepmerge';
import isEqual from 'lodash.isequal';
import nodeFetch from 'node-fetch';
// eslint-disable-next-line import/extensions
import introspectionResult from './generated/fragmentTypes.json';
import { ACCESS_TOKEN } from './utils/auth';
import getStorageWithExpiry from './utils/getStorageWithExpiry';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const authLink = new ApolloLink((operation, forward) => {
    // only add the access token on client-side
    if (typeof window !== 'undefined') {
        const token = getStorageWithExpiry('local')?.getItem(ACCESS_TOKEN);

        if (token) {
            operation.setContext((ctx: Record<string, unknown>) => ({
                ...(ctx ?? {}),
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }));
        }
    }

    return forward(operation);
});

type CreateApolloClient = (
    locale: string,
    initialState?: NormalizedCacheObject,
    fragmentTypes?: PossibleTypesMap,
    links?: ApolloLink[]
) => ApolloClient<NormalizedCacheObject>;

const createApolloClient: CreateApolloClient = (locale, initialState = {}, fragmentTypes, links = []) => {
    const ssrMode = typeof window === 'undefined';

    const cache = new InMemoryCache({
        possibleTypes: fragmentTypes ?? introspectionResult.possibleTypes,
        typePolicies: {
            Cart: {
                fields: {
                    order: {
                        merge(existing, incoming) {
                            return { ...existing, ...incoming };
                        },
                    },
                },
            },
        },
    }).restore(initialState);

    const fetch = ssrMode ? nodeFetch : window.fetch;

    return new ApolloClient({
        ssrMode,
        link: ApolloLink.from([
            authLink,
            ...links,
            new HttpLink({
                uri: process.env.NEXT_PUBLIC_API_URL,
                fetch: fetch as unknown as WindowOrWorkerGlobalScope['fetch'],
                headers: {
                    'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
                    'x-site-language': locale,
                },
            }),
        ]),
        cache,
    });
};

let globalApolloClient: ApolloClient<NormalizedCacheObject>;
/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 */
export const initApolloClient: CreateApolloClient = (...args) => {
    const [, initialState] = args;
    // Make sure to create a new client for every server-side request so that data
    // isn't shared between connections (which would be bad)
    if (typeof window === 'undefined') {
        return createApolloClient(...args);
    }

    // Reuse client on the client-side
    if (!globalApolloClient) {
        globalApolloClient = createApolloClient(...args);
    }

    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = globalApolloClient.extract();

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [
                ...sourceArray,
                ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
            ],
        });

        // Restore the cache with the merged data
        globalApolloClient.cache.restore(data);
    }

    return globalApolloClient;
};

export function addApolloState<P extends { props: Record<string, unknown> }>(
    client: ApolloClient<NormalizedCacheObject>,
    pageProps: P
) {
    if (pageProps?.props) {
        // eslint-disable-next-line no-param-reassign
        pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }

    return pageProps;
}

export default createApolloClient;
