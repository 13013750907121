import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Flex } from '@rebass/grid';
import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import parseToDateString from '../constants/parseToDateString';
import { OrderSummaryItem_Ticket_Fragment, TicketStatus } from '../generated/graphql';
import createImgProps from '../utils/createImgProps';
import parseDateTime from '../utils/parseDateTime';
import Stack from './Stack';
import SummaryBarItem, { SummaryBarItemBody, SummaryBarItemField, SummaryBarItemThumbnail } from './SummaryBarItem';
import SummaryBarItemPriceLine from './SummaryBarItemPriceLine';
import useGetPageSlugByLocale from '../hooks/useGetPageSlugByLocale';

export const fragment = gql`
    fragment OrderSummaryItemTicket on Ticket {
        activity {
            id
            slug
            name
            place
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
        }
        timeslot {
            id
            startDateTime
            endDateTime
            rateGroup {
                rates {
                    id
                    name
                    price
                }
            }
        }
    }
`;

interface Props {
    item: OrderSummaryItem_Ticket_Fragment;
    updating?: boolean;
    hidePrices: boolean;
}

const SummaryBarItemTicket: FC<React.PropsWithChildren<Props>> = ({ item, hidePrices, updating }) => {
    const { activity, timeslot, ticketStatus } = item;

    const router = useRouter();
    const intl = useIntl();
    const { getPageSlugByLocale } = useGetPageSlugByLocale();

    const activityPageSlug = getPageSlugByLocale('ACTIVITY');
    if (!activity || !timeslot) {
        return null;
    }
    const { name, place, jpegThumbnail, webpThumbnail, slug } = activity;
    const { endDateTime, startDateTime } = timeslot;

    return (
        <SummaryBarItem
            updating={updating}
            item={item}
            thumbnail={
                <SummaryBarItemThumbnail
                    onClick={() => {
                        router.push('/[activity]/[slug]', {
                            pathname: `/${activityPageSlug}/${slug}`,
                            query: {
                                date: parseToDateString(parseDateTime(startDateTime)),
                            },
                        });
                    }}
                    thumbnail={
                        jpegThumbnail && webpThumbnail ? createImgProps(jpegThumbnail, webpThumbnail) : undefined
                    }
                    title={name}
                    subTitle={
                        <SummaryBarItemBody>
                            {place
                                ? intl.formatMessage({ defaultMessage: '{place}, activiteit' }, { place })
                                : undefined}
                        </SummaryBarItemBody>
                    }
                />
            }
        >
            <SummaryBarItemField title={<FormattedMessage defaultMessage="Datum" />}>
                <Body variant="small">
                    <FormattedDate value={parseDateTime(startDateTime)} format="FULLWEEKDAYMONTHYEAR" />
                </Body>
            </SummaryBarItemField>
            <SummaryBarItemField title={<FormattedMessage defaultMessage="Tijd" />}>
                <Body variant="small">
                    <FormattedTime value={parseDateTime(startDateTime)} /> —{' '}
                    <FormattedTime value={parseDateTime(endDateTime)} />
                </Body>
            </SummaryBarItemField>
            {!hidePrices && (
                <SummaryBarItemField title={<FormattedMessage defaultMessage="Prijsopbouw" />}>
                    <Stack spacing={3}>
                        {ticketStatus !== TicketStatus.Waived &&
                            item.priceLines.map((line, key) => <SummaryBarItemPriceLine priceLine={line} key={key} />)}
                        {ticketStatus === TicketStatus.Waived && (
                            <Flex>
                                <Body variant="small">
                                    <FormattedMessage defaultMessage="Vervallen" />
                                </Body>
                            </Flex>
                        )}
                    </Stack>
                </SummaryBarItemField>
            )}
        </SummaryBarItem>
    );
};

export default SummaryBarItemTicket;
