// https://every-layout.dev/layouts/stack

import { Flex, FlexProps } from '@rebass/grid';
import React, { CSSProperties, forwardRef, FC, Ref } from 'react';
import styled, { css } from 'styled-components';
import { ConfigStyle, ResponsiveValue, system } from 'styled-system';

type StackVariant = 'inline' | 'default';

const properties: Record<StackVariant, ConfigStyle['property']> = { default: 'marginTop', inline: 'marginLeft' };

export interface StackProps extends FlexProps {
    spacing?: ResponsiveValue<CSSProperties['margin']>;
    variant?: StackVariant;
    ref?: Ref<HTMLDivElement>;
}

const Stack: FC<React.PropsWithChildren<StackProps>> = forwardRef(({ variant = 'default', ...props }, ref) => (
    <StyledStack variant={variant} {...props} ref={ref} />
));

const getCss = (variant: StackVariant = 'default') => css`
    flex-direction: ${variant === 'default' ? 'column' : 'row'};
    && {
        > * + * {
            ${system({ spacing: { scale: 'space', property: properties[variant] } })};
        }
    }
`;

const StyledStack = styled(Flex)<Required<StackProps>>`
    ${({ variant }) => getCss(variant)}
`;

export default Stack;
