import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import createBorderCss from '@oberoninternal/travelbase-ds/utils/createBorderCss';
import { BoxProps } from '@rebass/grid';
import React, { FC, forwardRef, Ref } from 'react';
import styled, { css } from 'styled-components';

export type WizardStepVariant = 'done' | 'active' | 'todo' | 'disabled';

export interface WizardStepProps extends Omit<BoxProps, 'ref'> {
    variant?: WizardStepVariant;
    step: number;
    ref?: Ref<HTMLDivElement>;
    valid?: boolean;
}
const WizardStep: FC<React.PropsWithChildren<WizardStepProps>> = forwardRef((props, ref) => {
    const { step, variant, children, ...rest } = props;
    return (
        <Container variant={variant} {...rest} ref={ref}>
            <Circle>{step}</Circle>
            <StyledBody>{children}</StyledBody>
        </Container>
    );
});

export default WizardStep;

const StyledBody = styled(Body).attrs({ variant: 'tiny' })`
    color: ${({ theme }) => theme.colors.neutral['50']};
`;

const Circle = styled.div`
    ${createBorderCss('2px')}
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.secondary['30']};
    --border-color: ${({ theme }) => theme.colors.secondary['30']};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral['0']};
    border-radius: 50%;
    width: calc(var(--circle-dimension) * 1px);
    height: calc(var(--circle-dimension) * 1px);
    flex-shrink: 0;
    z-index: 1;
`;

const todoCss = css`
    ${Circle} {
        background: ${({ theme }) => theme.colors.neutral['0']};
        color: ${({ theme }) => theme.colors.neutral['50']};
        --border-color: ${({ theme }) => theme.colors.neutral['20']};
    }
`;

const activeCss = css`
    ${StyledBody} {
        color: ${({ theme }) => theme.colors.neutral['100']};
    }
`;

const doneCss = css``;

const disabledCss = css`
    && {
        ${Circle} {
            background: ${({ theme }) => theme.colors.neutral['20']};
            --border-color: ${({ theme }) => theme.colors.neutral['20']};
            color: ${({ theme }) => theme.colors.neutral['50']};
        }
        ::after {
            background: ${({ theme }) => theme.colors.neutral['20']};
        }
    }
`;

const getCss = (variant: WizardStepVariant) => {
    switch (variant) {
        case 'todo':
            return todoCss;
        case 'active':
            return activeCss;
        case 'done':
            return doneCss;
        case 'disabled':
            return disabledCss;
        default:
            throw new UnreachableCaseError(variant);
    }
};

const Container = styled.div<{ variant?: WizardStepVariant; valid?: boolean }>`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        flex-direction: column;
        --circle-dimension: 24;
        :not(:first-of-type)::after {
            content: '';
            width: 100%;
            right: 50%;
            height: 2px;
            top: calc(var(--circle-dimension) / 2 * 1px - 1px);
            position: absolute;
            background: ${({ theme, valid }) => (valid ? theme.colors.secondary['30'] : theme.colors.neutral['20'])};
        }

        > * + * {
            margin-top: ${({ theme }) => theme.spacing['30_Small']};
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex-direction: row;
        --circle-dimension: 32;

        > * + * {
            margin-left: ${({ theme }) => theme.spacing['30_Small']};
        }
    }

    ${({ variant = 'todo' }) => getCss(variant)};
`;
