import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

type Variant = 'large' | 'default' | 'small' | 'tiny';

const Heading = styled.div<SpaceProps & { variant?: Variant }>`
    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin-bottom: ${({ theme, variant }) => {
            switch (variant) {
                case 'tiny':
                    return theme.spacing['30_Small'];
                case 'large':
                    return theme.spacing['80_XXLarge'];
                case 'small':
                    return '24px';
                default:
                    return theme.spacing['50_Semi'];
            }
        }};
    }
    ${space};
`;

export default Heading;
