import * as Yup from 'yup';
import { ObjectSchema } from 'yup';
import addYears from 'date-fns/addYears';
import isAfter from 'date-fns/isAfter';
import set from 'date-fns/set';
import { Birthdate } from './entities/Birthdate';

type MinimumAgeFn = (age: number, message?: string) => ObjectSchema;

declare module 'yup' {
    interface ObjectSchema {
        minimumAge: MinimumAgeFn;
    }
}

const method: MinimumAgeFn = (age, message) =>
    Yup.object().test({
        message,
        test(value: Birthdate) {
            const { createError, path } = this;

            const minDate = addYears(new Date(), -age);

            if (value.year && value.month && value.year) {
                const parsedDate = set(new Date(0), {
                    year: +value.year,
                    month: +value.month - 1, // months are zero indexed...
                    date: +value.day,
                });
                if (isAfter(parsedDate, minDate)) {
                    createError({
                        path,
                        message,
                    });
                    return false;
                }
            }

            return true;
        },
    });

Yup.addMethod(Yup.object, 'minimumAge', method);
