import { CustomFormats } from 'react-intl';

const formats: CustomFormats = {
    number: {
        EUR: {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        },
        EUR_ROUNDED: {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        },
        PERCENTAGE: {
            style: 'percent',
            maximumFractionDigits: 1,
        },
    },
    date: {
        WEEKDAYMONTH: {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
        },
        MONTH: {
            month: 'long',
        },
        WEEKDAYMONTHYEAR: {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        },
        FULLWEEKDAYMONTHYEAR: {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        },
        DATERANGEPICKER: {
            day: 'numeric',
            month: 'short',
        },
        DAYMONTHYEAR: {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        },
        WEEKDAY: {
            weekday: 'long',
        },
    },
};

export default formats;
