import styled from 'styled-components';

const SvgButton = styled.button`
    display: flex;
    padding: 0;
    > svg {
        margin: auto;
    }
`;

export default SvgButton;
