import React from 'react';
import { PaymentMethodEnum, SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';
import { BrandConfig } from './brandConfig';
import { rentalUnitTypes } from './filterTypes';
import wadden from '../themes/wadden';
import { Transform } from 'react-html-parser';
import { Main } from 'next/document';
import amelandConfig from './brandConfig.ameland';
import schierConfig from './brandConfig.schier';
import waterlandConfig from './brandConfig.waterland';
import { FormattedMessage } from 'react-intl';
import TranslatedPhoneNumber from '../components/TranslatedPhoneNumber';
import WhatsApp from '../components/svg/socials/Whatsapp.svg';
import Phone from '../components/svg/socials/Phone.svg';
import Mail from '../components/svg/socials/Mail.svg';

const waddenConfig: BrandConfig = {
    name: 'wadden',
    locales: ['nl', 'de', 'en'],
    forceAffiliate: 'wadden',
    theme: wadden,
    gtmID: '',
    font: {
        css: `
        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');


    `,
    },
    // language=CSS
    globalStyling: `
        main {
            padding-top: 0 !important;
        }
        .searchbar {
            margin-top: 12rem !important;
        }

        @media screen and (max-width: ${wadden.mediaQueriesValues.s - 1}px) {
            .searchbar {
                margin-top: 8rem !important;
            }

        }

        .hero-searchbar-container {
            top: 0 !important;
        }

        .checkout-content-wrapper {
            padding-top: 12rem !important;
        }
    `,
    template: {
        containerId: 'content',
        urls: {
            nl: 'https://www.wadden.nl/zoek-en-boek',
            de: 'https://www.holland-nordseeinseln.de/suchen-buchen',
            en: 'https://www.dutchwaddenislands.com/search-book',
        },
        hrefParser: href => href.replace('lege-pagina', ''),
        getCustomMainRenderer: async () => {
            const { convertNodeToElement } = await import('react-html-parser');
            return parserArgs => {
                const [node, , transform] = parserArgs;
                // @ts-ignore
                const children = node.children?.map((child, i) =>
                    convertNodeToElement(child, i, transform as Transform)
                );
                return (
                    <main role="main">
                        <Main />
                        {children}
                    </main>
                );
            };
        },
    },

    accommodationTypes: [
        {
            // not a valid filter, so make sure to escape the value when using it
            message: 'all',
            value: 'all',
        },
        {
            message: 'vacationHomes',
            value: rentalUnitTypes.HOME,
        },
        {
            message: 'hotelOrBnB',
            value: rentalUnitTypes.ROOM,
        },
        {
            message: 'groupAccommodations',
            value: `${rentalUnitTypes.HOME} type-of-accommodation.group-accommodation`,
        },
        {
            message: 'camping',
            value: `${rentalUnitTypes.CAMPING_PITCH}`,
        },
    ],

    groupTypes: ['adults', 'youth', 'children', 'babies', 'pets'],
    unitSearchOrder: {
        popularity: SearchRentalUnitsOrderEnum.RecentBookingsBoosted,
        ratingDesc: SearchRentalUnitsOrderEnum.ReviewRatingDesc,
    },
    tripsSearchOrder: {
        popularity: UnitSearchOrderEnum.RecentBookingsBoosted,
        ratingDesc: UnitSearchOrderEnum.ReviewRatingDesc,
        priceAsc: UnitSearchOrderEnum.PriceAsc,
        priceDesc: UnitSearchOrderEnum.PriceDesc,
    },

    // Map zoom level
    mapZoom: 9.415853514279021,

    // Map min zoom level
    mapMinZoom: {
        desktop: 6,
        mobile: 6,
    },

    coordinates: {
        latitude: 53.228483528828384,
        longitude: 5.52337487502224,
    },

    seo: {
        twitterUsername: '@wadden',
        siteName: 'Wadden',
    },

    minBookingAge: 18,
    cancellationInsuranceDefault: true,

    customContent: {},

    searchBox: {
        hideScrollbar: true,
        hasBrandSearch: true,
    },
    contact: {
        useNativeContactInfo: true,
        contactInfoByBrand: {
            texel: [
                {
                    name: <FormattedMessage defaultMessage="Stel je vraag via WhatsApp" />,
                    content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                    icon: <WhatsApp width={24} height={24} />,
                    link: 'https://wa.me/+31683357827',
                    queryParams: {
                        nl: {
                            text: 'Beste VVV Texel, ik heb een vraag over {rentalUnitName}',
                        },
                        de: {
                            text: 'Sehr geehrter Mitarbeiter des VVV Texel, ich habe eine Frage zu {rentalUnitName}',
                        },
                        en: {
                            text: 'Dear VVV Texel employee, I have a question about {rentalUnitName}',
                        },
                    },
                },
                {
                    name: (
                        <>
                            <FormattedMessage defaultMessage="Bel" />{' '}
                            <TranslatedPhoneNumber phoneNumber="+31 222 314 741" />
                        </>
                    ),
                    content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                    icon: <Phone width={24} height={24} />,
                    link: 'tel:+31(0)222314741',
                },

                {
                    name: <FormattedMessage defaultMessage="E-mail" />,
                    content: <FormattedMessage defaultMessage="Je krijgt binnen twee werkdagen een reactie." />,
                    icon: <Mail width={24} height={24} />,
                    link: 'mailto:info@texel.net',
                    queryParams: {
                        nl: {
                            body: 'Beste VVV Texel, ik heb een vraag over {rentalUnitName}',
                        },
                        de: {
                            body: 'Sehr geehrter Mitarbeiter des VVV Texel, ich habe eine Frage zu {rentalUnitName}',
                        },
                        en: {
                            body: 'Dear VVV Texel employee, I have a question about {rentalUnitName}',
                        },
                    },
                },
            ],
            terschelling: [
                {
                    name: <FormattedMessage defaultMessage="Stel je vraag via WhatsApp" />,
                    content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                    icon: <WhatsApp width={24} height={24} />,
                    link: '',
                    queryParams: {
                        nl: {
                            text: 'Beste VVV Terschelling, ik heb een vraag over {rentalUnitName}',
                        },
                        de: {
                            text: 'Sehr geehrter Mitarbeiter des VVV Terschelling, ich habe eine Frage zu {rentalUnitName}',
                        },
                        en: {
                            text: 'Dear VVV Terschelling employee, I have a question about {rentalUnitName}',
                        },
                    },
                },
                {
                    name: (
                        <>
                            <FormattedMessage defaultMessage="Bel" /> <span>+31 562 443000</span>
                        </>
                    ),
                    content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                    icon: <Phone width={24} height={24} />,
                    link: 'tel:+31562443000',
                },

                {
                    name: <FormattedMessage defaultMessage="E-mail" />,
                    content: <FormattedMessage defaultMessage="Je krijgt binnen twee werkdagen een reactie." />,
                    icon: <Mail width={24} height={24} />,
                    link: 'mailto:info@vvvterschelling.nl',
                    queryParams: {
                        nl: {
                            body: 'Beste VVV Terschelling, ik heb een vraag over {rentalUnitName}',
                        },
                        de: {
                            body: 'Sehr geehrter Mitarbeiter des VVV Terschelling, ich habe eine Frage zu {rentalUnitName}',
                        },
                        en: {
                            body: 'Dear VVV Terschelling employee, I have a question about {rentalUnitName}',
                        },
                    },
                },
            ],
            ameland: amelandConfig.contact.info,
            schier: schierConfig.contact.info,
            waterland: waterlandConfig.contact.info,
        },
    },
    locationDescription: {
        useNativeLocationDescription: true,
        locationDescriptionByBrand: {
            texel: {
                imgSrc: '/static/img/texel.jpg',
                imgAlt: {
                    nl: 'Dit is Texel',
                    en: 'This is Texel',
                    de: 'Das ist Texel',
                },

                tips: {
                    nl: [
                        'Uitkijkpunt De Fonteinsnol',
                        'Een boottocht op het wad',
                        'Het Sommeltjespad aan de Pelikaanweg',
                        'Wadlopen over de bodem van de zee',
                        'Het strand bij de vuurtoren',
                    ],
                    en: [
                        "Viewpoint 'De Fonteinsnol'",
                        'A boattrip along the Waddensea (tidal flats)',
                        "'Sommeltjespad' at the Pelikaanweg",
                        'Mudflat hiking across the bottom of the sea',
                        'The beach at the lighthouse',
                    ],
                    de: [
                        'Aussichtspunkt De Fonteinsnol',
                        'Eine Bootsfahrt auf dem Wattenmeer',
                        'Sommeltjespad am Pelikaanweg',
                        'Wattwanderung über den Meeresboden',
                        'Der Strand am Leuchtturm',
                    ],
                },

                description: {
                    nl:
                        'Texel is een bruisend eiland.' +
                        'Het hele jaar door is er veel te doen: ' +
                        'van unieke sportevenementen en culinaire activiteiten tot de leukste muziekfestivals. ' +
                        'Natuurlijk is een bezoek aan bekende plekken zoals natuurgebied de Slufter, ' +
                        'de Vuurtoren en Ecomare meer dan de moeite waard. ' +
                        'Maar er zijn nog veel meer plekken te ontdekken.',
                    en: 'Texel is a vibrant island. There are many activities throughout the year. Unique sports events, culinary activities to the best music festivals. Of course a visit to famous scenic spots such as the Slufter nature reserve, the Lighthouse and Ecomare are more than worthwhile.',
                    de: `Texel ist eine lebendige Insel. Es gibt viele Aktivitäten während des ganzen Jahres. Von einzigartigen Sportveranstaltungen, kulinarische Aktivitäten zu den besten Musikfestivals. Natürlich ist ein Besuch der berühmten Aussichtspunkte wie die Slufter, der Leuchtturm und Ecomare mehr als lohnenswert.`,
                },
            },
            terschelling: {
                imgSrc: '/static/img/drenkelingenhuisje.jpeg',
                imgAlt: {
                    nl: 'Het Drenkelingenhuisje',
                    en: 'The Drenkelingenhuisje',
                    de: 'Das Drenkelingenhuisje',
                },

                tips: {
                    nl: [
                        'Sterrenkijken in het Dark Sky Park',
                        'Bezoek het Drenkelingenhuisje',
                        'Fiets langs alle uitzichtpunten',
                        'Wadlopen over de bodem van de zee',
                        'Een uitgebreide strandwandelling',
                    ],
                    en: [
                        'Stargazing in the Dark Sky Park',
                        'Visit the Drenkelingenhuisje',
                        'Cycle along all viewpoints',
                        'Mudflat walking over the bottom of the sea',
                        'An extensive beach walk',
                    ],
                    de: [
                        'Sternenbeobachtung im Dark Sky Park',
                        'Besuchen Sie das Drenkelingenhuisje',
                        'Radeln Sie entlang aller Aussichtspunkte',
                        'Wattwandern über dem Meeresgrund',
                        'Ein ausgiebiger Strandspaziergang',
                    ],
                },

                description: {
                    nl: 'Beleef het ware eilandgevoel.<br/>Waar je ook bent, je proeft, ziet, hoort, ruikt en voelt de zee. Even helemaal weg en dat pure eilandgevoel beleven. Dat is Terschelling. Je hoofd leeg laten waaien en plek maken voor nieuwe herinneringen. Je helemaal onderdompelen in dat ware eilandgevoel.',
                    en: 'Experience the true island feeling.<br/>Wherever you are, you can taste, see, hear, smell and feel the sea. Leave your troubles behind and experience that pure island feeling. That is Terschelling. Clear your head and make room for new memories. Completely immerse yourself in that true island feeling.',
                    de: 'Erleben Sie das wahre Inselgefühl.<br/>Wo immer Sie sind, Sie können das Meer schmecken, sehen, hören, riechen und fühlen. Entfliehen Sie dem Alltag und erleben Sie das wahre Inselgefühl. Das ist Terschelling. Den Kopf frei bekommen und Platz für neue Erinnerungen schaffen. Tauchen Sie ganz in das wahre Inselgefühl ein.',
                },
            },
            waterland: waterlandConfig.locationDescription,
            ameland: amelandConfig.locationDescription,
            schier: schierConfig.locationDescription,
        },
    },
    showBrandInSearchTile: true,
    checkout: {
        checkoutShouldRedirect: true,
        mobileHeaderPlacement: 'bottom',
        initialPaymentMethod: {
            nl: PaymentMethodEnum.Ideal,
            en: PaymentMethodEnum.CreditCard,
            de: PaymentMethodEnum.Paypal,
        },
    },
    complaintFormUrl: {
        nl: 'https://www.wadden.nl/nl/contact',
        de: 'https://www.wadden.nl/de/kontakt',
        en: 'https://www.wadden.nl/en/contact',
    },
    homepageUrl: {
        nl: 'https://www.wadden.nl',
        de: 'https://www.wadden.nl/de',
        en: 'https://www.wadden.nl/en',
    },
};

export default waddenConfig;
