import React, { ReactNode } from 'react';
import { defineMessages, IntlShape } from 'react-intl';
import Bedroom from '../components/svg/Bedroom.svg';
import House from '../components/svg/House.svg';
import RV from '../components/svg/RV.svg';
import Tent from '../components/svg/Tent.svg';
import Boat from '../components/svg/Boat.svg';
import { RentalUnitTypeEnum } from '../generated/graphql';

type UnitTypeConst = {
    translation: string;
    icon: ReactNode;
};

export const unitTypeMessages = defineMessages<RentalUnitTypeEnum>({
    HOME: {
        defaultMessage: 'Vakantiehuis',
    },
    CAMPING_PITCH: {
        defaultMessage: 'Camping',
    },
    ROOM: {
        defaultMessage: 'Kamer',
    },
    TENT: {
        defaultMessage: 'Tent',
    },
    BED: {
        defaultMessage: 'Bed',
    },
    CAMPER_SPOT: {
        defaultMessage: 'Camper',
    },
    BOAT: {
        defaultMessage: 'Boot',
    },
});

// TODO: kinda needless. just make a Record<RentalUnitTypeEnum, ReactNode> for the icons and use the messages object above directly.
const getUnitTypeConsts = (intl: IntlShape): Record<RentalUnitTypeEnum, UnitTypeConst> => {
    const { formatMessage } = intl;
    return {
        HOME: {
            translation: formatMessage(unitTypeMessages[RentalUnitTypeEnum.Home]),
            icon: <House />,
        },
        CAMPING_PITCH: {
            translation: formatMessage(unitTypeMessages[RentalUnitTypeEnum.CampingPitch]),
            icon: <Tent />,
        },
        ROOM: {
            translation: formatMessage(unitTypeMessages[RentalUnitTypeEnum.Room]),
            icon: <Bedroom />,
        },
        TENT: {
            translation: formatMessage(unitTypeMessages[RentalUnitTypeEnum.Tent]),
            icon: <Tent />,
        },
        BED: {
            translation: formatMessage(unitTypeMessages[RentalUnitTypeEnum.Bed]),
            icon: <Bedroom />,
        },
        CAMPER_SPOT: {
            translation: formatMessage(unitTypeMessages[RentalUnitTypeEnum.CamperSpot]),
            icon: <RV />,
        },
        BOAT: {
            translation: formatMessage(unitTypeMessages[RentalUnitTypeEnum.Boat]),
            icon: <Boat width="22px" height="22px" />,
        },
    };
};

export default getUnitTypeConsts;
