import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import { Flex } from '@rebass/grid';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FC, ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useTenantContext } from '../context/TenantContext';
import Heading from './designsystem/Heading';
import Page from './Page';

interface Props {
    title: ReactNode;
    description: ReactNode;
}

const ErrorTemplate: FC<React.PropsWithChildren<Props>> = ({ title, description, children }) => {
    const { push } = useRouter();
    const [animate, setAnimate] = useState(false);
    const pushToHome = () => {
        push(`/`);
    };
    const deviceSize = useDeviceSize();
    const { brandConfig } = useTenantContext();
    const onClickHandler = () => {
        if (deviceSize === 'mobile' || deviceSize === 'tablet') {
            pushToHome();
        } else {
            setAnimate(true);
        }
    };
    return (
        <Page>
            <Flex style={{ height: '100vh', position: 'relative' }} alignItems="center">
                <Content flexDirection="column" animate={animate}>
                    <Heading variant="large">
                        <Title variant="large">{title}</Title>
                    </Heading>
                    <Body>{description}</Body>
                    {children}
                    {!children && (
                        <Button
                            onClick={onClickHandler}
                            as="a"
                            style={{ marginTop: '5.6rem', alignSelf: 'flex-start', cursor: 'pointer' }}
                        >
                            <FormattedMessage defaultMessage="Terug naar de homepagina" />
                        </Button>
                    )}
                </Content>
                <ImgWrapper className="gt-l" animate={animate} onAnimationEnd={pushToHome}>
                    {brandConfig.indexImage && (
                        <Image
                            priority
                            alt={brandConfig.seo.siteName}
                            src={brandConfig.indexImage}
                            layout="fill"
                            objectFit="cover"
                            quality={100}
                            placeholder="blur"
                        />
                    )}
                </ImgWrapper>
            </Flex>
        </Page>
    );
};

export default ErrorTemplate;

const Content = styled(Flex)<{ animate: boolean }>`
    margin: auto;
    z-index: 1;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        margin: unset;
        position: absolute;
        left: 13vw;
        width: 30%;
        animation: ${({ animate }) => animate && 'fadeout'} 300ms forwards ease-out;
    }

    @keyframes fadeout {
        from {
            opacity: 1;
            transform: translateY(0);
        }
        to {
            opacity: 0;
            transform: translateY(5rem);
        }
    }
`;

const ImgWrapper = styled.div<{ animate: boolean }>`
    width: calc(100vw + var(--wrapperPadding));
    height: 100vh;
    clip-path: inset(0 0 0 50%);
    @keyframes fadein {
        from {
            clip-path: inset(0 0 0 50%);
        }
        to {
            clip-path: inset(0);
        }
    }

    animation: ${({ animate }) => animate && 'fadein'} 500ms forwards ease-out;
    animation-delay: 300ms;

    img {
        object-fit: cover;
    }
`;
