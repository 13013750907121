import Img, { BaseImgProps } from '../Img';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title, { TitleInner } from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import { Flex } from '@rebass/grid';
import React, { FC, HTMLAttributes, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';
import Stack from '../Stack';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';

export type UnitThumbnailVariant = 'regular' | 'large';

export interface UnitThumbnailProps extends HTMLAttributes<HTMLDivElement> {
    title?: string;
    strapline?: string;
    subTitle?: ReactNode;
    thumbnail?: BaseImgProps;
    loading?: boolean;
    variant?: UnitThumbnailVariant;
}

const UnitThumbnail: FC<React.PropsWithChildren<UnitThumbnailProps>> = ({
    title,
    strapline,
    subTitle,
    thumbnail,
    loading,
    variant,
    ...props
}) => (
    <UnitThumbnailContainer withLocation={!!strapline} {...props}>
        {loading ? (
            <Skeleton width="8rem" height="5.6rem" />
        ) : (
            <>
                {thumbnail && (
                    <Thumbnail
                        {...thumbnail}
                        sources={thumbnail.sources?.map(source => ({ ...source, sizes: '88px' }))}
                        variant={variant}
                    />
                )}
            </>
        )}
        <Flex flexDirection="column" mx={3} width={1}>
            {loading && (
                <Stack spacing={1} width={1}>
                    <Skeleton height="1.5rem" width="8rem" />
                    <Skeleton height="1.5rem" width="100%" />
                </Stack>
            )}
            {strapline && (
                <Title variant="regular" title={strapline} style={{ fontWeight: 'bold' }}>
                    {strapline}
                </Title>
            )}
            {variant === 'large' && title ? (
                <StyledTitle variant="large" title={title}>
                    {title}
                </StyledTitle>
            ) : (
                <StyledTitle variant="micro" title={title}>
                    {title}
                </StyledTitle>
            )}
            {subTitle && variant === 'large' ? <Subtitle variant="micro">{subTitle}</Subtitle> : subTitle}
        </Flex>
    </UnitThumbnailContainer>
);

interface ContainerProps {
    withLocation?: boolean;
}

const StyledTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
const UnitThumbnailContainer = styled(Flex).attrs({ alignItems: 'center' })<ContainerProps>`
    ${TitleInner} {
        text-align: start;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        overflow: hidden;
        ${({ theme }) => getFontCss(theme.fontSizes.title.micro)};
        line-height: 1.25 !important;
    }

    ${({ withLocation }) =>
        withLocation &&
        css`
            ${TitleInner} {
                ${({ theme }) => getFontCss(theme.fontSizes.title.micro)};
                line-height: 1.25 !important;
                -webkit-line-clamp: 1;
            }
        `}
    padding-right: 0;
`;

export default UnitThumbnail;

const regularCss = css`
    width: 8rem;
    height: 5.6rem;
`;

const largeCss = css`
    height: 4rem;
    width: 4rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin-right: ${({ theme }) => theme.spacing['50_Semi']};
        height: 8.8rem;
        width: 8.8rem;
    }
`;

const getThumbnailCss = (variant: UnitThumbnailVariant) => {
    switch (variant) {
        case 'regular':
            return regularCss;
        case 'large':
            return largeCss;
        default:
            throw new UnreachableCaseError(variant);
    }
};

const Thumbnail = styled(Img)<Pick<UnitThumbnailProps, 'variant'>>`
    position: relative;
    flex-shrink: 0;
    ${({ variant = 'regular' }) => getThumbnailCss(variant)};

    img {
        object-fit: cover;
    }
`;

const Subtitle = styled(Body)`
    ${({ theme }) => getFontCss(theme.fontSizes.title.micro)};
    line-height: 1.25;
    margin-top: 0.4rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        ${({ theme }) => getFontCss(theme.fontSizes.title.tiny)};
    }
`;
