import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Box, Flex } from '@rebass/grid';
import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { FormattedList, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';
import { useTenantContext } from '../context/TenantContext';
import {
    OrderSummaryFragment,
    OrderSummaryItem_Booking_Fragment,
    OrderSummaryItem_Ticket_Fragment,
    OrderSummaryItem_UpsellPurchase_Fragment,
    OrderSummaryItemFragment,
    PriceLine,
    PriceLineCategoryEnum,
} from '../generated/graphql';
import computePayToOwnerAmount from '../utils/computePayToOwnerAmount';
import { truthy } from '../utils/fp';
import TextButton from './designsystem/TextButton';
import UnitThumbnail from './designsystem/UnitThumbnail';
import Divider from './Divider';
import Loading from './Loading';
import Stack from './Stack';
import { iosStickyBarSafeSpace } from './StickyBar';
import SummaryBarItemBooking from './SummaryBarItemBooking';
import SummaryBarItemTicket from './SummaryBarItemTicket';
import Pricing from './SummaryBarPricing';
import SummaryBarItemUpsell from './SummaryBarItemUpsell';

export interface SummaryBarProps {
    data: Maybe<OrderSummaryFragment | OrderSummaryItemFragment>;
    loading?: boolean;
    updating?: boolean;
    showOnMobile?: boolean;
    isClickable?: boolean;
    hidePrices?: boolean;
    showPartialPaymentAmount?: boolean;
    exactLink?: boolean;
    totalPriceOnly?: boolean;
}

export const fragment = gql`
    fragment OrderSummary on Order {
        orderItems {
            ...OrderSummaryItem
        }
        paymentOptions {
            partialPaymentAmount
        }
        ...OrderMeta
    }
`;

const orderItemsOrder = ['Booking', 'Ticket', 'UpsellPurchase'];

export const isBooking = (item: OrderSummaryItemFragment): item is OrderSummaryItem_Booking_Fragment =>
    item.__typename === 'Booking';

const isTicket = (item: OrderSummaryItemFragment): item is OrderSummaryItem_Ticket_Fragment =>
    item.__typename === 'Ticket';

const isUpsell = (item: OrderSummaryItemFragment): item is OrderSummaryItem_UpsellPurchase_Fragment =>
    item.__typename === 'UpsellPurchase';

const SummaryBar: FC<React.PropsWithChildren<SummaryBarProps>> = ({
    updating,
    loading,
    showOnMobile = false,
    hidePrices = false,
    showPartialPaymentAmount = false,
    exactLink,
    isClickable = true,
    totalPriceOnly,
    ...props
}) => {
    const { open, onClose, onOpen } = useSesame();
    const { brandConfig } = useTenantContext();
    const { formatMessage } = useIntl();
    const { mobileHeaderPlacement = 'top' } = brandConfig.checkout ?? {};

    let data: Partial<OrderSummaryFragment> | undefined;
    let totalPrice = 0;

    if (
        props.data?.__typename === 'Booking' ||
        props.data?.__typename === 'Ticket' ||
        props.data?.__typename === 'UpsellPurchase'
    ) {
        data = { orderItems: [props.data] };
        totalPrice = props.data.totalPrice;
    }

    if (props.data?.__typename === 'Order') {
        data = props.data;
        totalPrice = props.data.totalPrice;
    }
    const { orderItems = [] } = data ?? {};

    const unrelatedPrices = orderItems.reduce<PriceLine[]>(
        (acc, item) => [...acc, ...item.priceLines.filter(line => line.category === PriceLineCategoryEnum.NotInTotal)],
        []
    );

    const bookings = orderItems.filter(isBooking);
    const tickets = orderItems.filter(isTicket);
    const upsell = orderItems.filter(isUpsell);

    const mainBooking: OrderSummaryItem_Booking_Fragment | undefined = bookings.find(
        booking => booking.__typename === 'Booking'
    );
    const isRequest = mainBooking?.requiresApproval;
    const {
        paymentPrice = 0,
        deposit = 0,
        totalPrice: bookingTotalPrice = 0,
        handleDepositPayment,
    } = mainBooking ?? {};
    const payToOwnerAmount = mainBooking
        ? computePayToOwnerAmount(bookingTotalPrice, handleDepositPayment, deposit, paymentPrice)
        : 0;
    const { partialPaymentAmount } = data?.paymentOptions ?? {};

    const content = (
        <Container>
            {loading && (
                <Box>
                    <UnitThumbnail loading />
                    <Loading mt={4} spacing={3} />
                </Box>
            )}
            <Stack spacing={6}>
                {!loading &&
                    [...orderItems]
                        .sort(
                            (itemA, itemB) =>
                                orderItemsOrder.indexOf(itemA?.__typename ?? '') -
                                orderItemsOrder.indexOf(itemB?.__typename ?? '')
                        )
                        .map((item, i) => {
                            if (!item.__typename) {
                                return null;
                            }
                            switch (item.__typename) {
                                case 'Booking':
                                    return (
                                        <div>
                                            {i !== 0 && <Divider />}

                                            <SummaryBarItemBooking
                                                key={item.rentalUnit?.id ?? i}
                                                item={item}
                                                updating={updating}
                                                hidePrices={hidePrices}
                                                isClickable={isClickable}
                                                exactLink={exactLink}
                                            />
                                        </div>
                                    );
                                case 'Ticket':
                                    return (
                                        <div>
                                            {i !== 0 && <Divider />}

                                            <SummaryBarItemTicket
                                                key={item.timeslot?.id ?? i}
                                                updating={updating}
                                                item={item}
                                                hidePrices={hidePrices}
                                            />
                                        </div>
                                    );
                                case 'UpsellPurchase':
                                    return (
                                        <div>
                                            {i !== 0 && <Divider />}

                                            <SummaryBarItemUpsell
                                                key={item?.offer?.id ?? i}
                                                item={item}
                                                updating={updating}
                                            />
                                        </div>
                                    );
                                default:
                                    throw new UnreachableCaseError(item.__typename);
                            }
                        })}
            </Stack>
            {!loading && !updating && !hidePrices && (
                <>
                    <Divider mt={5} mb={4} />
                    <Stack spacing={3}>
                        <Pricing label={formatMessage({ defaultMessage: 'totaal' })} price={totalPrice} main />
                        {showPartialPaymentAmount && !!partialPaymentAmount && partialPaymentAmount !== totalPrice && (
                            <Pricing
                                label={formatMessage({ defaultMessage: 'Je betaalt nu' })}
                                price={partialPaymentAmount}
                                main
                            />
                        )}

                        {unrelatedPrices?.map(line => (
                            <Pricing
                                key={line.label}
                                label={line.label}
                                price={line.totalPrice}
                                category={line.category}
                            />
                        ))}
                        {(!!payToOwnerAmount || !!deposit) && !isRequest && !totalPriceOnly && (
                            <Stack style={{ marginTop: '3.2rem' }} spacing={3}>
                                {/* if the totalPrice and payToOwnerAmount  */}
                                {!!payToOwnerAmount && payToOwnerAmount !== totalPrice && (
                                    <Body style={{ fontWeight: 300 }} variant="tiny">
                                        <FormattedMessage
                                            values={{
                                                price: (
                                                    <span style={{ fontWeight: 600 }}>
                                                        <FormattedNumber value={payToOwnerAmount} format="EUR" />
                                                    </span>
                                                ),
                                            }}
                                            defaultMessage="Van het totaal betaal je {price} rechtstreeks aan de verhuurder."
                                        />
                                    </Body>
                                )}
                                {!!deposit && (
                                    <Body style={{ fontWeight: 300 }} variant="tiny">
                                        {handleDepositPayment ? (
                                            <FormattedMessage
                                                values={{
                                                    price: (
                                                        <span style={{ fontWeight: 600 }}>
                                                            <FormattedNumber value={deposit} format="EUR" />
                                                        </span>
                                                    ),
                                                }}
                                                defaultMessage="De borgsom van {price} betaal je via ons aan en verreken je met de verhuurder bij vertrek"
                                            />
                                        ) : (
                                            <FormattedMessage
                                                values={{
                                                    price: (
                                                        <span style={{ fontWeight: 600 }}>
                                                            <FormattedNumber value={deposit} format="EUR" />
                                                        </span>
                                                    ),
                                                }}
                                                defaultMessage="De borgsom van {price} betaal je rechtstreeks aan de verhuurder"
                                            />
                                        )}
                                    </Body>
                                )}
                            </Stack>
                        )}
                    </Stack>
                </>
            )}
            {(loading || updating) && (
                <>
                    <Divider mt={5} mb={4} />
                    <Flex width={1} justifyContent="space-between">
                        <Box width="12rem">
                            <Skeleton height="2rem" />
                        </Box>
                        <Box width="6rem">
                            <Skeleton height="2rem" />
                        </Box>
                    </Flex>
                </>
            )}
        </Container>
    );

    return (
        <>
            {/* mobile */}
            {!showOnMobile ? (
                <>
                    <MobileHeader placement={mobileHeaderPlacement} className="lt-m checkout-mobile-header">
                        {(loading || updating) && (
                            <Flex justifyContent="space-between" alignItems="center">
                                <Box width="12rem">
                                    <Skeleton height="2rem" />
                                </Box>
                                <Box width="6rem">
                                    <Skeleton height="2rem" />
                                </Box>
                            </Flex>
                        )}
                        {!loading && (
                            <>
                                {!updating && (
                                    <Pricing
                                        label={<FormattedMessage defaultMessage="totaal" />}
                                        price={totalPrice}
                                        main
                                    />
                                )}
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Body>
                                        <FormattedList
                                            value={[
                                                bookings.length ? (
                                                    <FormattedMessage
                                                        key="accommodation"
                                                        values={{ amount: bookings.length }}
                                                        defaultMessage={`{amount, plural,
                                                            one {# accommodatie}
                                                            other {# accommodaties}
                                                            }
                                                        `}
                                                    />
                                                ) : null,
                                                tickets.length ? (
                                                    <FormattedMessage
                                                        key="ticket"
                                                        values={{ amount: tickets.length }}
                                                        defaultMessage={`{amount, plural,
                                                            one {# activiteit}
                                                            other {# activiteiten}
                                                            }
                                                        `}
                                                    />
                                                ) : null,
                                                upsell.length ? (
                                                    <FormattedMessage
                                                        key="upsell"
                                                        values={{ amount: upsell.length }}
                                                        defaultMessage={`{amount, plural,
                                                            one {# speciale aanbieding}
                                                            other {# speciale aanbiedingen}
                                                            }
                                                        `}
                                                    />
                                                ) : null,
                                            ].filter(truthy)}
                                        />
                                    </Body>
                                    <TextButton onClick={onOpen}>
                                        <FormattedMessage defaultMessage="uw boeking" />
                                    </TextButton>
                                </Flex>
                            </>
                        )}
                    </MobileHeader>

                    <Modal onClose={onClose} open={open}>
                        <ModalHeader style={{ textTransform: 'capitalize', marginBottom: '1.6rem' }}>
                            <FormattedMessage defaultMessage="uw boeking" />
                        </ModalHeader>
                        {content}
                    </Modal>
                </>
            ) : (
                <div className="lt-m">{content}</div>
            )}
            {/* desktop */}
            <StickyWrapper className="gt-m">{content}</StickyWrapper>
        </>
    );
};

export default SummaryBar;

const StickyWrapper = styled.div`
    top: var(--vertical-padding);
    position: sticky;
`;

const Container = styled.div`
    width: 100%;
`;

export const MobileHeader = styled.div<{ placement: 'top' | 'bottom' }>`
    box-shadow: 0 1.6rem 2.4rem 0 rgba(59, 118, 160, 0.03), 0 2.4rem 8rem 0 rgba(59, 118, 160, 0.05);
    position: fixed;
    background: ${({ theme }) => theme.colors.neutral[0]};
    left: 0;
    right: 0;
    height: 7rem;
    padding: 0 var(--wrapperPadding);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: ${({ theme }) => theme.zIndices.sticky};

    ${({ placement }) => {
        switch (placement) {
            case 'top':
                return css`
                    top: 0;
                    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['20']};
                `;
            case 'bottom':
                return css`
                    bottom: 0;
                    border-top: 1px solid ${({ theme }) => theme.colors.neutral['20']};
                    ${iosStickyBarSafeSpace}
                `;
            default:
                return '';
        }
    }};
`;
