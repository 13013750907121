import { Colors } from '@oberoninternal/travelbase-ds/themes/default';
import websiteTheme from './website';
import { FontStyle, Theme } from '@oberoninternal/travelbase-ds/constants/theme';
import { BodyVariant } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';

const colors: Colors = {
    primary: {
        ...websiteTheme.colors.primary,
        60: '#fff0ed',
        70: '#ffa391',
        80: '#ff6647',
        90: '#d24a06',
        100: '#742539',
    },
    secondary: {
        ...websiteTheme.colors.secondary,
        60: '#e6f8f0',
        80: '#07b86c',
    },
    tertiary: {
        ...websiteTheme.colors.tertiary,
        60: '#d3dfe0',
        70: '#c5d5d6',
        80: '#8da8a8',
        90: '#244e52',
        100: '#004147',
    },
    neutral: websiteTheme.colors.neutral,
    positive: websiteTheme.colors.positive,
    warning: websiteTheme.colors.warning,
    negative: websiteTheme.colors.negative,
};

const titleStyles: Record<TitleVariant, FontStyle> = {
    large: {
        size: { min: 31, max: 31 },
        line: { min: 40, max: 40 },
        weight: 500,
        color: colors.neutral[100],
    },
    regular: {
        size: { min: 23, max: 23 },
        line: { min: 28, max: 28 },
        weight: 500,
        color: colors.tertiary[100],
    },
    small: {
        size: { min: 19, max: 19 },
        line: { min: 24, max: 24 },
        weight: 500,
        color: colors.tertiary[100],
    },
    tiny: {
        size: { min: 16, max: 16 },
        line: { min: 20, max: 20 },
        weight: 500,
        color: colors.tertiary[100],
    },
    micro: {
        size: { min: 15, max: 15 },
        line: { min: 20, max: 20 },
        weight: 500,
        color: colors.tertiary[100],
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 20, max: 20 },
        line: { min: 24, max: 24 },
        weight: 400,
        color: colors.tertiary[100],
    },
    large: {
        size: { min: 18, max: 18 },
        line: { min: 24, max: 24 },
        weight: 400,
        color: colors.tertiary[100],
    },
    regular: {
        size: { min: 16, max: 16 },
        line: { min: 24, max: 24 },
        letterSpacing: 0,
        weight: 400,
        color: colors.tertiary[100],
    },
    small: {
        size: { min: 14, max: 14 },
        line: { min: 24, max: 24 },
        letterSpacing: 0,
    },
    tiny: {
        size: { min: 12, max: 12 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
    micro: {
        size: { min: 10, max: 10 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
    },
};

const campercontactTheme: Theme = {
    ...websiteTheme,
    name: 'Campercontact',

    fontFamily: {
        title: 'Shandon Slab, sans-serif',
        body: 'Red Hat Display, sans-serif',
    },

    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    colors,
    colorAssignments: {
        main: ['primary', 80],
        buttonPrimary: ['primary', 80],
        buttonLight: ['primary', 80],
        textAction: ['primary', 80],
        input: ['primary', 80],
        form: ['primary', 80],
        rating: ['secondary', 80],
        booking: ['primary', 80],
        calendar: {
            ...websiteTheme.colorAssignments.calendar,

            bookableWithin: {
                selected: ['neutral', 0],
                notSelected: ['neutral', 0],
            },
            bookableOutside: ['neutral', 0],
            bookable: {
                backgroundColor: ['secondary', 60],
                color: ['neutral', 100],
                focusColor: ['secondary', 80],
            },
            withinCss: {
                selected: ['neutral', 0],
                notSelected: ['secondary', 60],
            },
            selected: {
                backgroundColor: ['secondary', 80],
                color: ['neutral', 0],
                focusColor: ['secondary', 60],
            },
            periodBackground: {
                selected: ['secondary', 80],
                notSelected: ['secondary', 80],
            },
            price: ['secondary', 80],
        },
    },
    maxWidths: {
        xs: '464px',
        s: '636px',
        m: '960px',
        l: '1152px',
        xl: '1344px',
        xxl: '1440px',
    },
    radius: {
        button: '6px 6px 24px 6px', // we use this for buttons and SearchBar when inline on Search
        textInput: '0.8rem', // we use this for Input, TextInput, SelectDateInputs, Dropdown
        textArea: '0.6rem', // we use this for SelectInput, Select Option, Select open containers, Dropdown open containers
        dropdown: '0.5rem',
    },
};

export default campercontactTheme;
