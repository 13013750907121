import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import useRouterEvent from '../hooks/useRouterEvent';

interface Props {
    when: boolean;
    message: string;
}

export const promptErrorMessage =
    'Route change was aborted (this error can be safely ignored). See https://github.com/zeit/next.js/issues/2476.';

// An attempt at recreating the Prompt component from react-router for Next.js
const Prompt = ({ when, message }: Props) => {
    const router = useRouter();

    const handleUnload = useCallback(
        (event: BeforeUnloadEvent) => {
            if (when) {
                // eslint-disable-next-line no-param-reassign
                (event || window.event).returnValue = null;
                return null;
            }
            return undefined;
        },
        [when]
    );

    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload);
        return () => window.removeEventListener('beforeunload', handleUnload);
    }, [handleUnload]);

    const routeChangeStart = useCallback(
        (url: string) => {
            if (router.asPath !== url && when) {
                router.events.emit('routeChangeError');
                if (!window.confirm(message)) {
                    // Following is a hack-ish solution to abort a Next.js route change
                    // as there's currently no official API to do so
                    // See https://github.com/zeit/next.js/issues/2476#issuecomment-573460710

                    // eslint-disable-next-line @typescript-eslint/no-throw-literal
                    throw promptErrorMessage;
                }
            }
        },
        [message, router, when]
    );

    useRouterEvent('routeChangeStart', routeChangeStart);

    return null;
};

export default Prompt;
