/* eslint-disable */
// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
const Sentry = require('@sentry/node');
const SentryIntegrations = require('@sentry/integrations');
const Cookie = require('js-cookie');
const { isApolloError } = require('@apollo/client');
const { default: getApolloErrorType } = require('./getApolloErrorType');
const { promptErrorMessage } = require('../components/Prompt');

module.exports = (release = process.env.SENTRY_RELEASE) => {
    const sentryOptions = {
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        environment: process.env.NEXT_PUBLIC_BRAND,
        release,
        maxBreadcrumbs: 50,
        attachStacktrace: true,
        ignoreErrors: [promptErrorMessage],
    };

    // When we're developing locally
    if (process.env.NODE_ENV !== 'production') {
        // Don't actually send the errors to Sentry
        sentryOptions.beforeSend = () => {
            console.log('Prevented Sentry log in development mode.');
            return null;
        };

        // Instead, dump the errors to the console
        sentryOptions.integrations = [
            new SentryIntegrations.Debug({
                // Trigger DevTools debugger instead of using console.log
                debugger: false,
            }),
        ];
    }

    Sentry.init(sentryOptions);

    return {
        Sentry,
        captureException: (err, ctx) => {
            Sentry.configureScope(scope => {
                if (err.message) {
                    // De-duplication currently doesn't work correctly for SSR / browser errors
                    // so we force deduplication by error message if it is present
                    scope.setFingerprint([err.message]);
                }

                if (err.statusCode) {
                    scope.setExtra('statusCode', err.statusCode);
                }

                if (isApolloError(err)) {
                    scope.setExtra('apiErrorName', err.name);
                    scope.setExtra('apiStatusCode', getApolloErrorType(err));
                    scope.setExtra('apiErrorMessage', err.message);
                    scope.setExtra('apiErrorExtraInfo', err.extraInfo);
                    scope.setExtra('apiErrors', err.graphQLErrors);
                }

                scope.setTag('ssr', typeof window === 'undefined');

                if (ctx) {
                    const { req, res, errorInfo, query, pathname } = ctx;

                    if (res && res.statusCode) {
                        scope.setExtra('statusCode', res.statusCode);
                    }

                    if (typeof window !== 'undefined') {
                        scope.setExtra('query', query);
                        scope.setExtra('pathname', pathname);

                        // On client-side we use js-cookie package to fetch it
                        const sessionId = Cookie.get('sid');
                        if (sessionId) {
                            scope.setUser({ id: sessionId });
                        }
                    } else {
                        scope.setExtra('url', req.url);
                        scope.setExtra('method', req.method);
                        scope.setExtra('headers', req.headers);
                        scope.setExtra('params', req.params);
                        scope.setExtra('query', req.query);

                        // On server-side we take session cookie directly from request
                        if (req.cookies?.sid) {
                            scope.setUser({ id: req.cookies.sid });
                        }
                    }

                    if (errorInfo) {
                        Object.keys(errorInfo).forEach(key => scope.setExtra(key, errorInfo[key]));
                    }
                }
            });

            return Sentry.captureException(err);
        },
    };
};
