import TextButtonDS from '@oberoninternal/travelbase-ds/components/action/TextButton';
import styled from 'styled-components';

const TextButton = styled(TextButtonDS).attrs(props => ({
    showBorderAlways: true,
    ...props,
}))`
    > span {
        display: inline;
    }
`;

export default TextButton;
