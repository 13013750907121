import { Locale } from '../entities/Locale';
import {
    getAccommodationPageSlugByLocale,
    getActivityPageSlugByLocale,
    getCompanyPageSlugByLocale,
} from '../utils/getPageSlugByLocale';
import { useTenantContext } from '../context/TenantContext';
import { useCallback } from 'react';
import { useRouter } from 'next/router';

type PageType = 'ACTIVITY' | 'COMPANY' | 'ACCOMMODATION';

const useGetPageSlugByLocale = () => {
    const { brandConfig } = useTenantContext();
    const { locale: routerLocale } = useRouter();
    const getPageSlugByLocale = useCallback(
        (pageType: PageType, locale = routerLocale) => {
            switch (pageType) {
                case 'COMPANY':
                    return getCompanyPageSlugByLocale(locale as Locale, brandConfig);
                case 'ACCOMMODATION':
                    return getAccommodationPageSlugByLocale(locale as Locale, brandConfig);
                case 'ACTIVITY':
                default:
                    return getActivityPageSlugByLocale(locale as Locale, brandConfig);
            }
        },
        [brandConfig, routerLocale]
    );

    return {
        getPageSlugByLocale,
    };
};

export default useGetPageSlugByLocale;
