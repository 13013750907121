import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { isApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

const getApolloErrorType = (error: Maybe<Error>) =>
    error && isApolloError(error)
        ? (error.graphQLErrors[0] as (GraphQLError & { errorType?: string }) | undefined)?.errorType ??
          (error.graphQLErrors[0]?.extensions?.errorType as string)
        : undefined;

export default getApolloErrorType;
