import { PaymentMethodEnum, SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';
import { BrandConfig } from './brandConfig';
import { rentalUnitTypes } from './filterTypes';
import indexImage from '../../public/static/img/zandvoortBg.jpg';
import theme from '../themes/zandvoort';

const oberonConfig: BrandConfig = {
    name: 'Zandvoort',
    locales: ['nl', 'de', 'en'],
    theme,
    gtmID: {
        de: 'GTM-NKWZW9N',
        nl: 'GTM-NKWZW9N',
        en: 'GTM-MJW4MDK',
    },
    initialNewsletterChecked: true,
    font: {
        hrefs: [
            '/static/font/NunitoSans_10pt-Black.ttf',
            '/static/font/NunitoSans_10pt-Bold.ttf',
            '/static/font/NunitoSans_10pt-Light.ttf',
            '/static/font/NunitoSans_10pt-Regular.ttf',
            '/static/font/NunitoSans_10pt-SemiBold.ttf',
            '/static/font/NunitoSans_10pt-ExtraBold.ttf',
            '/static/font/NunitoSans_10pt-ExtraLight.ttf',
            '/static/font/NunitoSans_10pt-Medium.ttf',
        ],
        css: `
        // Nunito Sans
        @font-face {
            font-family: 'Nunito Sans';
            src: url('/static/font/NunitoSans_10pt-Black.ttf') format('truetype');
            font-weight: 900;
            font-display: swap;
        }

        @font-face {
            font-family: 'Nunito Sans';
            src: url('/static/font/NunitoSans_10pt-Bold.ttf') format('truetype');
            font-weight: bold;
            font-display: swap;
        }

        @font-face {
            font-family: 'Nunito Sans';
            src: url('/static/font/NunitoSans_10pt-Light.ttf') format('truetype');
            font-weight: 300;
            font-display: swap;
        }

        @font-face {
            font-family: 'Nunito Sans';
            src: url('/static/font/NunitoSans_10pt-Regular.ttf') format('truetype');
            font-weight: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Nunito Sans';
            src: url('/static/font/NunitoSans_10pt-SemiBold.ttf') format('truetype');
            font-weight: 600;
            font-display: swap;
        }

        @font-face {
            font-family: 'Nunito Sans';
            src: url('/static/font/NunitoSans_10pt-ExtraBold.ttf') format('truetype');
            font-weight: 800;
            font-display: swap;
        }

        @font-face {
            font-family: 'Nunito Sans';
            src: url('/static/font/NunitoSans_10pt-ExtraLight.ttf') format('truetype');
            font-weight: 200;
            font-display: swap;
        }

        @font-face {
            font-family: 'Nunito Sans';
            src: url('/static/font/NunitoSans_10pt-Medium.ttf') format('truetype');
            font-weight: 500;
            font-display: swap;
        }
    `,
    },
    termsAndConditions: {
        nl: `https://toolboxzandvoort.nl/wp-admin/admin-ajax.php?juwpfisadmin=false&action=wpfd&task=file.download&wpfd_category_id=684&wpfd_file_id=21555`,
        en: `https://toolboxzandvoort.nl/wp-admin/admin-ajax.php?juwpfisadmin=false&action=wpfd&task=file.download&wpfd_category_id=684&wpfd_file_id=21556`,
        de: `https://toolboxzandvoort.nl/wp-admin/admin-ajax.php?juwpfisadmin=false&action=wpfd&task=file.download&wpfd_category_id=684&wpfd_file_id=21554`
    },
    locationDescription: {
        imgSrc: '/static/img/zandvoortTips.jpg',
        imgAlt: {
            nl: '',
            en: '',
            de: '',
        },
        description: {
            nl: 'Zandvoort is een levendige kustplaats. Het hele jaar door is er veel te beleven, van unieke sportevenementen en culinaire activiteiten tot de leukste muziekfestivals. Natuurlijk is een bezoek aan bekende bezienswaardigheden zoals het prachtige strand en het Circuit Zandvoort zeker de moeite waard, maar er zijn nog veel meer plekken om te ontdekken.',
            de: 'Zandvoort ist ein lebendiges Küstenstädtchen. Das ganze Jahr über gibt es hier viel zu erleben, von einzigartigen Sportveranstaltungen über kulinarische Aktivitäten bis hin zu den besten Musikfestivals. Natürlich lohnt sich ein Besuch der bekannten Attraktionen wie dem schönen Strand und der Rennstrecke Zandvoort, aber es gibt noch viel mehr zu entdecken.',
            en: 'Zandvoort is a lively coastal town. Throughout the year there is a lot to experience, from unique sports events and culinary activities to the best music festivals. Of course, a visit to well-known attractions such as the beautiful beach and the Zandvoort Racetrack is definitely worthwhile, but there are many more places to discover.',
        },
        tips: {
            nl: [
                'Herten spotten in de Amsterdamse Waterleidingduinen.',
                'Wandelen tussen de Wisenten in het Nationaal Park Zuid-Kennemerland',
                'Slenteren door de ‘sloppies’',
                'Jutten op het strand',
                'Leer golfsurfen op de Noordzee',
            ],
            en: [
                'Deer spotting in the Amsterdam Water Supply Dunes',
                'Walking among the wisents in Zuid-Kennemerland National Park',
                'Strolling through the "sloppies"',
                'Beachcombing',
                'Learn to surf on the North Sea',
            ],
            de: [
                'Hirsche beobachten in den Amsterdamse Waterleidingduinen',
                'Wandern zwischen den Wisenten im Nationalpark Zuid-Kennemerland',
                'Spazieren durch die "Sloppies"',
                'Strandkämmen',
                'Surfen lernen an der Nordsee',
            ],
        },
    },
    template: {
        containerId: 'travelbase-container',
        urls: {
            // We host the (edited) template for visitzandvoort in our S3 bucket.
            // To edit the template, manually upload your changes to S3.
            nl: 'https://zandvoort-cdn.travelbase.nl/template-zandvoort-nl.html',
            de: 'https://zandvoort-cdn.travelbase.nl/template-zandvoort-de.html',
            en: 'https://zandvoort-cdn.travelbase.nl/template-zandvoort-en.html',
        },
    },
    contact: {},
    indexImage,
    accommodationTypes: [
        {
            message: 'all',
            value: 'all',
        },
        {
            message: 'hotelOrBnB',
            value: rentalUnitTypes.ROOM,
        },
        {
            message: 'vacationHomes',
            value: rentalUnitTypes.HOME,
        },
        {
            message: 'camping',
            value: `${rentalUnitTypes.CAMPING_PITCH} ${rentalUnitTypes.CAMPER_SPOT} ${rentalUnitTypes.TENT}`,
        },
    ],
    groupTypes: ['adults', 'youth', 'children', 'babies', 'pets'],
    unitSearchOrder: {
        popularity: SearchRentalUnitsOrderEnum.RandomBoosted,
        ratingDesc: SearchRentalUnitsOrderEnum.ReviewRatingDesc,
    },
    tripsSearchOrder: {
        popularity: UnitSearchOrderEnum.RandomBoosted,
        ratingDesc: UnitSearchOrderEnum.ReviewRatingDesc,
        priceAsc: UnitSearchOrderEnum.PriceAsc,
        priceDesc: UnitSearchOrderEnum.PriceDesc,
    },
    // Map zoom level
    mapZoom: 13.5,

    // Map min zoom level
    mapMinZoom: {
        desktop: 13.5,
        mobile: 11.5,
    },
    coordinates: {
        latitude: 52.3711,
        longitude: 4.5334,
    },
    seo: {
        siteName: 'Visit Zandvoort',
    },
    minBookingAge: 18,
    cancellationInsuranceDefault: true,
    complaintFormUrl: {
        nl: 'https://www.visitzandvoort.nl/contact',
        en: 'https://www.visitzandvoort.nl/contact',
        de: 'https://www.visitzandvoort.nl/contact',
    },

    checkout: {
        initialPaymentMethod: {
            nl: PaymentMethodEnum.Ideal,
            en: PaymentMethodEnum.CreditCard,
            de: PaymentMethodEnum.Paypal,
        },
    },
};

export default oberonConfig;
