import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { SurchargeCalculationEnum } from '../generated/graphql';

const surchargeCalculationTexts: Record<SurchargeCalculationEnum, ReactNode> = {
    PER_PIECE: <FormattedMessage defaultMessage="eenmalig" />,
    PER_PIECE_PER_NIGHT: <FormattedMessage defaultMessage="per nacht" />,
    PER_PET: <FormattedMessage defaultMessage="per huisdier" />,
    PER_PET_PER_NIGHT: <FormattedMessage defaultMessage="per huisdier per nacht" />,
    PER_PERSON: <FormattedMessage defaultMessage="per persoon" />,
    PER_PERSON_PER_NIGHT: <FormattedMessage defaultMessage="per persoon per nacht" />,
    SUBSEQUENT: <FormattedMessage defaultMessage="nacalculatie" />,
};

export default surchargeCalculationTexts;
