import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentMethodEnum, SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';
import schierTheme from '../themes/schier';
import { BrandConfig } from './brandConfig';
import { accommodationTypes, rentalUnitTypes } from './filterTypes';
import Phone from '../components/svg/socials/Phone.svg';
import Mail from '../components/svg/socials/Mail.svg';
import TranslatedPhoneNumber from '../components/TranslatedPhoneNumber';

const schierConfig: BrandConfig = {
    name: 'Schiermonnikoog',
    locales: ['nl', 'de', 'en'],
    theme: schierTheme,
    locationDescription: {
        imgSrc: '/static/img/schierTips.jpg',
        imgAlt: { nl: 'Dit is Schiermonnikoog', en: 'This is Schiermonnikoog', de: 'Das ist Schiermonnikoog' },
        tips: {
            nl: [
                'Bezoek informatiecentrum “Het Baken”',
                'Op excursie met een ervaren eilandgids',
                'Wandel over strand, door bos en duin',
                'Fiets van Oost naar West',
                'Leef je uit op het activiteitenstrand',
            ],
            en: [
                'Visit informationcenter “Het Baken”',
                'Go with an experienced island guide',
                'Walk on the beach, through woods and dunes',
                'Cycle from East to West',
                'Have fun on the activity beach',
            ],
            de: [
                'Besuchen Sie das Informationszentrum „Het Baken“',
                'Auf einem Ausflug mit einem erfahrenen Inselführer',
                'Gehen Sie am Strand durch Wälder und Dünen',
                'Radeln Sie von Ost nach West',
                'Viel Spaß am Aktivitätsstrand',
            ],
        },

        description: {
            nl: 'Schiermonnikoog is altijd een goed idee. Een dagje wandelen of fietsen, een weekend uitwaaien of een heerlijke vakantie met het gezin of vrienden. Ons eiland biedt rust voor de liefhebber, maar ook gezelligheid in één van de vele horecagelegenheden en/of winkels in ons authentieke dorp. De natuur is overweldigend mooi en divers. We verwelkomen u graag!',
            en: 'Schiermonnikoog is always a good idea. A day of walking or cycling, a weekend of fresh air or a wonderful holiday with family or friends. Our island offers peace and quiet for the enthusiast, but also fun in one of the many catering establishments and / or shops in our authentic village. Nature is overwhelmingly beautiful and diverse. We look forward to welcoming you!',
            de: 'Schiermonnikoog ist immer eine gute Idee. Ein Tag zum Wandern oder Radfahren, ein Wochenende an der frischen Luft oder ein wunderschöner Urlaub mit Familie oder Freunden. Unsere Insel bietet dem Enthusiasten Ruhe, aber auch Spaß in einem der vielen Catering-Betriebe und / oder Geschäfte in unserem authentischen Dorf. Die Natur ist überwältigend schön und vielfältig. Wir freuen uns darauf sie begrüßen zu dürfen!',
        },
    },
    contact: {
        info: [
            {
                name: (
                    <>
                        <FormattedMessage defaultMessage="Bel" />{' '}
                        <TranslatedPhoneNumber phoneNumber="+31 519 531 233" />
                    </>
                ),
                content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                icon: <Phone width={24} height={24} />,
                link: 'tel:+31519531233',
            },
            {
                name: <FormattedMessage defaultMessage="E-mail" />,
                content: <FormattedMessage defaultMessage="Je krijgt binnen twee werkdagen een reactie." />,
                icon: <Mail width={24} height={24} />,
                link: 'mailto:info@vvvschiermonnikoog.nl',
                queryParams: {
                    nl: {
                        subject: 'Beste VVV Schiermonnikoog, ik heb een vraag over {rentalUnitName}',
                    },
                    de: {
                        subject:
                            'Sehr geehrter Mitarbeiter des VVV Schiermonnikoog, ich habe eine Frage zu {rentalUnitName}',
                    },
                    en: {
                        subject: 'Dear VVV Schiermonnikoog employee, I have a question about {rentalUnitName}',
                    },
                },
            },
        ],

        page: {
            nl: 'https://www.vvvschiermonnikoog.nl/contact/',
            de: 'https://www.vvvschiermonnikoog.de/kontakt/',
            en: 'https://www.vvvschiermonnikoog.com/contact/',
        },
    },
    accommodationTypes: [
        {
            message: 'vacationHomes',
            value: rentalUnitTypes.HOME,
        },
        {
            message: 'hotel',
            value: `${rentalUnitTypes.ROOM} ${accommodationTypes.HOTEL}`,
        },
        {
            message: 'bnbOrRoom',
            value: `${rentalUnitTypes.ROOM} ${accommodationTypes.BED_AND_BREAKFAST} ${accommodationTypes.APARTMENT_BLOCK}`,
        },
        {
            message: 'groupAccommodations',
            value: `${rentalUnitTypes.HOME} type-of-accommodation.group-accommodation`,
        },
        {
            message: 'tent',
            value: rentalUnitTypes.TENT,
        },

        {
            message: 'camping',
            value: `${rentalUnitTypes.CAMPING_PITCH}`,
        },
        {
            // not a valid filter, so make sure to escape the value when using it
            message: 'all',
            value: 'all',
        },
    ],
    lastMinute: {
        defaultAccommodationType: 'all',
    },
    groupTypes: ['adults', 'youth', 'children', 'babies', 'pets'],
    unitSearchOrder: {
        popularity: SearchRentalUnitsOrderEnum.RandomBoosted,
        ratingDesc: SearchRentalUnitsOrderEnum.ReviewRatingDesc,
    },
    tripsSearchOrder: {
        popularity: UnitSearchOrderEnum.RandomBoosted,
        ratingDesc: UnitSearchOrderEnum.ReviewRatingDesc,
        priceAsc: UnitSearchOrderEnum.PriceAsc,
        priceDesc: UnitSearchOrderEnum.PriceDesc,
    },

    hidePlaceInSearchTile: true,

    // Map zoom level
    mapZoom: 13.2,

    // Map min zoom level
    mapMinZoom: {
        desktop: 10.5,
        mobile: 8.5,
    },

    coordinates: {
        latitude: 53.489701,
        longitude: 6.241929,
    },

    seo: {
        twitterUsername: '@vvvschiermonnik',
        siteName: 'VVV Schiermonnikoog',
    },

    minBookingAge: 18,
    cancellationInsuranceDefault: true,
    searchBox: { styleSheetUrl: process.env.NEXT_PUBLIC_SEARCH_BOX_STYLESHEET_URL },
    planner: {
        styleSheetUrl: process.env.NEXT_PUBLIC_PLANNER_STYLESHEET_URL,
    },
    gtmID: 'GTM-573RJ8F',
    template: {
        disableGTMWhenActive: true,
        containerId: 'container',
        urls: {
            de: 'https://www.vvvschiermonnikoog.de/tor-travelbase',
            en: 'https://www.vvvschiermonnikoog.com/tor-travelbase',
            nl: 'https://www.vvvschiermonnikoog.nl/tor-travelbase',
        },
    },
    termsAndConditions: {
        nl: 'https://www.vvvschiermonnikoog.nl/vvv-info/reisvoorwaarden/reisvoorwaarden',
        en: 'https://www.vvvschiermonnikoog.nl/vvv-info/reisvoorwaarden/reisvoorwaarden',
        de: 'https://www.vvvschiermonnikoog.de/vvv-info/reisebedingungen',
    },
    cancellationInsurance: {
        nl: 'https://www.vvvschiermonnikoog.nl/uploads/bestanden/Waarborgfonds_annuleringen/Waarborgregeling%20_VVVSchiermonnikoog.pdf',
        en: 'https://www.vvvschiermonnikoog.nl/uploads/bestanden/Waarborgfonds_annuleringen/Waarborgregeling%20_VVVSchiermonnikoog.pdf',
        de: 'https://www.vvvschiermonnikoog.nl/uploads/bestanden/Waarborgfonds_annuleringen/Reiseruecktrittskostenregelung_VVVSchiermonnikoog.pdf',
    },
    checkout: {
        mobileHeaderPlacement: 'bottom',
        initialPaymentMethod: {
            nl: PaymentMethodEnum.Ideal,
            en: PaymentMethodEnum.CreditCard,
            de: PaymentMethodEnum.Paypal,
        },
    },
    complaintFormUrl: {
        nl: 'https://www.vvvschiermonnikoog.nl/klachtenformulier/',
        en: 'https://www.vvvschiermonnikoog.com/complaint-form/',
        de: 'https://www.vvvschiermonnikoog.de/formular-mit-beschwerde/',
    },
    homepageUrl: {
        nl: 'https://www.vvvschiermonnikoog.nl',
        en: 'https://www.vvvschiermonnikoog.com',
        de: 'https://www.vvvschiermonnikoog.de',
    },
    scripts: (
        <script type="text/javascript">
            {`if (navigator.userAgent.indexOf('GoNativeIOS/1.0 gonative') > -1) {
        window.location.href = 'gonative://ios/attconsent/status?callback=cb';

        function cb(result) {
            if (result.granted) {
                (function(w,d,s,l,i){ w[l]=w[l]||[];w[l].push({ 'gtm.start':
                new Date().getTime(),event:'gtm.js' });var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-573RJ8F');
            }
        }
    }`}
        </script>
    ),
    enableFavorites: true,
};

export default schierConfig;
