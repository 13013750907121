import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import ErrorTemplate from '../ErrorTemplate';

const NotFound: FC<React.PropsWithChildren<unknown>> = () => (
    <ErrorTemplate
        title={<FormattedMessage defaultMessage="Oeps! Pagina niet gevonden" />}
        description={<FormattedMessage defaultMessage="We kunnen de pagina die je zoekt helaas niet vinden..." />}
    />
);

export default NotFound;
