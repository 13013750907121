import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import omitDeep from 'omit-deep-lodash';
import { useMemo } from 'react';

export * from '@apollo/client';

/**
 * This file is mostly a re-export of the apollo hooks, with the minor difference that it throws
 * an error if any of the hooks have errors in their results. It also removes the __typename property
 * from mutation variables for developer convenience
 *
 */

export const useQuery = <TData, TVariables = Apollo.OperationVariables>(
    query: DocumentNode,
    options?: Apollo.QueryHookOptions<TData, TVariables>
) => {
    const opts = useMemo(
        () =>
            typeof window === 'undefined'
                ? options
                : {
                      ssr: false, // we no longer use the ssr from apollo!
                      ...options,
                  },
        [options]
    );

    const result = Apollo.useQuery<TData, TVariables>(query, opts);

    if (result.error) {
        throw result.error;
    }

    // https://github.com/apollographql/apollo-client/issues/7038
    result.data = !result.loading ? result.data ?? result.previousData : result.previousData;

    return result;
};

export const useMutation = <TData, TVariables>(
    query: DocumentNode,
    options?: Apollo.MutationHookOptions<TData, TVariables>
): Apollo.MutationTuple<TData, TVariables> => {
    const [mutationFunction, result] = Apollo.useMutation<TData, TVariables>(query, options);

    // we patch the real mutate func with our own, so we can extract the variables from it, if it gets called.
    const patched = (funcOptions?: Apollo.MutationFunctionOptions<TData, TVariables>) =>
        mutationFunction({ ...funcOptions, variables: omitDeep(funcOptions?.variables, ['__typename']) });

    if (result.error && options?.errorPolicy !== 'ignore') {
        result.error.name = 'MutationError';
        throw result.error;
    }

    return [patched, result];
};
