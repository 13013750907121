import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentMethodEnum, SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';
import website from '../themes/website';
import { BrandConfig } from './brandConfig';
import { rentalUnitTypes } from './filterTypes';
import oberonBg from '../../public/static/img/oberonBg.jpeg';
import WhatsApp from '../components/svg/socials/Whatsapp.svg';
import Phone from '../components/svg/socials/Phone.svg';
import Mail from '../components/svg/socials/Mail.svg';
import TranslatedPhoneNumber from '../components/TranslatedPhoneNumber';
import ZoTexel from '../components/svg/ZoTexel.svg';
import BookLocal from '../components/svg/BookLocal.svg';

const oberonConfig: BrandConfig = {
    name: 'Oberon',
    locales: ['nl', 'de', 'en'],
    theme: website,
    gtmID: 'GTM-PNNFPCW',
    initialNewsletterChecked: true,
    font: {
        hrefs: [
            '/static/font/Butler-UltraLight.woff2',
            '/static/font/Butler-UltraLight.woff',
            '/static/font/Butler-Light.woff2',
            '/static/font/Butler-Light.woff',
            '/static/font/Butler.woff2',
            '/static/font/Butler.woff',
            '/static/font/Butler-Medium.woff2',
            '/static/font/Butler-Medium.woff',
            '/static/font/Butler-Bold.woff2',
            '/static/font/Butler-Bold.woff',
            '/static/font/Butler-ExtraBold.woff2',
            '/static/font/Butler-ExtraBold.woff',
            '/static/font/manrope-regular-webfont.woff2',
            '/static/font/manrope-regular-webfont.woff',
            '/static/font/manrope-extrabold-webfont.woff2',
            '/static/font/manrope-extrabold-webfont.woff',
            '/static/font/manrope-light-webfont.woff2',
            '/static/font/manrope-light-webfont.woff',
            '/static/font/manrope-extralight-webfont.woff2',
            '/static/font/manrope-extralight-webfont.woff',
            '/static/font/manrope-bold-webfont.woff2',
            '/static/font/manrope-bold-webfont.woff',
            '/static/font/manrope-medium-webfont.woff2',
            '/static/font/manrope-medium-webfont.woff',
            '/static/font/manrope-semibold-webfont.woff2',
            '/static/font/manrope-semibold-webfont.woff',
        ],
        css: `
        // Butler

        @font-face {
            font-family: 'Butler';
            src: url('/static/font/Butler-UltraLight.woff2') format('woff2'),
                url('/static/font/Butler-UltraLight.woff') format('woff');
            font-weight: 200;
            font-display: swap;

        }

        @font-face {
            font-family: 'Butler';
            src: url('/static/font/Butler-Light.woff2') format('woff2'), url('/static/font/Butler-Light.woff') format('woff');
            font-weight: 300;
            font-display: swap;

        }

        @font-face {
            font-family: 'Butler';
            src: url('/static/font/Butler.woff2') format('woff2'), url('/static/font/Butler.woff') format('woff');
            font-weight: normal;
            font-display: swap;

        }

        @font-face {
            font-family: 'Butler';
            src: url('/static/font/Butler-Medium.woff2') format('woff2'), url('/static/font/Butler-Medium.woff') format('woff');
            font-weight: 500;
            font-display: swap;

        }

        @font-face {
            font-family: 'Butler';
            src: url('/static/font/Butler-Bold.woff2') format('woff2'), url('/static/font/Butler-Bold.woff') format('woff');
            font-weight: 600;
            font-display: swap;

        }

        @font-face {
            font-family: 'Butler';
            src: url('/static/font/Butler-ExtraBold.woff2') format('woff2'), url('/static/font/Butler-ExtraBold.woff') format('woff');
            font-weight: 700;
            font-display: swap;

        }

        @font-face {
            font-family: 'Butler';
            src: url('/static/font/Butler-ExtraBold.woff2') format('woff2'),
                url('/static/font/Butler-ExtraBold.woff') format('woff');
            font-weight: 800;
            font-display: swap;

        }

        // Manrope

        @font-face {
            font-family: 'Manrope';
            src: url('/static/font/manrope-regular-webfont.woff2') format('woff2'), url('/static/font/manrope-regular-webfont.woff') format('woff');
            font-weight: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Manrope';
            src: url('/static/font/manrope-extrabold-webfont.woff2') format('woff2'),
                url('/static/font/manrope-extrabold-webfont.woff') format('woff');
            font-weight: 800;
            font-display: swap;

        }

        @font-face {
            font-family: 'Manrope';
            src: url('/static/font/manrope-light-webfont.woff2') format('woff2'), url('/static/font/manrope-light-webfont.woff') format('woff');
            font-weight: 300;
            font-display: swap;

        }

        @font-face {
            font-family: 'Manrope';
            src: url('/static/font/manrope-extralight-webfont.woff2') format('woff2'),
                url('/static/font/manrope-extralight-webfont.woff') format('woff');
            font-weight: 200;
            font-display: swap;

        }

        @font-face {
            font-family: 'Manrope';
            src: url('/static/font/manrope-bold-webfont.woff2') format('woff2'), url('/static/font/manrope-bold-webfont.woff') format('woff');
            font-weight: 700;
            font-display: swap;
        }

        @font-face {
            font-family: 'Manrope';
            src: url('/static/font/manrope-medium-webfont.woff2') format('woff2'), url('/static/font/manrope-medium-webfont.woff') format('woff');
            font-weight: 500;
            font-display: swap;

        }

        @font-face {
            font-family: 'Manrope';
            src: url('/static/font/manrope-semibold-webfont.woff2') format('woff2'), url('/static/font/manrope-semibold-webfont.woff') format('woff');
            font-weight: 600;
            font-display: swap;

        }
    `,
    },
    termsAndConditions: {
        nl: `https://www.texel.net/nl/over-texel/over-ons/voorwaarden/algemene-boekingsvoorwaarden-van-texel-net/`,
        en: `https://www.texel.net/en/about-texel/about-us/conditions/general-terms-and-conditions/`,
        de: `https://www.texel.net/de/ueber-texel/ueber-uns/bedingungen/allgemeine-reisebedingungen-von-vvv-texel/`,
    },
    locationDescription: {
        imgSrc: '/static/img/waterlandTips.jpg',
        imgAlt: {
            nl: 'Dit is Waterland van Friesland',
            en: 'This is Waterland van Friesland',
            de: 'Das ist Waterland van Friesland',
        },
        description: {
            nl: 'Als VVV kennen we de bijzondere plekjes. Hieronder hebben we enkele tips opgeschreven die je bezoek nog leuker maken. Op zoek naar meer? Laat je dan verrassen door de veelzijdigheid van onze regio via speciale <a href="https://www.waterlandvanfriesland.nl/nl/inspiratie/verhalenroutes"target="_blank">inspiratie verhalenroutes.</a>',
            en: 'At the tourist information centre we know all the special places. We’ve put together a few tips for you to make your visit even more enjoyable. Would you like more? Let the versatility of our region surprise you and set off on one of our very special <a href="https://www.waterlandvanfriesland.nl/en/inspiration/story-trails" target="_blank">inspirational story trails</a>.',
            de: 'Der VVV kennt sich aus in der Region und verrät Ihnen gerne ein paar Geheimtipps, die Ihren Aufenthalt bei uns unvergesslich machen. Lust auf mehr? Lassen Sie sich von der Vielseitigkeit unserer Region überraschen und begeben Sie sich auf eine unserer speziellen <a href="https://www.waterlandvanfriesland.nl/de/inspiration/geschichtenrouten" target="_blank">Inspirations- und Geschichtenrouten.</a>',
        },
        tips: {
            nl: [
                'Zeven van de Elfsteden liggen in onze regio. Maak een <a href="https://www.waterlandvanfriesland.nl/nl/stedentrip" target="_blank">stedentrip</a> vol kunst, cultuur en gezelligheid!',
                'Bezoek het <a href="https://www.waterlandvanfriesland.nl/nl/inspiratie/verhalenroutes/stromen-in-waterland/woudagemaal" target="_blank">UNESCO Werelderfgoed Ir. D.F. Woudagemaal</a> in Lemmer, of één van de andere <a href="https://www.waterlandvanfriesland.nl/nl/plan-je-bezoek/zien-en-doen/kunst-en-cultuur/alle-musea" target="_blank">20 musea</a>.',
                'Ontdek <a href="https://www.waterlandvanfriesland.nl/nl/gaasterland" target="_blank">Gaasterland</a> met glooiende heuvels, bossen en kliffen langs het IJsselmeer. Wist je dat onze regio een <a href="https://www.waterlandvanfriesland.nl/nl/nationaal-landschap" target="_blank">Nationaal Landschap</a> is?',
                'De IJsselmeerkust en onze Friese meren hebben bij elkaar meer dan <a href="https://www.waterlandvanfriesland.nl/nl/plan-je-bezoek/watersport/strand-friesland" target="_blank">25 bijzondere stranden</a>. Ontdek jouw favoriete plekje!',
                'Fietsen en wandelen kan bij ons over water. Ontdek bijzondere <a href="https://www.waterlandvanfriesland.nl/nl/plan-je-bezoek/routes/pontjes-routes" target="_blank">pontjes routes</a> in Zuidwest Friesland.',
            ],
            en: [
                'Seven of the eleven famous Frisian cities can be found in our region. Take a <a href="https://www.waterlandvanfriesland.nl/en/inspiration/story-trails/citytrip-in-waterland" target="_blank">city trip</a> full of art, culture and fun!',
                'Visit the <a href="https://www.waterlandvanfriesland.nl/en/inspiration/story-trails/flows-in-waterland/woudagemaal" target="_blank">UNESCO World Heritage site of the Ir. D.F. Wouda pumping station</a> in Lemmer, or one of the other <a href="https://www.waterlandvanfriesland.nl/en/visit/things-to-see-and-do/culture-and-museums/museums" target="_blank">20 museums</a>.',
                'Discover <a href="https://www.waterlandvanfriesland.nl/en/gaasterland" target="_blank">Gaasterland</a> with its rolling hills, woodlands and cliffs along the IJsselmeer lake. Did you know that our region is a designated <a href="https://www.waterlandvanfriesland.nl/en/inspiration/landscape-of-national-importance" target="_blank">landscape of national importance</a>?',
                'The IJsselmeer coastline and our Frisian lakes offer more than <a href="https://www.waterlandvanfriesland.nl/en/visit/water-sports/beaches" target="_blank">25 unique beaches</a>. Discover your favourite spot!',
                'Water is no obstacle during hikes and cycling rides. Discover very special <a href="https://www.waterlandvanfriesland.nl/en/visit/routes" target="_blank">ferry routes</a> in Southwest Friesland.',
            ],
            de: [
                'Elf Städte haben Friesland berühmt gemacht. Sieben davon liegen in unserer Region. Wie wäre es mit einem <a href="https://www.waterlandvanfriesland.nl/de/inspiration/geschichtenrouten/stadtetrip-in-waterland" target="_blank">Städtetrip</a> mit Kunst, Kultur und Altstadtflair?',
                'Besuchen Sie das <a href="https://www.waterlandvanfriesland.nl/de/inspiration/geschichtenrouten/stroeme-im-waterland/woudapumpwerk" target="_blank"></a>UNESCO-Welterbe Ir. D.F. Wouda-Pumpwerk in Lemmer oder eines der anderen <a href="https://www.waterlandvanfriesland.nl/de/besuchen/sehen-staunen-mitmachen/kultur-und-museen/alle-museen" target="_blank">20 Museen</a>.',
                'Entdecken Sie das <a href="https://www.waterlandvanfriesland.nl/de/gaasterland" target="_blank">Gaasterland</a> mit seinen Hügeln, Wäldern und den berühmten IJsselmeer-Klippen. Wussten Sie schon, dass unsere Region ein <a href="https://www.waterlandvanfriesland.nl/de/landschaftspark" target="_blank">nationaler Landschaftspark</a> ist?',
                'Das IJsselmeer und die friesische Seenplatte bieten mehr als <a href="https://www.waterlandvanfriesland.nl/de/besuchen/wassersport/strande-friesland" target="_blank">25 besondere Strände</a>. Auch Sie finden hier garantiert Ihren Lieblingsplatz!',
                'Wasser ist bei (Rad-)Wanderungen kein Hindernis. Auf vielen Routen in Südwestfriesland stoßen Sie immer wieder auf idyllische <a href="https://www.waterlandvanfriesland.nl/de/besuchen/wassersport/fahren" target="_blank">Fähren</a>.',
            ],
        },
    },
    contact: {
        info: [
            {
                name: <FormattedMessage defaultMessage="Stel je vraag via WhatsApp" />,
                content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                icon: <WhatsApp width={24} height={24} />,
                link: 'https://wa.me/31683357827',
                queryParams: {
                    nl: {
                        text: 'Beste VVV Texel, ik heb een vraag over {rentalUnitName}',
                    },
                    de: {
                        text: 'Sehr geehrter Mitarbeiter des VVV Texel, ich habe eine Frage zu {rentalUnitName}',
                    },
                    en: {
                        text: 'Dear VVV Texel employee, I have a question about {rentalUnitName}',
                    },
                },
            },
            {
                name: (
                    <>
                        <FormattedMessage defaultMessage="Bel" />{' '}
                        <TranslatedPhoneNumber phoneNumber="+31 519 546 546" />
                    </>
                ),
                content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                icon: <Phone width={24} height={24} />,
                link: 'tel:+31519546546',
            },

            {
                name: <FormattedMessage defaultMessage="E-mail" />,
                content: <FormattedMessage defaultMessage="Je krijgt binnen twee werkdagen een reactie." />,
                icon: <Mail width={24} height={24} />,
                link: 'mailto:info@vvvameland.nl',
                queryParams: {
                    nl: {
                        subject: 'Beste VVV Texel, ik heb een vraag over {rentalUnitName}',
                    },
                    de: {
                        subject: 'Sehr geehrter Mitarbeiter des VVV Texel, ich habe eine Frage zu {rentalUnitName}',
                    },
                    en: {
                        subject: 'Dear VVV Texel employee, I have a question about {rentalUnitName}',
                    },
                },
            },
        ],
        page: {
            nl: 'https://www.oberon.nl/contact/',
            de: 'https://www.oberon.nl/contact/',
            en: 'https://www.oberon.nl/contact/',
        },
    },

    indexImage: oberonBg,
    accommodationTypes: [
        {
            message: 'all',
            value: 'all',
        },
        {
            message: 'vacationHomes',
            value: rentalUnitTypes.HOME,
        },
        {
            message: 'caravan',
            value: rentalUnitTypes.CAMPER_SPOT,
        },
        {
            message: 'hotelOrBnB',
            value: rentalUnitTypes.ROOM,
        },
        {
            message: 'groupAccommodations',
            value: rentalUnitTypes.BED,
        },
        {
            message: 'camping',
            value: `${rentalUnitTypes.CAMPING_PITCH} ${rentalUnitTypes.CAMPER_SPOT} ${rentalUnitTypes.TENT}`,
        },
        {
            message: 'boat',
            value: `${rentalUnitTypes.BOAT}`,
        },
    ],
    groupTypes: ['adults', 'youth', 'children', 'babies', 'pets'],
    tripsSearchOrder: {
        popularity: UnitSearchOrderEnum.RandomBoosted,
        ratingDesc: UnitSearchOrderEnum.ReviewRatingDesc,
        priceAsc: UnitSearchOrderEnum.PriceAsc,
        priceDesc: UnitSearchOrderEnum.PriceDesc,
    },

    unitSearchOrder: {
        popularity: SearchRentalUnitsOrderEnum.RandomBoosted,
        ratingDesc: SearchRentalUnitsOrderEnum.ReviewRatingDesc,
    },
    defaultTripSearchOrderByRentalUnitType: {
        [rentalUnitTypes.ROOM]: UnitSearchOrderEnum.PriceAsc,
    },

    // Map zoom level
    mapZoom: 10.5,

    // Map min zoom level
    mapMinZoom: {
        desktop: 10.5,
        mobile: 8.5,
    },

    coordinates: {
        latitude: 52.3892,
        longitude: 4.8906,
    },

    seo: {
        twitterUsername: '@oberon',
        siteName: 'Oberon',
    },
    minBookingAge: 18,
    cancellationInsuranceDefault: true,
    complaintFormUrl: {
        nl: 'https://www.oberon.nl/contact/',
        en: 'https://www.oberon.nl/contact/',
        de: 'https://www.oberon.nl/contact/',
    },
    surveyUrl: {
        nl: 'https://nl.surveymonkey.com/r/3N9Y3DR',
        en: 'https://nl.surveymonkey.com/r/PC6SXR6',
        de: 'https://de.surveymonkey.com/r/GKKCYW7',
    },
    checkout: {
        initialPaymentMethod: {
            nl: PaymentMethodEnum.Ideal,
            en: PaymentMethodEnum.CreditCard,
            de: PaymentMethodEnum.Paypal,
        },
        showBookingConfirmExtras: true,
    },
    badge: {
        icon: <ZoTexel />,
        handle: 'general.zotexels',
    },
    badge2: {
        icon: <BookLocal />,
        handle: 'general.book-local',
    },

    enableFavorites: true,
};

export default oberonConfig;
