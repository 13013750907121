import { Box } from '@rebass/grid';
import styled, { css } from 'styled-components';

export const wrapperPadding = css`
    --min-padding: 22;
    --max-padding: 96;
    --min-viewport: 320;
    --max-viewport: 1600;
    --wrapperPadding: min(
        9.6rem,
        calc(
            var(--min-padding) * 1px + (var(--max-padding) - var(--min-padding)) * (100vw - (var(--min-viewport) * 1px)) /
                (var(--max-viewport) - var(--min-viewport))
        )
    );
    /* can't use calc in a min(), so: */
    --wrapperSpaceCalc: calc(var(--wrapperPadding) + (100vw - var(--max-viewport) * 1px) / 2);
    --wrapperSpaceLeft: max(var(--wrapperPadding), var(--wrapperSpaceCalc));

    text-align: left;
`;

const Page = styled(Box)`
    ${wrapperPadding}
`;

export default Page;
