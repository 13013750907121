import { ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import '@oberoninternal/travelbase-ds/dsStyles.css';
import { AppProps } from 'next/app';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import TorApp from '../components/App';
import ErrorBoundary from '../components/ErrorBoundary';
import { BrandConfig } from '../constants/brandConfig';
import TenantContext from '../context/TenantContext';
import { APOLLO_STATE_PROP_NAME, initApolloClient } from '../createApolloClient';
import { Locale } from '../entities/Locale';
import NotFoundError from '../entities/NotFoundError';
import sentry from '../utils/sentry';

const { Sentry } = sentry();

Sentry.init();

/**
 * This is the internal _app that should only be used when tor3-website is being run in standalone mode.
 * Do NOT import this file when using tor as a module.
 * It expects webpack to process this file which doesn't happen in module mode.
 */

const requireMessage = require.context('../lang/compiled/', false, /\.json$/);

export const getMessages = (locale: string) => {
    let languageMessages = requireMessage(`./${locale}.json`);
    const brandMessages = requireMessage(`./${locale}.${process.env.NEXT_PUBLIC_BRAND}.json`) ?? {};

    if (locale === 'nl') {
        const baseMessages = requireMessage(`./base.json`);
        languageMessages = { ...baseMessages, ...languageMessages };
    }
    return {
        ...languageMessages,
        ...brandMessages,
    };
};

const langRegex = /^\.\/([a-z]+)\.json$/;

// Get the supported languages by looking for translations in the `lang/` dir.
export const supportedLanguages = requireMessage
    .keys()
    .map(file => langRegex.exec(file)?.[1])
    .filter((lang): lang is Locale => !!lang && lang !== 'base');

const brand = process.env.NEXT_PUBLIC_BRAND ?? 'ameland';

const requireContext = require.context('../constants', false, /brandConfig.?([a-z]{2,})?\.([tj])sx?/);
export const brandConfig: BrandConfig = requireContext(`./brandConfig.${brand}.tsx`).default;

interface PageProps {
    __APOLLO_STATE__: NormalizedCacheObject;
}

const App: FC<React.PropsWithChildren<AppProps<PageProps>>> = props => {
    const { router, pageProps, Component } = props;
    const routerLocale = router.locale;
    const languageExists = supportedLanguages.some(code => code === routerLocale);

    if (router.locale && router.locale !== 'default' && !languageExists) {
        throw new NotFoundError();
    }

    const locale: Locale = languageExists ? (routerLocale as Locale) : 'nl';
    const messages = useMemo(() => getMessages(locale), [locale]);
    const tenantContext = useMemo(
        () => ({
            locale,
            messages,
            supportedLanguages,
            brandConfig,
        }),
        [locale, messages]
    );
    const client = initApolloClient(locale, pageProps[APOLLO_STATE_PROP_NAME]);
    if ((routerLocale as string) === 'default') {
        return <></>;
    }
    return (
        <ThemeProvider theme={brandConfig.theme}>
            <TenantContext.Provider value={tenantContext}>
                <ApolloProvider client={client}>
                    <TorApp {...props}>
                        <ErrorBoundary>
                            <Component {...pageProps} />
                        </ErrorBoundary>
                    </TorApp>
                </ApolloProvider>
            </TenantContext.Provider>
        </ThemeProvider>
    );
};

export default App;
