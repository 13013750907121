import ErrorMessage from '@oberoninternal/travelbase-ds/components/form/ErrorMessage';
import Body, { BodyInner } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Box } from '@rebass/grid';
import { gql } from '@apollo/client';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { OrderSummaryItemFragment } from '../generated/graphql';
import UnitThumbnail from './designsystem/UnitThumbnail';
import Loading from './Loading';
import Stack from './Stack';

export const fragment = gql`
    fragment OrderSummaryItem on OrderItem {
        ...OrderSummaryItemBooking
        ...OrderSummaryItemTicket
        ...OrderSummaryItemUpsellPurchase
        ...CartOrderItem
    }
`;

interface Props {
    item?: OrderSummaryItemFragment;
    updating?: boolean;
    thumbnail?: ReactNode;
}

const SummaryBarItem: FC<React.PropsWithChildren<Props>> = ({ updating, item, thumbnail, children }) => (
    <Box>
        {thumbnail && thumbnail}
        {updating && <Loading mt={4} spacing={3} />}
        {!updating && (
            <Stack mt={4} spacing={4}>
                {children}
                {!!item?.errors?.length && item.errors.length > 0 && (
                    <Box my={3}>
                        {item.errors.map((error, i) => (
                            <ErrorMessage key={i}>{error}</ErrorMessage>
                        ))}
                    </Box>
                )}
            </Stack>
        )}
    </Box>
);

export default SummaryBarItem;

export const SummaryBarItemThumbnail = styled(UnitThumbnail)<{ isClickable?: boolean }>`
    width: 100%;
    ${props =>
        props.isClickable &&
        `
            cursor: pointer;
            @media screen and (min-width: ${props.theme.mediaQueries.m}) {
                position: relative;
                :hover {
                    ::before {
                        content: '';
                        position: absolute;
                        width: 0.8rem;
                        height: 0.8rem;
                        background: ${props.theme.colors.secondary['40']};
                        left: -2.4rem;
                        top: calc(50% - 0.4rem);
                        border-radius: 50%;
                    }
                }
            }
            `}
`;

export const SummaryBarItemField: FC<React.PropsWithChildren<{ title: ReactNode }>> = ({ title, children }) => (
    <Box>
        <Body variant="tiny">{title}</Body>
        <Content>{children}</Content>
    </Box>
);

const Content = styled(Box)`
    ${BodyInner} {
        font-weight: 300;
    }
`;

export const SummaryBarItemBody = styled(Body).attrs({ variant: 'small' })`
    text-transform: capitalize;
    margin: 0;
    color: ${({ theme }) => theme.colors.secondary[100]};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;
