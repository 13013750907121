import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Flex } from '@rebass/grid';
import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import getUnitTypeConsts from '../constants/getUnitTypeConsts';
import { OrderSummaryItem_Booking_Fragment, PriceLineCategoryEnum } from '../generated/graphql';
import createPlaceholder from '../utils/createPlaceholder';
import getTripGroupText from '../utils/getTripGroupText';
import Stack from './Stack';
import SummaryBarItem, { SummaryBarItemBody, SummaryBarItemField, SummaryBarItemThumbnail } from './SummaryBarItem';
import useGetPageSlugByLocale from '../hooks/useGetPageSlugByLocale';

export const fragment = gql`
    fragment OrderSummaryItemBooking on Booking {
        rentalUnit {
            id
            code
            name
            slug
            maxOccupancy
            minOccupancy
            petsAllowed
            accommodation {
                id
                hasPublicPage
                name
                checkInEndTime
                checkInStartTime
                checkOutTime
                type
            }
            type
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
        }
    }
`;

interface Props {
    item: OrderSummaryItem_Booking_Fragment;
    updating?: boolean;
    hidePrices?: boolean;
    /** This prop is used to determine if the item click should lead to unit page with the exact trip details (arrivaldate etc.) */
    exactLink?: boolean;
    isClickable?: boolean;
}

const SummaryBarItemBooking: FC<React.PropsWithChildren<Props>> = ({
    item,
    exactLink = false,
    updating,
    hidePrices,
    isClickable = true,
}) => {
    const intl = useIntl();
    const { getPageSlugByLocale } = useGetPageSlugByLocale();

    const accommodationSlug = getPageSlugByLocale('ACCOMMODATION');
    const { push } = useRouter();
    const {
        rentalUnit,
        amountAdults,
        amountBabies,
        amountChildren,
        amountYouths,
        amountPets,
        arrivalDate,
        departureDate,
    } = item;

    if (!rentalUnit) {
        return null;
    }

    const {
        name,
        code,
        slug,
        accommodation: { hasPublicPage, name: accommodationName, checkInEndTime, checkInStartTime, checkOutTime },
        type,
        jpegThumbnail,
        webpThumbnail,
    } = rentalUnit;

    const unitType = getUnitTypeConsts(intl)[type].translation;

    const guestsText = getTripGroupText(
        intl,
        {
            amountAdults,
            amountBabies,
            amountChildren,
            amountYouths,
            amountPets,
        },
        true
    );

    return (
        <SummaryBarItem
            updating={updating}
            item={item}
            thumbnail={
                <SummaryBarItemThumbnail
                    isClickable={isClickable}
                    onClick={() => {
                        if (isClickable) {
                            push('/[accommodation]/[slug]', {
                                pathname: `/${accommodationSlug}/${slug}`,
                                query: exactLink
                                    ? {
                                          arrivalDate,
                                          departureDate,
                                          amountAdults,
                                          amountBabies,
                                          amountChildren,
                                          amountYouths,
                                          amountPets,
                                      }
                                    : undefined,
                            });
                        }
                    }}
                    title={name}
                    thumbnail={{
                        sources: [
                            {
                                srcSet: webpThumbnail.transform?.srcSet,
                                type: 'image/webp',
                            },
                            {
                                srcSet: jpegThumbnail.transform?.srcSet,
                                type: 'image/jpeg',
                            },
                        ],
                        fallback: {
                            src: jpegThumbnail.transform?.src,
                        },
                        placeholder: createPlaceholder(webpThumbnail.transform?.placeholder),
                    }}
                    strapline={(hasPublicPage && accommodationName) || undefined}
                    subTitle={
                        <SummaryBarItemBody style={{ textTransform: 'capitalize' }} title={unitType}>
                            {unitType}
                        </SummaryBarItemBody>
                    }
                />
            }
        >
            <SummaryBarItemField title={<FormattedMessage defaultMessage="# Accommodatie" />}>
                <Body variant="small">{code}</Body>
            </SummaryBarItemField>
            <SummaryBarItemField title={<FormattedMessage defaultMessage="Reisgezelschap" />}>
                <Body variant="small">{guestsText}</Body>
            </SummaryBarItemField>
            <SummaryBarItemField title={<FormattedMessage defaultMessage="Aankomst" />}>
                <Body variant="small">
                    <FormattedDate value={new Date(arrivalDate)} format="DATERANGEPICKER" />{' '}
                    {checkInStartTime === checkInEndTime ? (
                        checkInStartTime
                    ) : (
                        <>
                            <FormattedMessage defaultMessage="tussen" /> {checkInStartTime} {' - '}
                            {checkInEndTime}
                        </>
                    )}{' '}
                    <FormattedMessage defaultMessage="uur" />
                </Body>
            </SummaryBarItemField>
            <SummaryBarItemField title={<FormattedMessage defaultMessage="Vertrek" />}>
                <Body variant="small">
                    <FormattedDate value={new Date(departureDate)} format="DATERANGEPICKER" />{' '}
                    <FormattedMessage defaultMessage="voor" /> {`${checkOutTime}`}{' '}
                </Body>
            </SummaryBarItemField>
            {!hidePrices && (
                <SummaryBarItemField title={<FormattedMessage defaultMessage="Prijsopbouw" />}>
                    <Stack spacing={3}>
                        {item.priceLines
                            .filter(line => line.category !== PriceLineCategoryEnum.NotInTotal)
                            .map(line => (
                                <Flex key={line.label} justifyContent="space-between">
                                    <Body variant="small">{line.label}</Body>
                                    <Body variant="small">
                                        <FormattedNumber value={line.totalPrice} format="EUR" />
                                    </Body>
                                </Flex>
                            ))}
                    </Stack>
                </SummaryBarItemField>
            )}
        </SummaryBarItem>
    );
};

export default SummaryBarItemBooking;
