const setItemWithExpiry =
    (storage: Storage) =>
    (keyName: string, value: string, expiryInDays = 7) => {
        storage.setItem(keyName, value);
        storage.setItem(`${keyName}_expiry`, ((+new Date()).valueOf() + 1000 * 60 * 60 * 24 * expiryInDays).toString());
    };

const getItemWithExpiry = (storage: Storage) => (keyName: string) => {
    const expiry = storage.getItem(`${keyName}_expiry`);

    // non-existent or expired?
    if (expiry === null || Number(expiry) < new Date().valueOf()) {
        return null;
    }

    return storage.getItem(keyName);
};

const removeItemWithExpiry = (storage: Storage) => (keyName: string) => {
    storage.removeItem(keyName);
    storage.removeItem(`${keyName}_expiry`);
};

const variantStorageMap = { local: 'localStorage', session: 'sessionStorage' } as const;

const onBrowser = typeof window !== 'undefined';

const getStorageWithExpiry = (variant: 'local' | 'session') => {
    try {
        const type = variantStorageMap[variant];

        if (!onBrowser || typeof window?.[type] === 'undefined') {
            return null;
        }

        const storage = window[type];

        return {
            setItem: setItemWithExpiry(storage),
            getItem: getItemWithExpiry(storage),
            removeItem: removeItemWithExpiry(storage),
        };
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Unable to use ${variantStorageMap[variant]}`);
    }
    return null;
};

export default getStorageWithExpiry;
