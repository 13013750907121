import { ButtonVariant } from '@oberoninternal/travelbase-ds/components/action/Button';
import { Theme } from '@oberoninternal/travelbase-ds/constants/theme';
import { ComponentType, ReactNode } from 'react';
import { Transform } from 'react-html-parser';
import { Locale, Localizable } from '../entities/Locale';
import { PaymentMethodEnum, SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';
import accommodationMessages from './accommodationMessages';
import { StaticImageData } from 'next/image';
import { SearchBarValues } from '../components/SearchBox';

export type SearchBarInteractables = 'tripgroup' | 'datepicker' | 'accommodationType';
export type GroupType = 'adults' | 'youth' | 'children' | 'babies' | 'pets';
export type ContactInfo = {
    name: ReactNode;
    content: ReactNode;
    icon: ReactNode;
    link: string;
    queryParams?: Localizable<Record<string, string>>;
};

export type LocationDescriptionByBrand = {
    texel?: LocationDescription;
    terschelling?: LocationDescription;
    ameland?: LocationDescription;
    schier?: LocationDescription;
    waterland?: LocationDescription;
};

export type ContactInfoByBrand = {
    texel?: ContactInfo[];
    terschelling?: ContactInfo[];
    ameland?: ContactInfo[];
    schier?: ContactInfo[];
    waterland?: ContactInfo[];
};

export type LocationDescription = {
    useNativeLocationDescription?: boolean;
    locationDescriptionByBrand?: LocationDescriptionByBrand;
    description?: Localizable<string>;
    tips?: Localizable<string[]>;
    imgSrc?: string;
    imgAlt?: Localizable<string>;
};
export interface BrandConfig {
    name: string;

    forceAffiliate?: string;

    // optional alternative URI that should be linked to instead of `/search`
    searchUri?: Localizable<string>;

    // list of enabled locales
    locales: Locale[];

    // template urls are URL's that exist on the main site for the purpose of
    // giving the app a menu and a footer to wrap itself in, as the app does not come with these by default.
    template?: {
        urls: Localizable<string>;
        containerId: string;
        /**
         * normally the whole container gets replaced with our <Main/> component but in some cases we'd like to have both (Waterland)
         */
        getCustomMainRenderer?: () => Promise<(transformArgs: Parameters<Transform>) => JSX.Element | null>;
        hrefParser?: (href: string) => string;
        onRouteChange?: (href?: string) => void;
        /**
         * Some template's contain the same GTM id as the one in the config (`brandConfig.gtmId`). Thus in order to avoid having them twice on the page this setting exists. When the template is included on a page the GTM code won't be rendered and when on an iframe page like the searchbox or the planner it does get included.
         */
        disableGTMWhenActive?: boolean;
    };
    searchBox?: {
        // a stylesheet to be included on the iframe search box page /searchbox
        styleSheetUrl?: string;
        hideScrollbar?: boolean;
        hasBrandSearch?: boolean;
    };
    planner?: {
        /**
         * a stylesheet to be included on the iframe planner page /[slug]/planner
         */
        styleSheetUrl?: string;
    };

    searchBar?: {
        hide?: boolean;
        active?: SearchBarInteractables[];
    };

    theme: Theme;
    // optional template specific styling if there is any conflicting styling
    globalStyling?: string;
    // If provided, we will load google tag manager with this ID.
    // NOTE: we will not support any other tracking tags to TOR, ever.
    // All tracking tags should be added through GTM.
    gtmID?: string | Localizable<string>;

    // when used, all searches will include this filter property by default.
    // additionally, any rentalUnits that don't match this property will be hidden from search engines (noindex).
    bakedInFilterProperty?: string;

    // Custom fonts
    font?: {
        css?: string;
        hrefs?: string[];
    };
    customSlugs?: {
        agenda?: Localizable<string>;
        activity?: Localizable<string>;
        company?: Localizable<string>;
        accommodation?: Localizable<string>;
    };
    locationDescription?: LocationDescription;

    contact: {
        useNativeContactInfo?: boolean;
        contactInfoByBrand?: ContactInfoByBrand;
        info?: ContactInfo[];
        page?: Localizable<string>;
    };
    termsAndConditions?: Localizable<string>;
    cancellationInsurance?: Localizable<string>;

    indexImage?: StaticImageData;

    accommodationTypes: Array<{
        message: keyof typeof accommodationMessages;
        value: string;
    }>;
    groupTypes: GroupType[];

    unitSearchOrder: Record<string, SearchRentalUnitsOrderEnum>;
    tripsSearchOrder: Record<string, UnitSearchOrderEnum>;

    hidePlaceInSearchTile?: boolean;
    showBrandInSearchTile?: boolean;
    defaultTripSearchOrderByRentalUnitType?: Partial<Record<SearchBarValues['accommodationType'], UnitSearchOrderEnum>>;
    mapZoom?: number;
    mapMinZoom?: {
        desktop: number;
        mobile: number;
    };

    // coordinates of the center of the island
    coordinates: {
        latitude: number;
        longitude: number;
    };

    seo: {
        twitterUsername?: string;
        siteName: string;
        homeTitle?: Record<Locale, string>;
        siteDescription?: Record<Locale, string>;
    };

    minBookingAge: number;

    cancellationInsuranceDefault: boolean;
    disableNewsLetter?: boolean;
    initialNewsletterChecked?: boolean;
    customContent?: {
        LowSearchListing?: ComponentType<React.PropsWithChildren<unknown>>;
        Index?: ComponentType<React.PropsWithChildren<unknown>>;
        Navigation?: ComponentType<React.PropsWithChildren<unknown>>;
        Footer?: ComponentType<React.PropsWithChildren<unknown>>;
        UnitInfo?: ComponentType<React.PropsWithChildren<unknown>>;
    };
    checkout?: {
        /**
         * defaults to top
         */
        mobileHeaderPlacement?: 'bottom' | 'top';
        /**
         * default value for payment method in the checkout
         */
        initialPaymentMethod?: Localizable<PaymentMethodEnum>;
        /**
         * insurance terms url that complements the insurance texts
         */
        insuranceTerms?: Localizable<string>;
        /**
         * shows extra booking text
         */
        showBookingConfirmExtras?: boolean;
        /**
         * redirects the checkout to the right travelbase instances based on the brand of a rental unit
         */
        checkoutShouldRedirect?: boolean;
        /**
         * shows trusted shops card
         */
        showTrustedShopsCard?: boolean;
    };
    complaintFormUrl: Localizable<string>;
    surveyUrl?: Localizable<string>;
    bookButtonVariant?: ButtonVariant;
    unitExras?: {
        svgFilter?: string;
    };
    homepageUrl?: Localizable<string>;
    lastMinute?: {
        defaultAccommodationType?: string;
    };
    navigation?: {
        /**
         * offset value for the scroll when clicking on a navigation item
         */
        offset?: number;
    };
    scripts?: ReactNode;
    usps?: {
        description: JSX.Element;
        title: JSX.Element;
    }[];
    showUsps?: boolean;
    passport?: {
        imgSrc: string;
    };
    badge?: {
        icon: ReactNode;
        handle: string;
    };
    badge2?: {
        icon: ReactNode;
        handle: string;
    };
    placeFilter?: {
        show?: boolean;
        hasPriority?: boolean;
        type?: 'OR' | 'AND';
    };
    searchBoxGroup?: {
        defaultOccupancy?: number;
        maxOccupancy?: number;
        minOccupancy?: number;
    };

    // enable users to favorite acco's/activities/etc. disabled by default.
    enableFavorites?: boolean;
}

export type SearchOrderKey = keyof BrandConfig['tripsSearchOrder'];
