import React from 'react';
import Euro from '../components/svg/Euro.svg';
import EuroTag from '../components/svg/EuroTag.svg';
import Local from '../components/svg/Local.svg';
import Passport from '../components/svg/Passport.svg';
import Shield from '../components/svg/Shield.svg';
import Goodiebag from '../components/svg/Goodiebag.svg';
import Arrangement from '../components/svg/Arrangement.svg';
import Microphone from '../components/svg/Microphone.svg';

export const uspIcons = {
    euro: <Euro />,
    tag: <EuroTag />,
    passport: <Passport />,
    shield: <Shield />,
    local: <Local />,
    goodiebag: <Goodiebag />,
    arrangement: <Arrangement />,
    microphone: <Microphone />,
};
