import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Link from 'next/link';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import ContentWrapper from '../ContentWrapper';
import Cell from '../designsystem/Cell';
import Grid from '../designsystem/Grid';
import Heading from '../designsystem/Heading';
import Page from '../Page';
import { CheckoutPageGradient } from '../CheckoutPage';

const CheckoutEmpty: FC<React.PropsWithChildren<unknown>> = () => (
    <>
        <Page>
            <CheckoutPageGradient />
            <ContentWrapper variant="checkout">
                <Grid>
                    <Cell
                        gridColumn={['1 / -1', null, null, '1 / 7', null, '1 / 8']}
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Heading variant="large">
                            <Title variant="large">
                                <FormattedMessage defaultMessage="Geen boeking gevonden!" />
                            </Title>
                        </Heading>
                        <Body>
                            <FormattedMessage defaultMessage="Er is geen boeking gevonden in uw winkelwagen. Ga naar de homepage en selecteer een accommodatie die u wilt boeken!" />
                        </Body>
                        <Link href="/" legacyBehavior>
                            <Button as="a" style={{ marginTop: '5.6rem', alignSelf: 'flex-start', cursor: 'pointer' }}>
                                <FormattedMessage defaultMessage="Terug naar de homepagina" />
                            </Button>
                        </Link>
                    </Cell>
                    <Cell
                        className="gt-m"
                        order={[1, null, null, 2]}
                        gridColumn={['1 / -1', null, null, '8 / -1', null, '10 / -1']}
                    >
                        <Body variant="small">
                            <FormattedMessage defaultMessage="Geen accommodaties of activiteiten." />
                        </Body>
                    </Cell>
                </Grid>
            </ContentWrapper>
        </Page>
    </>
);

export default CheckoutEmpty;
