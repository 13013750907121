import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import NotFound from '../pages/404';
import ErrorTemplate from './ErrorTemplate';
import CheckoutEmpty from './pages/CheckoutEmpty';

const ErrorFallback = (props: FallbackProps) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    if (props.error) {
        switch (props.error.name) {
            case 'InvalidCheckoutError':
                return <CheckoutEmpty />;
            case 'NotFoundError':
                return <NotFound />;
            default:
                break;
        }
    }

    return (
        <ErrorTemplate
            title={<FormattedMessage defaultMessage="Oeps! {statusCode}" values={{ statusCode: '' }} />}
            description={
                <FormattedMessage defaultMessage="Bij het opvragen van deze pagina is er iets misgegaan. Probeer het later opnieuw." />
            }
        >
            <Button
                onClick={props.resetErrorBoundary}
                style={{ marginTop: '5.6rem', alignSelf: 'flex-start', cursor: 'pointer' }}
            >
                <FormattedMessage defaultMessage="Probeer het opnieuw" />
            </Button>
        </ErrorTemplate>
    );
};

export default ErrorFallback;
