import { useRouter } from 'next/router';
import { defineMessages, useIntl } from 'react-intl';
import { useCheckoutState, variantMap } from '../context/checkout';

export type CheckoutPageRoute = 'CheckoutExtras' | 'CheckoutDetails' | 'CheckoutConfirm' | CheckoutPageRoutePayment;

export type CheckoutPageRoutePayment = 'CheckoutPayment' | 'CheckoutWithoutPayment' | 'CheckoutRequest';

export type CheckoutPage = { route: CheckoutPageRoute; suffix: string; title: string };
export type ValidatableCheckoutPageRoute = Exclude<CheckoutPageRoute, 'CheckoutConfirm' | 'CheckoutExtras'>;

export const getSuffixForRoute = (route: CheckoutPageRoute) => {
    switch (route) {
        case 'CheckoutExtras':
            return 'checkout/extras';
        case 'CheckoutDetails':
            return 'checkout/details';
        case 'CheckoutPayment':
            return 'checkout/payment';
        case 'CheckoutConfirm':
            return 'checkout/confirm';
        default:
            return '';
    }
};

function assertIsCheckoutRoute<T extends Array<{ suffix: string; title: string }>>(
    pages: T,
    activeRoute: string
): asserts activeRoute is typeof pages[number]['suffix'] {
    const isCheckoutPage = !!pages.find(({ suffix }) => activeRoute.endsWith(suffix));
    if (!isCheckoutPage) {
        throw new Error('useCheckoutPages must be used within the specified routes');
    }
}

const getActive = <T extends Array<{ suffix: string; route: string; title: string }>>(
    pages: T,
    activeRoute: string
) => {
    assertIsCheckoutRoute(pages, activeRoute);
    const activePage: T[number] | undefined = pages.find(({ suffix }) => activeRoute.endsWith(suffix));
    const activeStep = pages.findIndex(({ suffix }) => activeRoute.endsWith(suffix));
    if (!activePage) {
        throw new Error("There isn't any checkout page active ");
    }
    return { activePage, activeStep };
};

const pageTranslations = defineMessages<CheckoutPageRoute>({
    CheckoutExtras: { defaultMessage: `Boeking` },
    CheckoutDetails: { defaultMessage: `Gegevens` },
    CheckoutPayment: { defaultMessage: `Betaling` },
    CheckoutWithoutPayment: { defaultMessage: `Samenvatting` },
    CheckoutRequest: { defaultMessage: `Aanvragen` },
    CheckoutConfirm: { defaultMessage: `Bevestiging` },
});

// TODO: this needs to be improved and should be derived from the Cart entirely
const useCheckoutPages = (checkoutType?: CheckoutPageRoute, onlyTicketsInBasket?: boolean): CheckoutPage[] => {
    const { variant } = useCheckoutState();
    const { formatMessage } = useIntl();
    return variantMap[variant].map(route => {
        if (checkoutType && route === 'CheckoutPayment') {
            return { route, suffix: getSuffixForRoute(route), title: formatMessage(pageTranslations[checkoutType]) };
        }

        // bleghhh
        if (route === 'CheckoutExtras' && onlyTicketsInBasket) {
            return { route, suffix: getSuffixForRoute(route), title: formatMessage({ defaultMessage: 'Overzicht' }) };
        }
        return { route, suffix: getSuffixForRoute(route), title: formatMessage(pageTranslations[route]) };
    });
};

const useCheckoutPageMeta = () => {
    const { pathname } = useRouter();
    const activeRoute = pathname.replace('/', '');
    const pages = useCheckoutPages();
    const { activePage, activeStep } = getActive(pages, activeRoute);
    const nextPage = pages[activeStep + 1] as CheckoutPage | undefined; // It could also be undefined when there's no next page
    return { activePage, activeStep, nextPage };
};

export { useCheckoutPages, useCheckoutPageMeta };
