import websiteTheme from './website';
import { FontStyle, Theme, Colors } from '@oberoninternal/travelbase-ds/constants/theme';
import { BodyVariant } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';

/**
 * The Ameland Voorkant theme.
 * The Ameland theme for the booking site.
 */

const primary = {
    0: '#FFE3FF',
    5: '#FFCCFD',
    10: '#FFB5F5',
    20: '#FE9DEA',
    30: '#FE87DA',
    40: '#FE5DB7',
    50: '#FF3C95',
    60: '#FE2279',
    70: '#F20C5F',
    80: '#BF0449',
    90: '#640127',
    100: '#120007',
};

const colors: Colors = {
    primary,
    tertiary: primary,
    secondary: {
        0: '#DFF6FC',
        5: '#DFF6FC',
        10: '#BFEAF9',
        20: '#A1DDF2',
        30: '#83CEEE',
        40: '#53AFDE',
        50: '#2E91CA',
        60: '#1675AC',
        70: '#095787',
        80: '#03395A',
        90: '#001B2C',
        100: '#001B2C',
    },
    neutral: {
        0: '#ffffff',
        5: '#fbfcfc',
        10: '#f7f8f9',
        20: '#e6e9eb',
        30: '#cbd0d4',
        40: '#a4acb2',
        50: '#767f87',
        60: '#48515b',
        70: '#212a36',
        80: '#060e1c',
        90: '#00040d',
        100: '#000208',
    },
    positive: {
        0: '#faffeb',
        5: '#e2f1b7',
        10: '#c9e389',
        20: '#b1d65e',
        30: '#99c838',
        40: '#77b01b',
        50: '#569804',
        60: '#3f8000',
        70: '#2d6900',
        80: '#1e5100',
        90: '#123900',
        100: '#082100',
    },
    warning: {
        0: '#fff5eb',
        5: '#f9d9b8',
        10: '#f2bd88',
        20: '#eca15a',
        30: '#e5862e',
        40: '#c96b11',
        50: '#ad5500',
        60: '#914800',
        70: '#753b00',
        80: '#592d00',
        90: '#3d1f00',
        100: '#211100',
    },
    negative: {
        0: '#ffebf3',
        5: '#fcbcd6',
        10: '#fa8fb9',
        20: '#f7629b',
        30: '#f5377d',
        40: '#f20c5f',
        50: '#cf004c',
        60: '#ac003f',
        70: '#8a0033',
        80: '#670027',
        90: '#44001a',
        100: '#21000d',
    },
};

const baseTitle: Partial<FontStyle> = {
    color: colors.secondary[80],
    weight: 600,
};

const titleStyles: Record<TitleVariant, FontStyle> = {
    large: {
        size: { min: 19, max: 26 },
        line: { min: 24, max: 28 },
        letterSpacing: 0,
        ...baseTitle,
    },
    regular: {
        size: { min: 19, max: 23 },
        line: { min: 24, max: 28 },
        letterSpacing: 0,
        ...baseTitle,
    },
    small: {
        size: { min: 16, max: 19 },
        line: { min: 24, max: 24 },
        ...baseTitle,
    },
    tiny: {
        size: { min: 16, max: 16 },
        line: { min: 20, max: 20 },
        ...baseTitle,
    },
    micro: {
        size: { min: 15, max: 15 },
        line: { min: 16, max: 16 },
        ...baseTitle,
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 17, max: 20 },
        line: { min: 28, max: 34 },
        weight: 400,
    },
    large: {
        size: { min: 15, max: 19 },
        line: { min: 20, max: 32 },
        letterSpacing: 0,
        weight: 400,
    },
    regular: {
        size: { min: 15, max: 19 },
        line: { min: 20, max: 32 },
        letterSpacing: 0,
        weight: 400,
    },
    small: {
        size: { min: 13, max: 14 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 500,
    },
    tiny: {
        size: { min: 12, max: 13 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
    micro: {
        size: { min: 12, max: 12 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
};

const ameland: Theme = {
    ...websiteTheme,
    name: 'Ameland',
    fontFamily: {
        title: 'Montserrat, sans-serif',
        body: 'Montserrat, sans-serif',
    },
    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    colors,
    colorAssignments: {
        main: ['primary', 60],
        buttonPrimary: ['primary', 60],
        buttonLight: ['primary', 30],
        textAction: ['primary', 40],
        input: ['secondary', 50],
        form: ['secondary', 50],
        rating: ['secondary', 40],
        booking: ['secondary', 50],
        special: ['primary', 40],
        calendar: websiteTheme.colorAssignments.calendar,
    },
    radius: {
        button: '0',
        textArea: '0.5rem',
        textInput: '0.5rem',
        dropdown: '0.5rem',
    },
};

export default ameland;
