/*
 These class names are used to provide a means for style overrides by a third party stylesheet.
 Consider this an overview of stylable elements and a way to ensure these class names don't accidentally change or get
 deleted since they have external stylesheets relying on them. This should not replace theming but should provide more
 detailed control over the styles of some elements, mainly the search box iframe version that gets integrated into
 external websites.
 */

export const CLASS_SEARCH_BOX_BUTTON_CONTAINER = 'ext-search-box__button-container';
export const CLASS_SEARCH_BOX_CONTAINER = 'ext-search-box__container';
export const CLASS_SEARCH_BOX_CONTAINER_MOBILE = 'ext-search-box__container-mobile';
export const CLASS_SEARCH_BOX_FIELDSET = 'ext-search-box__fieldset';
export const CLASS_SEARCH_BOX_BUTTON = 'ext-search-box__search-button';
export const CLASS_SEARCH_BOX_TRIP_GROUP_FIELD = 'ext-search-box__trip-group-field';
export const CLASS_SEARCH_BOX_TRIP_GROUP_FIELD_ROUND_BUTTON = 'ext-search-box__trip-group-field__round-button';
export const CLASS_SEARCH_BOX_DATE_PICKER_FIELD = 'ext-search-box__date-picker-field';
export const CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD = 'ext-search-box__accommodation-type-field';
export const CLASS_FIELDSET_LABEL = 'ext-fieldset__label';
export const CLASS_STICKY_BAR = 'travelbase-stickybar';
export const CLASS_DESKMODE_BUTTON = 'ext-deskmode__button';
