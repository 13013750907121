import { BrandConfig } from '../constants/brandConfig';
import { createContext, useContext } from 'react';
import { Locale } from '../entities/Locale';

interface Context {
    messages: Record<string, string>;
    brandConfig: BrandConfig;
    supportedLanguages: Locale[];
    locale: string;
}

const TenantContext = createContext<Context>({} as Context);

// convenience hook
export const useTenantContext = () => useContext(TenantContext);

export default TenantContext;
