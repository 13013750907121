import { StyledButton } from '@oberoninternal/travelbase-ds/components/action/Button';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import { getFontCss, getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';
import Portal from '@reach/portal';
import { Flex } from '@rebass/grid';
import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { CLASS_STICKY_BAR } from '../constants/customizationClassNames';
import SvgButton from './designsystem/SvgButton';
import UnitThumbnail from './designsystem/UnitThumbnail';

interface Props extends HTMLAttributes<HTMLDivElement> {
    wrapInPortal?: boolean;
    hasRelativePosition?: boolean;
}

const StickyBar: FC<React.PropsWithChildren<Props>> = ({
    className,
    children,
    wrapInPortal,
    hasRelativePosition,
    ...props
}) => {
    const content = (
        <Sticky
            hasRelativePosition={hasRelativePosition}
            inPortal={wrapInPortal}
            className={`${className ?? ''} ${CLASS_STICKY_BAR}`}
            {...props}
        >
            <Container>{children}</Container>
        </Sticky>
    );

    if (wrapInPortal) {
        return <Portal>{content}</Portal>;
    }

    return content;
};

export default StickyBar;

export const iosStickyBarSafeSpace = css`
    @supports (margin-bottom: env(safe-area-inset-bottom)) {
        margin-bottom: env(safe-area-inset-bottom);
        ::after {
            position: absolute;
            content: '';
            width: 100%;
            top: 100%;
            height: env(safe-area-inset-bottom);
            background: white;
            left: 0;
        }
    }
`;

export const StickyBarTextButton = styled(TextButton)`
    line-height: 1.4em;
    text-align: left;
    padding: 0;
    font-weight: 600;
    margin-left: 0rem !important;

    > span > :first-child + :last-child {
        margin-left: 0;
    }
    :hover {
        > span {
            ::after {
                opacity: 0;
            }
        }
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueriesValues.s}px) {
        margin-left: 0.8rem !important;
        > span {
            ::after {
                opacity: 0.4;
                transform-origin: 100% 50%;
                transform: scale3d(0, 1, 1);
                transition: transform 0.3s, opacity 0.3s;
            }
            ::before {
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                right: 0;
                opacity: 0.4;
                border-bottom: 1px solid ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.textAction)};
                ${({ size }) => (size === 'tiny' ? 'padding-bottom: 2px;' : '')};
            }
        }
        :hover {
            > span {
                ::after {
                    opacity: 1;
                    transform-origin: 0% 50%;
                    transform: scale3d(1, 1, 1);
                }
            }
        }
    }
`;

export const StickyBarPriceContainer = styled(Flex)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        text-align: right;
    }
`;

export const StickyBarThumnail = styled(UnitThumbnail)`
    border-right: 1px solid ${({ theme }) => theme.colors.neutral['20']};
    padding-right: ${({ theme }) => theme.spacing['40_Standard']};
    max-width: 30.2rem;
    margin-right: ${({ theme }) => theme.spacing['50_Semi']};
`;

export const StickyBarOriginalPrice = styled.span`
    ${({ theme }) => getFontCss(theme.fontSizes.body.regular)};
    text-decoration: line-through;
    color: ${({ theme }) => theme.colors.neutral['40']};
`;

export const StickyBarPrice = styled.span`
    && {
        ${({ theme }) => getFontCss(theme.fontSizes.body.huge)};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
`;

const Container = styled(Flex).attrs({ alignItems: 'center', py: 3, px: '2.4rem' })`
    height: var(--booking-bar-height);
    width: 100%;
    ${iosStickyBarSafeSpace}
    > div {
        height: 4.8rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.m - 1}px) {
        ${StyledButton} {
            padding-left: ${({ theme }) => theme.spacing['40_Standard']};
            padding-right: ${({ theme }) => theme.spacing['40_Standard']};
        }
    }
`;

const Sticky = styled.div<{ inPortal?: boolean; hasRelativePosition?: boolean }>`
    position: ${({ hasRelativePosition }) => (hasRelativePosition ? 'relative' : 'fixed')};
    margin-top: ${({ hasRelativePosition }) => hasRelativePosition && '1.5rem'};
    bottom: 0;
    left: 0;
    transition: transform 200ms ease-in-out;
    right: 0;
    background: ${({ theme }) => theme.colors.neutral['0']};
    /* Only relative */
    border: ${({ hasRelativePosition, theme }) => !hasRelativePosition && `1px solid ${theme.colors.neutral['20']}`};
    box-shadow: ${({ hasRelativePosition }) =>
        !hasRelativePosition && ' 0 0 2.4rem 0 rgba(59, 118, 160, 0.03), 0 0 8rem 0 rgba(59, 118, 160, 0.05);'};
    z-index: ${({ theme, inPortal }) => (inPortal ? theme.zIndices.modal + 1 : theme.zIndices.sticky)};
`;

export const StickyBarSvgButton = styled(SvgButton)`
    margin: -3px 0 0 4px;
    display: inline-flex;
`;
