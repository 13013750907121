import React from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';

const Heading = () => (
    <>
        <Navigation />
        <Container>
            <PageTitle>Overnachten</PageTitle>
        </Container>
    </>
);

const PageTitle = styled.h3`
    ${({ theme }) => getFontCss(theme.fontSizes.title.large)};
    text-align: center;
    margin: 0;
`;

const Container = styled.div`
    background: url('/static/img/detocht-header-banner.jpg') no-repeat center center;
    background-size: cover;
    padding: 2em 0;
`;

export default Heading;
