const not = <F extends Function>(fn: F) =>
    function negated(...args: unknown[]) {
        return !fn(...args);
    };
const applicable =
    <F extends Function>(...args: F[]) =>
    <T extends object>(val: T) =>
        args.every(arg => arg(val));
const truthy = <T extends unknown>(val: T): val is NonNullable<T> => !!val;

export { not, applicable, truthy };
