import React, { ReactNode } from 'react';
import { Flex } from '@rebass/grid';
import { FormattedNumber } from 'react-intl';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { PriceLineCategoryEnum } from '../generated/graphql';
import surchargeCalculationTexts from '../constants/surchargeCalculationTexts';
import styled from 'styled-components';

interface PricingProps {
    main?: boolean;
    label: ReactNode | string;
    price: number;
    category?: PriceLineCategoryEnum | null;
}

const Pricing = ({ main, label, price, category }: PricingProps) => (
        <Flex justifyContent="space-between" alignItems="center">
            <StyledBody
                style={{
                    textTransform: typeof label === 'string' ? 'unset' : 'capitalize',
                    fontWeight: main ? 600 : undefined,
                }}
                variant={main ? 'regular' : 'small'}
            >
                {label}
            </StyledBody>
            <Body variant={main ? 'regular' : 'small'} style={{ fontWeight: main ? 600 : undefined }}>
                {price === 0 && category === PriceLineCategoryEnum.NotInTotal ? (
                    surchargeCalculationTexts.SUBSEQUENT
                ) : (
                    <FormattedNumber value={price} format="EUR" />
                )}
            </Body>
        </Flex>
    );
const StyledBody = styled(Body)`
    :first-letter {
        text-transform: uppercase;
    }
`;
export default Pricing;
