import { AccommodationTypeEnum, RentalUnitTypeEnum } from '../generated/graphql';

// In a way to achieve some kind of type checking we can use these types for defining accommodation and rentalunit filters instead
// of just typing out the filter, which is prone to errors

const withPrefix = <T extends object>(obj: T, prefix: string) =>
    Object.entries(obj).reduce<T>((prev, [key, value]) => ({ ...prev, [key]: `${prefix}.${value}` }), {} as T);

// Uber types
export const accommodationTypes = withPrefix<Record<AccommodationTypeEnum, string>>(
    {
        HOTEL: 'hotel',
        APARTMENT_BLOCK: 'apartment-block',
        BED_AND_BREAKFAST: 'bed-and-breakfast',
        BUNGALOW_PARK: 'bungalow-park',
        CAMPING: 'camping',
        LOCATION: 'locatie',
    },
    '_accommodation-type'
);

export const getRentalUnitTypeByPrefixedType = (type: string) =>
    Object.keys(rentalUnitTypes)[Object.values(rentalUnitTypes).indexOf(type)] as RentalUnitTypeEnum;

// Super types
export const rentalUnitTypes = withPrefix<Record<RentalUnitTypeEnum, string>>(
    {
        BED: 'bed',
        CAMPER_SPOT: 'camper-spot',
        CAMPING_PITCH: 'camping-pitch',
        HOME: 'home',
        ROOM: 'room',
        TENT: 'tent',
        BOAT: 'boat',
    },
    '_rental-unit-type'
);
