import { Colors } from '@oberoninternal/travelbase-ds/themes/default';
import websiteTheme from './website';
import { FontStyle, Theme } from '@oberoninternal/travelbase-ds/constants/theme';
import { BodyVariant } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';

/**
 * The De Tocht Voorkant theme.
 * The De Toch theme for the booking site.
 */

const primary = {
    90: '#C6AF4B',
    100: '#003057',
};

const colors: Colors = {
    primary: {
        ...websiteTheme.colors.primary,
        40: primary[90],
        50: primary[90],
        60: primary[100],
        70: primary[100],
        80: primary[90],
        90: primary[100],
    },
    tertiary: websiteTheme.colors.tertiary,
    secondary: {
        ...websiteTheme.colors.secondary,
        0: primary[90],
        5: primary[90],
        30: primary[90],
        50: primary[100],
        70: primary[100],
        80: websiteTheme.colors.neutral[0],
    },
    neutral: websiteTheme.colors.neutral,
    positive: websiteTheme.colors.positive,
    warning: websiteTheme.colors.warning,
    negative: websiteTheme.colors.negative,
};

const baseTitle: Partial<FontStyle> = {
    color: colors.primary[90],
    weight: 900,
};

const titleStyles: Record<TitleVariant, FontStyle> = {
    large: {
        size: { min: 19, max: 50 },
        line: { min: 24, max: 50 },

        ...baseTitle,
    },
    regular: {
        size: { min: 19, max: 23 },
        line: { min: 24, max: 28 },
        weight: 400,
    },
    small: {
        size: { min: 16, max: 19 },
        line: { min: 24, max: 24 },
        weight: 400,
    },
    tiny: {
        size: { min: 16, max: 16 },
        line: { min: 20, max: 20 },
        weight: 400,
    },
    micro: {
        size: { min: 15, max: 15 },
        line: { min: 16, max: 16 },
        weight: 400,
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 17, max: 20 },
        line: { min: 28, max: 34 },
        weight: 900,
    },
    large: {
        size: { min: 14, max: 18 },
        line: { min: 20, max: 32 },
        weight: 400,
    },
    regular: {
        size: { min: 14, max: 16 },
        line: { min: 20, max: 28 },
        letterSpacing: 0,
        weight: 400,
    },
    small: {
        size: { min: 13, max: 14 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
    },
    tiny: {
        size: { min: 12, max: 13 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
    micro: {
        size: { min: 12, max: 12 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
    },
};

const detochtTheme: Theme = {
    ...websiteTheme,
    name: 'De Tocht',

    fontFamily: {
        title: 'Proximanova',
        body: 'Proximanova',
    },

    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    colors,
    colorAssignments: {
        main: ['primary', 80],
        buttonPrimary: ['primary', 80],
        buttonLight: ['primary', 80],
        textAction: ['primary', 80],
        input: ['primary', 80],
        form: ['primary', 80],
        rating: ['primary', 80],
        booking: ['primary', 80],
        calendar: websiteTheme.colorAssignments.calendar,
    },
    maxWidths: {
        xs: '464px',
        s: '636px',
        m: '960px',
        l: '1152px',
        xl: '1344px',
        xxl: '1440px',
    },
    radius: {
        button: '0.8rem', // we use this for buttons and SearchBar when inline on Search
        textInput: '0.8rem', // we use this for Input, TextInput, SelectDateInputs, Dropdown
        textArea: '0.6rem', // we use this for SelectInput, Select Option, Select open containers, Dropdown open containers
        dropdown: '0.5rem',
    },
};

export default detochtTheme;
