import { IntlShape } from 'react-intl';
import messages from '../constants/messages';
import { Booking } from '../entities/Booking';

const getTripGroupText = (
    intl: IntlShape,
    { amountAdults = 0, amountChildren = 0, amountBabies = 0, amountPets = 0, amountYouths = 0 }: Booking,
    long = false
) => {
    const { formatMessage, formatList } = intl;
    const amountGuests = amountAdults + amountYouths + amountChildren + amountBabies;
    const guestsText = formatMessage(messages.guests, { amountGuests }) || '';
    const petsText = formatMessage(messages.pets, { amountPets }) || '';
    if (long) {
        const list = [
            amountAdults && formatMessage(messages.adults, { amountAdults }),
            amountYouths && formatMessage(messages.youth, { amountYouths }),
            amountChildren && formatMessage(messages.children, { amountChildren }),
            amountBabies && formatMessage(messages.babies, { amountBabies }),
            formatMessage(messages.pets, { amountPets }),
        ];

        return formatList(
            // filter out all falsey values
            list.filter(message => message),
            { type: 'conjunction' }
        ) as string;
    }
    return `${guestsText}${petsText && `, ${petsText}`}`;
};

export default getTripGroupText;
