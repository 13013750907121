import { BaseImgProps } from '../components/Img';
import { ImgHTMLAttributes } from 'react';
import { Image, TransformFragment } from '../generated/graphql';
import createPlaceholder from './createPlaceholder';

export type ImageType = Image | Partial<TransformFragment>;

const isImage = (img: ImageType): img is Image => 'transform' in img;
const createImgProps = (
    /**
     * .type defaults to 'image/jpeg'
     */
    fallback: ImageType & { type?: string },
    webp: ImageType,
    sizes?: string,
    props?: ImgHTMLAttributes<HTMLImageElement>
): BaseImgProps => {
    const { loading, ...rest } = props ?? {};
    const fallbackType = fallback.type ?? 'image/jpeg';
    const jpegTransform = isImage(fallback) ? fallback.transform : fallback;
    const webpTransform = isImage(webp) ? webp.transform : webp;

    return {
        fallback: {
            src: jpegTransform?.src,
            ...rest,
        },
        loading,
        sources: [
            {
                srcSet: webpTransform?.srcSet,
                type: 'image/webp',
                sizes,
            },
            {
                srcSet: jpegTransform?.srcSet,
                type: fallbackType,
                sizes,
            },
        ],
        placeholder: createPlaceholder(webpTransform?.placeholder),
    };
};

export default createImgProps;
